import { Box,CardActionArea, CardMedia, Grid,Chip,Button, InputAdornment,Link, MenuItem, TextField, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import '../Integration/integration.css';
import { Link as RouterLink } from 'react-router-dom';
import styled from "@emotion/styled";

export const StyleWrapper = styled.div`
.css-1ibh9b6 {
    padding-top: 50px;
}
.css-m27bwk-MuiGrid-root {
    max-width: 20%;
}
.css-fg428m-MuiPaper-root-MuiCard-root {
    max-width: 300px;
}`

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;  

const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 1,
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      paddingTop: APP_BAR_DESKTOP + 1,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  }));

const listofApps = [
    {
        value: 'project',
        label: 'Project Management',
    },
    {
        value: 'Video Conferencing',
        label: 'video',
    },
    {
        value: 'mail',
        label: 'Email',
    },
    {
        value: 'Storage',
        label: 'storage',
    },
    {
        value: 'CRM',
        label: 'crm',
    },
    {
        value: 'Calendar',
        label: 'Calendar',
    },
    {
        value: 'Third party APIs',
        label: 'Third party APIs',
    },
    

];


let appList = [
    {
        image: "/static/icons/Teams.png",
        alt: "Teams",
        appName: " Teams",
        appType: "Video Conferencing",  
        content:"Record,transcribe,search Team meetings",
        link:"https://u7m4oy1hn4.execute-api.ap-south-1.amazonaws.com/STAGE/teamslogin"
    },
    {
        image:"/static/icons/Zoom.png",
        alt: "Zoom",
        appName: "Zoom",
        appType: "Video Conferencing",
        component: "Zoom",
        content:"Record,transcribe,search zoom meetings",
        link:"https://mgf7hxdajl.execute-api.ap-south-1.amazonaws.com/STAGE/zoomlogin"
    },
];
    let InActiveAppList = [
    {
        image: "/static/icons/Googlemeet.png",
        alt: "GoogleMeet",
        appName: " Google Meet",
        appType: "Video Conferencing",
    },
    {
        image: "/static/icons/Skype.png",
        alt: "Skype",
        appName: " Skype",
        appType: "Video Conferencing",
    },
    {
        image: "/static/icons/Gmail.png",
        alt: "Gmail",
        appName: "Gmail",
        appType: "Email",
    },
    {
        image: "/static/icons/Googledrive.png",
        alt: "GoogleDrive",
        appName: "Google Drive",
        appType: "Storage",
    },
    {
        image:"/static/icons/OneDrive.png",
        alt: "OneDrive",
        appName: "OneDrive",
        appType: "Storage",
    },
    {
        image:"/static/icons/Outlook.png",
        alt: "Outlook",
        appName: "Outlook",
        appType: "Email",
    },
    {
        image:"/static/icons/Googlecalendar.png",
        alt: "GoogleCalendar",
        appName: "Google Calendar",
        appType: "Calendar",
    },
    {
        image:"/static/icons/OutlookCalendar.png",
        alt: "OutlookCalendar",
        appName: "OutlookCalendar",
        appType: "Calendar",
       },
];


export function AppList() {
    const [app, setApp] = React.useState('');
    const [rows, setRows] = useState(appList);
    const [inactiverows] = useState(InActiveAppList);
    const [searched, setSearched] = useState("");
    const navigate = useNavigate();

    const RootStyle = styled('div')(({ theme }) => ({
        [theme.breakpoints.between('md')]: {
            display: 'flex',
          },

      }));


    const handleclick = (event) => {
        console.log(event.target.alt) 
        for (var i=0; i<appList.length; i++)  {
           if (appList[i].appName===event.target.alt){
                  navigate(appList[i].path)
           }
        }
    };

    const handleChange = (event) => {
        setApp(event.target.value)
        const filteredRows = appList.filter((row) => {
            return row.appType.toLowerCase().includes(event.target.value.toLowerCase());
        });
        setRows(filteredRows);
    };

    const requestSearch = (searchedVal) => {
        const filteredRows = appList.filter((row) => {
            return row.appName.toLowerCase().includes(searchedVal.target.value.toLowerCase());
        });
        setRows(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };

    return (
        <>
          <Box>
            <div style={{ display: "flex", width: '100%' }}>
                <div style={{ width: '50%', padding: '5px' }}>
                    <TextField InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                        type="search"
                        fullWidth
                        id="integation-apps"
                        className=""
                        label="Search Integration Apps"
                        variant="outlined"
                        onChange={(searchVal) => requestSearch(searchVal)}
                        onCancelSearch={() => cancelSearch()}
                    />
                </div>
                <div style={{ width: '50%', padding: '5px' }}>
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Video Conferencing"
                        fullWidth
                        value={app}
                        onChange={handleChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FilterAltIcon />
                                </InputAdornment>
                            ),
                        }}
                    >
                        {listofApps.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
            </div>
            <StyleWrapper>
            <MainStyle>
            <Grid container direction="row" justifyContent="center" alignItems="center"spacing={6}>
                    {rows.map((val) => (
                        
                        <Grid item xs={30} md={3}>
                            <div className="appStyle">
                                <RootStyle>
                                <Card sx={{ maxWidth: 1000 }}paddingleft= "10px">
                                   <CardActionArea  sx={{ maxWidth: 1000 }}paddingRight= "10px">
                                        <CardMedia
                                            className="imageCard"
                                            component="img"
                                            height="200"
                                            image={val.image}
                                            alt={val.appName}
                                        />
                                        <br/>
                                    </CardActionArea>
                                    <div className="appName">
                                    <Typography gutterBottom variant="h5" component="div">
                                        {val.appName}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {val.appType}
                                    </Typography>
                                    
                                    <br/>
                                    <Typography variant="body2" color="text.secondary">
                                        {val.chip}
                                    </Typography>
                                   
                                    <br/>
                                    <Chip label="Active" color="success" sx={ {backgroundColor: '#E2FBD8', color: '#054810',fontsize:'25px',p:'15px',mt:'-35px'}}/>
                                    <br/>
                                    <Typography variant="body4" color="text.secondary">
                                    {val.content}
                                    </Typography>
                                    <br/>
                                    <Link component={RouterLink} variant="subtitle2" to="/" >
                                    Learn More
                                    </Link> 
                                    <br/>
                                    <br/>
                            <Button target="_blank" href={val.link} variant="contained">Connect</Button>  
                            <br/>     
                            <br/>            
                                </div>  
                                </Card>
                                </RootStyle>
                            </div>
                        </Grid>
                    ))}
                </Grid>
                

                {/* In Active App list */}
           <br/>
           <br/>

            <Grid container spacing={3}>
                    {inactiverows.map((val) => (
                        
                        <Grid item xs={30} md={3}>
                            <div className="appStyle">
                                <RootStyle>
                                <Card sx={{ maxWidth: 1000 }}paddingleft= "10px">
                                   <CardActionArea  sx={{ maxWidth: 1000 }}paddingRight= "10px">
                                        <CardMedia
                                            className="imageCard"
                                            component="img"
                                            height="200"
                                            image={val.image}
                                            alt={val.appName}
                                        />
                                        <br/>
                                    </CardActionArea>
                                    <div className="appName">
                                    <Typography gutterBottom variant="h5" component="div">
                                        {val.appName}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {val.appType}
                                    </Typography>
                                    <br/>
                                    <br/>
                                    <Chip label="Coming Soon" color="warning" sx={ {backgroundColor: '#FFF7CD', color: '#054810',fontsize:'50px',p:'20px',mt:'-50px'}}/>
                                    <br/>   
                                    <br/>     
                                </div>    
                                </Card>
                                </RootStyle>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </MainStyle>
            </StyleWrapper>
        </Box>
        </>
    );
}
