import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {Link, Container} from '@mui/material';
import useResponsive from '../hooks/useResponsive';
import { UserProfileForm } from '../sections/auth/UserProfile';
import AuthSocial from '../sections/auth/AuthSocial';
import CustomizedBreadcrumbs from '../components/Breadcrumbs';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 1000,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(0, 0),
}));

export default function ForgotPassword() {
  const smUp = useResponsive('up', 'sm');

 
  return (
<>
          {smUp && (
            
              <Link variant="subtitle2" component={RouterLink} to="/dashboard/userprofile">  </Link>  )}
                    

        <Container maxWidth="lg">
          <ContentStyle>   
            <CustomizedBreadcrumbs />     
            <AuthSocial />
            <UserProfileForm />
            {!smUp && (
                <Link variant="subtitle2" component={RouterLink} to="/dashboard/userprofile">
                </Link>            
            )} 
          </ContentStyle>
        </Container>     
    </>
    );
}
