import * as Yup from 'yup';
import React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { useFormik, FormikProvider } from 'formik';
import { Card, Stack, Button,TextField} from "@mui/material";
import styled from "@emotion/styled";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { Link as RouterLink } from 'react-router-dom';
import { values } from 'lodash';

export const StyleWrapper = styled.div`
.css-6od3lo-MuiChip-label{
    font-size: x-large;
    padding-left: 30px;
    padding-right: 30px;
}`

        
export default function ContactSupport() {

  const ContactSupportSchema = Yup.object().shape({
      name: Yup.string().min(5, 'Too Short!').max(50, 'Too Long!').required('Name is required'),
      email: Yup.string().email('Email must be a valid email address').required('Email is required'),
      subject: Yup.string().required('Subject is required'),
      message: Yup.string().required('Message is required'),
    });
  
    const formik = useFormik({
      initialValues: {
        name: '',
        email: '',
        subject: '',
        message: '',
      },
      validationSchema: ContactSupportSchema,
      onSubmit: () => {
        console.log(values);
      },
    });
  
    const { errors, touched } = formik;

  return (
    <>
    <FormikProvider value={formik}>
    <StyleWrapper>
        <Card sx={{
        p: 5,
        margin: 'auto',
        maxWidth: 500,
        flexGrow: 1,
        justifyContent: "center",
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#1A2027' : '#ffffff',
      }}
        >
     <Stack alignItems="center">
        <Chip size="meduim" label="Contact Sales" color="primary" />  
      </Stack>      
      <br/>

      <Stack spacing={3} >
                  <TextField 
                  fullWidth
                  autoComplete='name'
                  type='text'
                    label="Name"
                    {...formik.getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                  <TextField 
                  fullWidth
                  autoComplete='email'
                  type='email'
                    label="Email"
                    {...formik.getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                   <FormControl sx={{ m: 1, minWidth: 200}}>
                  <InputLabel htmlFor="subject">Subject</InputLabel>
                   <Select defaultValue="None"  label="subject" {...formik.getFieldProps('subject')}> 
                   <MenuItem value={"Accounts"}>Accounts</MenuItem>
                   <MenuItem value={"App"}>App</MenuItem>        
                   <MenuItem value={"Recordings"}>Recordings</MenuItem>
                   <MenuItem value={"Calendar"}>Calendar</MenuItem>   
                   <MenuItem value={"Integration"}>Integration</MenuItem>        
                   <MenuItem value={"Analytics"}>Analytics</MenuItem>     
                   <MenuItem value={"General"}>General</MenuItem>        
                   <MenuItem value={"Billing"}>Billing</MenuItem>     
                 </Select>
                </FormControl>

                <TextField
          id="outlined-multiline-static"
          label=""
          multiline
          rows={4}
          defaultValue="Enter your message"
        />

                  </Stack>

      <br/>
                  
       <Stack direction="row" justifyContent="center" spacing={3}>
       <Box sx={{ '& button': { m: 2 } }}>
      <div>
        <Stack spacing={3} direction="row" alignItems="center" justifyContent="center">
          <Stack spacing={3} alignItems="center" justifyContent="center" >
           <Button size="medium"sx={{width:100}} backgroundColor="#38BC41" variant="contained" to="/dashboard/pricing" component={RouterLink}>
            Cancel
           </Button>
          </Stack>    
          <Stack spacing={3} alignItems="center" justifyContent="center" >
           <Button size="medium"sx={{width:100}} backgroundColor="#38BC41" variant="contained" to="/" component={RouterLink}>
            Save
           </Button>
          </Stack> 
        </Stack>       
      </div>
        </Box> 
      </Stack>
     </Card>
   </StyleWrapper>
   </FormikProvider>
    </>
  );
}
