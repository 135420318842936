import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import 'react-dropzone-uploader/dist/styles.css';
import { Stack} from '@mui/material';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {TablePagination } from '@mui/material';
import { filter } from 'lodash';
import Page from "../../components/Page";
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../sections/@dashboard/user';
import PropTypes from 'prop-types';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import styled from "@emotion/styled";
import { Link as RouterLink} from 'react-router-dom';
import React, { useState } from 'react';
import {Container} from "@mui/material";
import Iconify from '../../components/Iconify';
import  NewUser from "../../sections/Accounts/NewUser";
import { getUserList } from '../../Services/userTable';
import {useEffect}from "react";

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  minWidth: '100%',
});

export const StyleWrapper = styled.div`
.css-1fmcalr-MuiTableCell-root
{
    text-align: center;
}
`

const column = [
  { field: "FirstName", headername: "FirstName" },
  { field: "LastName", headername: "LastName" },
  { field: "Email", headername: "Email" },
]

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.Name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };


  return (
    <TableHead>
      <TableRow>
        {column.map((row) => (
          <TableCell>
            <TableSortLabel
              active={orderBy === row.field}
              direction={orderBy === row.field ? order : 'asc'}
              onClick={createSortHandler(row.field)}
            >
              {row.field}

              {orderBy === row.Name ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}

            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function Users() {
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('Date');
  const [rows,setRows]=useState([]);

  const [dialogState,setDialogstate]=React.useState(false);
  const handlesession = () => {
    setDialogstate(true)
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.Name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  
  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  
  const handleSessionSummary = () => {
    getUserList().then(res => {
              if (res.status === 200){
                  if (res.data === "No data" ) {
                      }
                  else {
                      setRows(res.data);
                       }
              }
              else {
              }
              })
    }
     useEffect(() => {      
          handleSessionSummary()     
      }, [])
    
  
  return (
    <>
    <RootStyle>
      <Page title="Users">
      <Container style={{maxWidth:'none'}}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} >
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
          <Button variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:plus-fill" />}style={{paddingRight: "20px" }}onClick={handlesession}>
            New User
          </Button>
          { dialogState &&  (
           <NewUser/>
           )}
        </Stack>
      </Container>
     
      <Card
        sx={{
          p: 2.5,
          flexGrow: 1,
          justifyContent: "center",
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? '#1A2027' : '#ffffff',
        }}>
        <StyleWrapper>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
          </Stack>
          <Table sx={{ minWidth: 900 }} aria-label="simple table">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={column}
              rowCount={rows.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                const {  field, firstName, lastName, email , isVerified} = row;
                const isItemSelected = selected.indexOf(firstName) !== -1;

                return (
                  <TableRow
                    hover
                    key={field}
                    tabIndex={-1}
                    role="checkbox"
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                  >
                    <TableCell align="center">{firstName} </TableCell>
                    <TableCell align="center">{lastName}</TableCell>
                    <TableCell align="center">{email}</TableCell>
                    <TableCell align="center"><UserMoreMenu /></TableCell>

                  </TableRow>

                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>

            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </StyleWrapper>
      </Card>
      
      </Page>
      </RootStyle>
    </>
  );
}