import React from "react";
import { useRef, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {helpers} from '../helper/index';
const settings = require('../settings');
const apiEndpoint = settings.zoomintegrationApiEndpoint;


export {createmeeting,getzoomtimezone,startmeetinganalytics}


function createmeeting(meetingdetails){
    const OrganizationId = JSON.parse(localStorage.getItem("userInfo")).organizationId;
    const userId = JSON.parse(localStorage.getItem("userInfo")).userId;

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`${apiEndpoint}/createmeeting?organizationId=${OrganizationId}&userId=${userId}`, 
    JSON.stringify(meetingdetails),requestOptions)
        .then(res => res)
        .catch(error => error.response)
                 
}

function getzoomtimezone(){
  
    return axios.get(`${apiEndpoint}/getzoomtimezonelist`)
        .then(res => res)
        .catch(error => error.response)
                 
}

function startmeetinganalytics(meetingid,password){
    const OrganizationId = JSON.parse(localStorage.getItem("userInfo")).organizationId;
    const userId = JSON.parse(localStorage.getItem("userInfo")).userId;

  
    return axios.post(`${apiEndpoint}/startzoommeetinganalytics?organizationId=${OrganizationId}&userId=${userId}&meettingId=${meetingid}&password=${password}`)
        .then(res => res)
        .catch(error => error.response)
                 
}
