import React from "react";
import { useRef, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {helpers} from '../helper/index';
const settings = require('../settings');
const apiEndpoint = settings.PaymentgatewayApiEndpoint;

export {pricingdetails,getpricingplan,getCheckout}

function pricingdetails(){
   
    return axios.get(`${apiEndpoint}/getpricingdetails`)
        .then(res => res)
        .catch(error => error.response)
                 
}

function getpricingplan(tier){
   
    return axios.get(`${apiEndpoint}/getpricingplan?tiername=${tier}`)
        .then(res => res)
        .catch(error => error.response)
                 
}

function getCheckout(analyticsqtyState,storageqtyState,tier) {
    const OrganizationId = JSON.parse(localStorage.getItem("userInfo")).organizationId;
    console.log(OrganizationId)
    const form = {
     "productList": [
       {
         "product": analyticsqtyState.name,
         "quantity": parseInt(analyticsqtyState.quantity)
       },
       {
         "product": storageqtyState.name,
         "quantity": parseInt(storageqtyState.quantity)
       }
        ]
       }
     console.log("products selected",form);
     const requestOptions = {
           headers: {
               'Content-Type': 'application/json',
           }
       };
       return axios.post(`${apiEndpoint}/create-checkout-session?organizationId=${OrganizationId}&tier=${tier}` ,form,
        requestOptions)
           .then(res => res.data)
           .catch(error => error.response)
   
   }
   