import { Container, Tab, Box, IconButton, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import CustomizedBreadcrumbs from '../components/Breadcrumbs';
import Page from '../components/Page';
import SummarizeIcon from '@mui/icons-material/Summarize';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import Person3Icon from '@mui/icons-material/Person3';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import BarChartIcon from '@mui/icons-material/BarChart';
import Overview from '../sections/Analytics/Overview';
import ChartComponent from '../sections/Analytics/ChartComponent';
import Useranalytics from '../sections/Analytics/Useranalytics';
import Detailtable from '../sections/Analytics/Detailtable';
import { Icon } from '@iconify/react';
import { useReactToPrint } from "react-to-print";
import React, { useRef} from "react";
import { useLocation } from 'react-router-dom';

export default function AnalyticsForm(props) {

    const location = useLocation()
    let userInfo;
    if (location.state) {
    ({ userInfo } = location.state);
}

    const [analyticsvalue, setanalyticsValue] = React.useState('Overview');
    const [detailsvalue, setdetailsValue] = React.useState('Table');
    const [customPath, setCustomPath] = React.useState("Overview");
    const componentRef = useRef();
    const [tabTitle, setTabTitle] = React.useState('Session Partcipant List');

    const handleanalyticsDisplay = (event, newValue) => {
        setanalyticsValue(newValue);
        setCustomPath(newValue);
    };

    const handleTabTitleChange = (newValue) => {
        setTabTitle(newValue);
    };

    const handledetailChange = (event, newValue) => {
        setdetailsValue(newValue);
    };
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (

        <Page title="Analytics" >

            <Container maxWidth="xl" className='tabPanel'>
                <Typography sx={{ pl: 2 }} variant="h4" gutterBottom>
                    Analytics
                </Typography>
                <Box>
                    <CustomizedBreadcrumbs value={customPath}></CustomizedBreadcrumbs>
                </Box>

                <TabContext value={analyticsvalue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                            onChange={handleanalyticsDisplay}
                            aria-label="icon position tabs example"
                        >
                            <Tab icon={<SummarizeIcon />} iconPosition="start" label="Overview" value="Overview" />
                
                            <Tab icon={<Person3Icon />} iconPosition="start" label="User Analytics" value="User Analytics" />
                            <IconButton style={{ marginLeft: "auto", padding: "20px" }}>
                                <Icon icon="fa6-solid:file-export" color="#38BC41" width="30" height="30" edge="false" onClick={handlePrint} />
                     </IconButton>

                            <IconButton style={{ padding: "20px" }}>
                                <Icon icon="ci:share" color="#38BC41" width="30" height="30" />
                            </IconButton>
                        </TabList>
                    </Box>
                    <TabPanel value="Overview">
                        <Overview />
                    </TabPanel>
                    <TabPanel value="Details">

                        <TabContext value={detailsvalue}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList
                                    onChange={handledetailChange}
                                    aria-label="icon position tabs example"
                                >
                                    <Tab icon={<BackupTableIcon />} iconPosition="start" label="Table" value="Table" />
                                    <Tab icon={<BarChartIcon />} iconPosition="start" label="Chart" value="Chart" />

                                </TabList>
                            </Box>
                            <TabPanel value="Table">
                                <div style={{ marginBottom: '34px', marginLeft: '-34px' }}>
                                    <Typography sx={{ pl: 2 }} variant="h5" gutterBottom>
                                        Over All Meeting Details Table
                                    </Typography>
                                </div>
                                <Detailtable />
                            </TabPanel>
                            <TabPanel value="Chart">
                                <div style={{ marginLeft: '-34px' }}>
                                    <Typography sx={{ pl: 2 }} variant="h5" gutterBottom>
                                        Chart
                                    </Typography>
                                </div>
                                <ChartComponent GraphType="DetailAnalytics" />
                            </TabPanel>
                        </TabContext>
                    </TabPanel>
                    <TabPanel value="User Analytics">
                        <div style={{ marginBottom: '34px', marginLeft: '-34px' }}>
                            <Typography id="userAnalytics" sx={{ pl: 2 }} variant="h5" gutterBottom>
                                {tabTitle}
                            </Typography>
                        </div>
                        <Useranalytics rowsPerPageValue={5} pageValue={0} sortOrder={"asc"} sortOrderBy={"Date"} setTabTitle={handleTabTitleChange} />
                    </TabPanel>

                </TabContext>
            </Container>
        </Page>
    );

}
