import React, {useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import * as Yup from 'yup';
import { useFormik} from 'formik';
import Iconify from '../../components/Iconify';
import { IconButton, InputAdornment} from '@mui/material';
import {Divider} from "@mui/material";
import SnackBar from "../../components/Snackbar/Snackbar";
import { newUser } from '../../Services/newUser';


const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));



export default function NewUser(props) {
  const [open, setOpen] = React.useState(true);

  
  const handleClose = () => {
    setOpen(false);
    window. location. reload(false);
  };

  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('First name is required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name is required'),
    organizationName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Organization name is required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    phone_number: Yup.string().min(10, 'Check Number').max(10, 'Check Number').required('Phone number is required'),
    office_phone_number: Yup.string().min(10, 'Check Number').max(10, 'Check Number').required('Phone number is required'),
    password: Yup.string().required('Password is required'),
    role: Yup.string().required('Role is required'),
  });
  
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      organizationName:'',
      password: '',
      phone_number: '',
      office_phone_number:'',
      role:'',

    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      // navigate('/dashboard/account', { replace: true });
      console.log(values);
    },
  });

  const { errors, touched,values, isSubmitting } = formik;
  var [password] = useState('');
  var [organizationName] = useState('');
  var [role] = useState('');
  var [userContact] = useState(
    {
      phone_number: "",
      office_phone_number: "",
    }
  );
  var [email] = useState('');
  var [lastName] = useState('');
  var [firstName] = useState('');
  const [loading, setLoading] = React.useState(false);

  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = React.useState("");

  const handleClose1 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  }

  const handleregister = async (password,organizationName,role,userContact,email,lastName,firstName) => {
    setLoading(true);
    newUser(formik).then(res => {
      if (res) {
        if (res.status === 200) {
          localStorage.setItem("userInfo", JSON.stringify(res.data))
          setSnackbarTitle("User Created")
          setSnackBarOpen(true)
        }
        else if (res.status === 404) {
          setSnackbarTitle("Error in creating user")
          setSnackBarOpen(true)   
        }
        else if (res.status === 422) {
          setSnackbarTitle("Validation Error")
          setSnackBarOpen(true)  
        }
      }
      else {
        setSnackbarTitle("Some error occured")
          setSnackBarOpen(true)  
      }

    }

    )

  }

  return (
    <RootStyle>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        justifyContent="center"
        alignItems="center"
       >
        <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
        <Stack style={{alignItems:"center"}}>
        <h2>Individual User Registration Form</h2>
        
        </Stack>
        <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
        <Divider variant="middle"/>
        <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
        <DialogContent style={{alignItems:"center" ,width:"450px"}} >
        <TextField sx={{width: 400}}
              label="First name"
              {...formik.getFieldProps('firstName')}
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
            />
            <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
             <TextField sx={{width: 400}}
              label="Last name"
              {...formik.getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
            <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
          <TextField sx={{width: 400}}
            autoComplete="username"
            type="email"
            label="Email "
            {...formik.getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
          <TextField sx={{width: 400}}
              label="Organization Name"
              {...formik.getFieldProps('organizationName')}
              error={Boolean(touched.organizationName && errors.organizationName)}
              helperText={touched.organizationName && errors.organizationName}
            />
        <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
          <TextField sx={{width: 400}}
            autoComplete="Phone Number"
            type="phone number"
            label=" Phone Number "
            {...formik.getFieldProps('phone_number')}
            error={Boolean(touched.phone_number && errors.phone_number)}
            helperText={touched.phone_number && errors.phone_number}
          />
          <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
          <TextField sx={{width: 400}}
            autoComplete="Office Phone Number"
            type="phone number"
            label=" Office Phone Number "
            {...formik.getFieldProps('office_phone_number')}
            error={Boolean(touched.office_phone_number && errors.office_phone_number)}
            helperText={touched.office_phone_number && errors.office_phone_number}
          />
           <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
           <FormControl sx={{width: 400}}>
                  <InputLabel htmlFor="Choose Role">Choose Role</InputLabel>
                  <Select defaultValue="None"  label="Choose Role" {...formik.getFieldProps('role')}> 
          <MenuItem value={"SUPERADMIN"}>SUPERADMIN</MenuItem>
          <MenuItem value={"ADMIN"}>ADMIN</MenuItem>        
          <MenuItem value={"CTO/CIO"}>CTO/CIO</MenuItem>   
          <MenuItem value={"PRODUCT_MANAGER"}>PRODUCT_MANAGER</MenuItem>
          <MenuItem value={"SOFTWARE_PROFESSIONAL"}>SOFTWARE_PROFESSIONAL</MenuItem>        
          <MenuItem value={"MARKETING_SALES"}>MARKETING_SALES</MenuItem>  
          <MenuItem value={"OTHERS"}>OTHERS</MenuItem>  
            </Select>
           </FormControl>
           <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
          <TextField sx={{width: 400}}
            autoComplete="Password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...formik.getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
           <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
          

        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}type="submit" variant="contained" loading={isSubmitting}>
            Cancel
          </Button>
          <Button autoFocus type="submit" variant="contained" loading={isSubmitting}
          onClick={() => handleregister(userContact,password, email, lastName, firstName,role,organizationName)}>
            Ok
          </Button>
          <SnackBar
           open={snackBarOpen}
           close={handleClose1}
           snackbarTitle={snackbarTitle}
          />
        </DialogActions>
      </BootstrapDialog>
      </RootStyle>
  );
}

