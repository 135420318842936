import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import useResponsive from '../../hooks/useResponsive';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

export { default as FeedbackPopup } from './FeedbackPopup';
export default function DashboardLayout() {
  const isDesktop = useResponsive('up', 'lg');
  const [open, setOpen] = useState(isDesktop);

  function HandleResize() {
    const isOpen = window.innerWidth > 1200;
    setOpen(isOpen);
  }

  useEffect(() => {
    window.addEventListener("resize", HandleResize, false);
  }, []);

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} isOpenSidebar={open} />
      <DashboardSidebar onOpenSidebar={() => setOpen(true)} isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
