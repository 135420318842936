import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import {Divider} from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';


const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function EditPopup(props) {
  const [open, setOpen] = React.useState(true);
  const [type, setType] = React.useState('');
  const [open1, setOpen1] = React.useState(false);

  const handleChange = (event) => {
    setType(event.target.value);
  };
  
  const handleClose = () => {
    setOpen(false);
    window. location. reload(false);
  };

  const handleClick = () => {
    setOpen1(true);
  };

  const handleClose1 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen1(false);
  };

  const [state] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    color:'#E2FBD8',
  });
  const { vertical, horizontal } = state;
  
  
  return (
    <RootStyle>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        justifyContent="center"
        alignItems="center"
       >
        <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
        <Stack style={{alignItems:"center"}}>
        <h3> Edit </h3>
        </Stack>
        <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
        <Divider variant="middle"/>
        <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
        
        <DialogContent style={{alignItems:"center"}}>
        <div>
        <TextField required={true} minRows={5} id="outlined-basic" label="Name" variant="outlined" style={{width: "260px"}}/>
           </div>
           <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
           <div>
        <FormControl required sx={{ minWidth: 260 }}>
        <InputLabel id="demo-simple-select-required-label">Type</InputLabel>
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          value={type}
          label="Type *"
          onChange={handleChange}
        >
              <MenuItem value={"demo"}> Demo</MenuItem>
              <MenuItem value={"interaction"}> Interaction</MenuItem>
              <MenuItem value={"monitoring"}> Monitoring</MenuItem>
            </Select>
         </FormControl>
        </div>
    
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} onClick1={handleClick } onClose={handleClose1}>
            Ok
          </Button>
          <Snackbar open={open1} autoHideDuration={6000} onClose={handleClose1} anchorOrigin={{ vertical, horizontal }}sx={{ color: 'SUCCESS.lighter' }}>
          <Alert onClose={handleClose1} severity="success" sx={{ width: '100%' }}>
           Changes saved successfully..
          </Alert>
          </Snackbar>
          <Button  onClick={handleClose}autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
      </RootStyle>
  );
}

