import { Card, Typography, Button, Stack, Box, FormControl, Grid, SvgIcon, TextField, Paper } from '@mui/material';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AddIcon from "@mui/icons-material/Add";
import Divider from '@mui/material/Divider';
import 'react-edit-text/dist/index.css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
// sections
import AuthSocial from '../../sections/auth/AuthSocial';
import styled from "@emotion/styled";
import CustomModal from './customModal';
import { useLocation } from 'react-router-dom';
import SnackBar from "../../components/Snackbar/Snackbar";
import { getpricingplan,getCheckout } from "../../Services/payment";
// ----------------------------------------------------------------------
export const StyleWrapper = styled.div`
.css-313pcq-MuiButtonBase-root-MuiToggleButton-root.Mui-selected{
    background-color: #E2FBD8;
    color: #212B36;
}
.css-313pcq-MuiButtonBase-root-MuiToggleButton-root:hover{
    background-color: #E2FBD8;
}
.css-313pcq-MuiButtonBase-root-MuiToggleButton-root.Mui-selected{
    background-color: #E2FBD8;
    color: #212B36;
}
.css-313pcq-MuiButtonBase-root-MuiToggleButton-root.Mui-selected:hover{
    background-color: #E2FBD8;
}
.css-1yrymlm-MuiTypography-root{
    font-weight: 800;
    line-height: 2;
    padding: 2;
}
`

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#ffffff',
    ...theme.typography.body2,
     padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
  }));
  

const FontAwesomeSvgIcon = React.forwardRef((props, ref) => {
    const { icon } = props;

    const {
        icon: [width, height, , , svgPathData],
    } = icon;

    return (
        <SvgIcon ref={ref} viewBox={`0 0 ${width} ${height}`}>
            {typeof svgPathData === 'string' ? (
                <path d={svgPathData} />
            ) : (
                /**
                 * A multi-path Font Awesome icon seems to imply a duotune icon. The 0th path seems to
                 * be the faded element (referred to as the "secondary" path in the Font Awesome docs)
                 * of a duotone icon. 40% is the default opacity.
                 *
                 * @see https://fontawesome.com/how-to-use/on-the-web/styling/duotone-icons#changing-opacity
                 */
                svgPathData.map((d, i) => (
                    <path style={{ opacity: i === 0 ? 0.4 : 1 }} d={d} />
                ))
            )}
        </SvgIcon>
    );
});

FontAwesomeSvgIcon.propTypes = {
    icon: PropTypes.any.isRequired,
};


const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));


const CardStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 1000,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(0, 0, 2, 20),
}));

 
// ----------------------------------------------------------------------

export default function Checkout() {
  const smUp = useResponsive('up', 'sm');

  const [open, setOpen] = React.useState(true);
  var [tier,settier]=React.useState("");
  var [productlistState,setproductlistState]=useState("");
  var [analyticsqtyState, setAnalyticsqtyState]=useState({name: "Engagement Analytics report",quantity: 0});
  var [storageqtyState, setStorageqtyState]=useState({name: "Storage per GB",quantity: 0});
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = React.useState("");
  
  const location = useLocation();
  useEffect(() => { 
 
    const myProp1Value = location.state && location.state.tiername;  
    settier(location.state && location.state.tiername)   
    console.log("tier",myProp1Value,tier)
    handleGetProducts(myProp1Value)     
      
  }, [])
  
  const setquantity = (event) => {
     console.log("product",event.target.id)
     console.log("quantity",event.target.value)
     if (tier=="Premium"){
           
            if(event.target.value<10){
              console.log(event.target.value,"minimum quantity in permium should be 10")
              setSnackbarTitle("minimum quantity in permium should be 10")
              setSnackBarOpen(true)
            }
            else if (event.target.id=="Engagement Analytics report"){
              setAnalyticsqtyState({name: "Engagement Analytics report",quantity: event.target.value})
              setStorageqtyState({name: "Storage per GB" ,quantity: storageqtyState.quantity})
              console.log("updated analytics report")
              console.log("analytics state---------",analyticsqtyState)
              console.log("storage state---------",storageqtyState)
              
            }
            else if (event.target.id=="Storage per GB") {
                console.log("updated storage")
               setStorageqtyState({name: "Storage per GB",quantity: event.target.value})
               setAnalyticsqtyState({name: "Engagement Analytics report" ,quantity: analyticsqtyState.quantity})
               console.log("analytics state..............",analyticsqtyState)
               console.log("storage state..............",storageqtyState)
               
               }
            else {
              console.log("nothing matched")
            
            }

            
     }
     else {
      if (event.target.id=="Engagement Analytics report"){
        setAnalyticsqtyState({name: "Engagement Analytics report",quantity: event.target.value})
        setStorageqtyState({name: "Storage per GB" ,quantity: storageqtyState.quantity})
        console.log("updated analytics report")
        console.log("analytics state---------",analyticsqtyState)
        console.log("storage state---------",storageqtyState)
        
      }
      else if (event.target.id=="Storage per GB") {
          console.log("updated storage")
         setStorageqtyState({name: "Storage per GB",quantity: event.target.value})
         setAnalyticsqtyState({name: "Engagement Analytics report" ,quantity: analyticsqtyState.quantity})
         console.log("analytics state..............",analyticsqtyState)
         console.log("storage state..............",storageqtyState)
         
         }
      else {
        console.log("nothing matched")
      
      }
     }
     
     
}
  
  

   
  const handleGetProducts =(value) => {
    console.log(value,"tiername")
    getpricingplan(value).then(res => {
            console.log("productlist",res.data)
            setproductlistState(res.data)
            })
    }
    
  const handleCheckout = () => {
    if (analyticsqtyState.quantity==="0" && storageqtyState.quantity=="0") {
       
         
    }
    if (tier=="Premium"){
           
      if(analyticsqtyState<10){
        setSnackbarTitle("minimum quantity in permium should be 10")
        setSnackBarOpen(true)
      }
      else {
        getCheckout(analyticsqtyState,storageqtyState,tier).then(res => {
                console.log("checkout url ",res)
                window.open(res.data)
                })
             }
      
}
    else {
    getCheckout(analyticsqtyState,storageqtyState,tier).then(res => {
            console.log("checkout url ",res)
            window.open(res.data)
            })
         }
      
   }
    
   const handleClose = (event, reason) => {
    if (reason === "onclick") {
        return;
    }
    setSnackBarOpen(false);
    }


 

    
  return (
    <>
    <Page title="Checkout">
      <StyleWrapper>
      <RootStyle>
        <CardStyle>
          
          <Card
          sx={{
            p: 3.5,
            pl: 5,
            maxWidth: 1000,
            flexGrow: 1,
            justifyContent: "center",
            backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? '#1A2027' : '#ffffff',
           }}>
                           
                        <Typography sx={{  fontSize: 15, fontWeight: 800, mb: 2, pl:3, pb:0 }}>
                            SELECT THE PRODUCT
                        </Typography>
                        <br/>
                        
                        <Box sx={{ flexGrow: 1 }}>
                        
      <Grid container spacing={3}>
      { Object.entries(productlistState).map (([key, value]) => 
     <Grid item xs={6} >
    
          <Item style={{backgroundColor: '#FFE7D9' }} >
          <img src={value.imageurl} alt='analytics' style={{ paddingLeft: '20px', paddingBottom: '20px', paddingTop: '20px' }}/>
         <Stack direction="row" spacing={10}>
          <Typography sx={{  color: '#7A0C2E' ,fontSize: 18, fontWeight: 700, mb: 2, pl:3, pb:0 }}> {value.Name} </Typography>
          <Typography sx={{  color: '#7A0C2E' ,fontSize: 17, fontWeight: 900, mb: 2, pl:3, pb:0 }}>{value.Price}$</Typography>
         </Stack>     
         <Typography sx= {{color: '#7A0C2E' , mb: 2, pl:1, pb:0 }}> <h5>{value.Description}</h5></Typography>
         <Stack justifyContent='right' >
         <TextField sx={{"& .MuiInputLabel-root": {color: '#7A0C2E'}, "& .MuiOutlinedInput-root": { "& > fieldset": { borderColor: "#7A0C2E" },}, color: '#7A0C2E', m: 1, ml: 3, width: '25ch' }} required id={value.Name} label="Enter Quantity" type="number" variant="standard" onChange={setquantity} />     </Stack> 
          </Item>
            
        </Grid>
        )}
      </Grid>
     
    </Box>
   
    <br/>
    <Stack spacing={3} alignItems="center" justifyContent="center" >
     <Button variant="contained" color="success" autoFocus onClick={handleCheckout} className="buttonSubmitNotification" >
        CHECKOUT 
    </Button>
    <SnackBar
                open={snackBarOpen}
                close={handleClose}
                snackbarTitle={snackbarTitle}
            />
     </Stack>  
          </Card>    
         </CardStyle>             
        <AuthSocial />


            {!smUp && (
              <Typography variant="body2" sx={{ mt: 3, textAlign: 'center' }}></Typography>)}
           </RootStyle>
      </StyleWrapper>
    </Page>
    </>
  );
}

