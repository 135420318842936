import * as React from 'react'

const UserContext = React.createContext()

function userReducer(state, action) {
    switch (action.type) {
        case 'setUser': {
            return { userInfo:{...action.value}}
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function UserProvider({ children }) {
    const [state, dispatch] = React.useReducer(userReducer, {userInfo: null })
    const value = { state, dispatch }
    return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

function useUserInfo() {
    const context = React.useContext(UserContext)
    if (context === undefined) {
        throw new Error('useUserInfo must be used within a UserContextProvider')
    }
    return context
}

export { UserProvider, useUserInfo }