import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Link, Container, Typography } from '@mui/material';
import useResponsive from '../hooks/useResponsive';
import { OfflineForm } from '../sections/auth/Offline';
import AuthSocial from '../sections/auth/AuthSocial';
import Page from "../components/Page";

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(16, 1, 0, 1),
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(10, 0),
}));

export default function Offline() {
  const smUp = useResponsive('up', 'sm');

  return (
    <>
    <Page title="Recordings">
    <RootStyle>
        <HeaderStyle>

        <Typography sx={{pl: 4}} variant="h4" gutterBottom>
            Recordings
          </Typography>
      {smUp && (

        <Link variant="subtitle2" component={RouterLink} to="/dashboard/offline">  </Link>)}
      </HeaderStyle>

      <Container maxWidth="lg">
        <ContentStyle>
          <AuthSocial />
          <OfflineForm />

          {!smUp && (
            <Link variant="subtitle2" component={RouterLink} to="/dashboard/offline">
            </Link>
          )}
        </ContentStyle>
      </Container>
      </RootStyle>
      </Page>
    </>
  );
}
