import axios from 'axios';
import {helpers} from '../helper/index';
const settings = require('../settings');
const apiEndpoint = settings.ApiEndPoint;
export {getAnalyticsSummary,setAnalyticsSummary};

function getAnalyticsSummary(){
    helpers.setAuth();
    const organizationId=JSON.parse(localStorage.getItem("userInfo")).organizationId;
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`${apiEndpoint}/getanalyticstemplate?OrganizationId=${organizationId}`, 
     requestOptions)
        .then(res => res)
        .catch(error => error.response)
}

function setAnalyticsSummary(analyticsSummary){
    helpers.setAuth();
    const organizationId=JSON.parse(localStorage.getItem("userInfo")).organizationId;
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`${apiEndpoint}/setanalyticstemplate?OrganizationId=${organizationId}`, analyticsSummary,
     requestOptions)
        .then(res => res)
        .catch(error => error.response)
}