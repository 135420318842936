import axios from 'axios';

const settings = require('../settings');
const apiEndpoint = settings.ApiEndPoint;


export {ResetPasswordUser};

function buildReqBody(formik) {
    return {
        loginEmail: formik.values.email,
        password: formik.values.password,
         }
}
function ResetPasswordUser(formik) {
        const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`${apiEndpoint}/resetpassword`, 
    JSON.stringify(buildReqBody(formik)), requestOptions)
        .then(res => res)
        .catch(error => error.response)
}