import axios from 'axios';
const settings = require('../settings');
const apiEndpoint = settings.ApiEndPoint;

export { CompanyUser };

function buildReqBody(formik) {
    return {
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        loginEmail: formik.values.email,
        organizationName: formik.values.organizationName,
        organizationSize: formik.values.organizationSize,
        websiteUrl: formik.values.websiteUrl,
        role: formik.values.role,

        address: {
            address: formik.values.address,
            city: formik.values.city,
            state: formik.values.state,
            country: formik.values.country,
            zipcode: formik.values.zipcode,
        },
        contact: {
            OfficePhone: formik.values.office_number,
            MobileNumber: formik.values.phone_number,
        },
        password: formik.values.password,
        purpose: formik.values.purpose,
    }
}

function CompanyUser(formik) {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };

   return axios.post(`${apiEndpoint}/createorgsuperadmin`,
           JSON.stringify(buildReqBody(formik)), requestOptions)
        .then(res => res)
        .catch(error => error.response)
}