import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import Page from '../components/Page';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}));

const styles = {

    largeIcon: {
      width: 100,
      height: 100,
    },
  
  };

export default function PaymentFailed() {
  return (
    <Page title="Payment Failed">
      <Container>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
        
         <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 24 24"><path fill="#0e6d0e" d="M6.83 4H20c1.11 0 2 .89 2 2v12c0 .34-.08.66-.23.94L20 17.17V12h-5.17l-4-4H20V6H8.83l-2-2zm13.66 19.31L17.17 20H4c-1.11 0-2-.89-2-2l.01-12c0-.34.08-.66.23-.93L.69 3.51L2.1 2.1l19.8 19.8l-1.41 1.41zM4 6.83V8h1.17L4 6.83zM15.17 18l-6-6H4v6h11.17z"/></svg>

          <Typography variant="h2" paragraph>
            Payment cancelled
          </Typography>

          <Typography sx={{ color: 'text.secondary' }} variant="h6" paragraph>
            For any queries, please do contact info@attentionkart.com
          </Typography>

          <Button to="/" size="large" variant="contained" component={RouterLink}>
            Go to Home
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
