import React from "react";
import { useRef, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {helpers} from '../helper/index';
const settings = require('../settings');
const apiEndpoint = settings.offlinesessionApiEndPoint;



export {getuploadparams,getvideourl,checkvideoname,setuploaddetails,getofflinesession,editofflinesession,deleteofflinesession};


function checkvideoname(videoName){
        const OrganizationId = JSON.parse(localStorage.getItem("userInfo")).organizationId;
        const requestOptions = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        return axios.post(`${apiEndpoint}/checkvideoname?organizationId=${OrganizationId}&videoName=${videoName}`, 
         requestOptions)
            .then(res => res)
            .catch(error => error.response)
                     
    }
    


function getuploadparams(videoName){
  
    const OrganizationId = JSON.parse(localStorage.getItem("userInfo")).organizationId;
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`${apiEndpoint}/putvideourl?organizationId=${OrganizationId}&videoName=${videoName}`, 
     requestOptions)
        .then(res => res)
        .catch(error => error.response)    
}


function getvideourl(sessionId){

    const OrganizationId = JSON.parse(localStorage.getItem("userInfo")).organizationId;
   const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`${apiEndpoint}/getvideourl?organizationId=${OrganizationId}&sessionId=${sessionId}`, requestOptions)
        .then(res => res)
        .catch(error => error.response)
    }


function setuploaddetails(filesize,videoduration,filname, sessiontype){

    const OrganizationId = JSON.parse(localStorage.getItem("userInfo")).organizationId;
    const UserId = JSON.parse(localStorage.getItem("userInfo")).userId;
   
    return axios.post(`${apiEndpoint}/setuploaddetails?organizationId=${OrganizationId}&userId=${UserId}&fileSize=${filesize}&videoDuration=${videoduration}&filename=${filname}&sessionType=${sessiontype}`)
        .then(res => res)
        .catch(error => error.response)
    }


function getofflinesession(page,size){

    const OrganizationId = JSON.parse(localStorage.getItem("userInfo")).organizationId;
    const UserId = JSON.parse(localStorage.getItem("userInfo")).userId;
    const pagedetails= { "page": page, "size": size }
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
   
    return axios.post(`${apiEndpoint}/getofflinesessionlistbyId?organizationId=${OrganizationId}&userId=${UserId}`,  JSON.stringify(pagedetails),requestOptions,)
        .then(res => res)
        .catch(error => error.response)
    }


function editofflinesession(sessiondetails,sessionId){

    const OrganizationId = JSON.parse(localStorage.getItem("userInfo")).organizationId;
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
   
   
    return axios.post(`${apiEndpoint}/editsessiondetailbyId?organizationId=${OrganizationId}&sessionId=${sessionId}`,JSON.stringify(sessiondetails),requestOptions)
        .then(res => res)
        .catch(error => error.response)
    }


function deleteofflinesession(sessionId){

        const OrganizationId = JSON.parse(localStorage.getItem("userInfo")).organizationId;
     
        
       
        return axios.post(`${apiEndpoint}/deletesessiondetailbyId?organizationId=${OrganizationId}&sessionId=${sessionId}`)
            .then(res => res)
            .catch(error => error.response)
        }