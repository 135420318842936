import React, { useRef, useState } from 'react';
import { Menu, MenuItem, IconButton, Button } from '@mui/material';
import Iconify from '../../../components/Iconify';
import EditPopup from '../../../sections/@dashboard/user/EditPopup.js';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import styled from "@emotion/styled";

export const StyleWrapper = styled.div`
.css-zdvt3i-MuiPaper-root-MuiAlert-root {
  background-color:#E2FBD8;
  color:#38BC41;
}`
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function UserMoreMenu() {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [dialogState,setDialogstate]=React.useState(false);
  
  const handlesession = () => {
    setDialogstate(true)
  }

  const [open, setOpen] = React.useState(false);
  const [state] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const {horizontal} = state;

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 120, maxWidth: '90%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ color: 'text.secondary' }}>
          <Button startIcon={<Iconify icon="eva:trash-2-outline"width={24} height={24} />}onClick={handleClick} >Delete</Button>
          <StyleWrapper>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical:'top', horizontal }}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
         Successfully deleted...
        </Alert>
      </Snackbar>
      </StyleWrapper>
        </MenuItem>
        <MenuItem sx={{ color: 'text.secondary' }}>
          <Button startIcon={<Iconify icon="eva:edit-fill"width={24} height={24} />} onClick={handlesession}>Edit</Button>
          { dialogState &&  (
           <EditPopup/>
               )}
        </MenuItem>
      </Menu>
    </>
  );
}