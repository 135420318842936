import * as Yup from 'yup';
import React, { useState, useEffect } from 'react';
import { useFormik} from 'formik';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Link, Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/Iconify';
import { loginUser } from "../../../Services/login";
import SnackBar from '../../../components/Snackbar/Snackbar';
import { useUserInfo } from '../../../context/userContext';

export default function LoginForm() {

  const navigate = useNavigate();

  var [loginValue] = useState("");
  var [passwordValue] = useState("");
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = React.useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { userInfo: userInfo,dispatch } = useUserInfo();
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 6000);
    }
  }, [loading]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  }

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      console.log(values);
    },
  });


  const { errors, touched, values } = formik;

  const handlelogin = async (loginValue, passwordValue) => {
    setLoading(true);
    loginUser(formik).then(res => {

      if (res) {
        if (res.status === 201) {
          setSnackbarTitle(" Valid Credentials")
          setSnackBarOpen(true)
          dispatch({type:"setUser",value:res.data});
          localStorage.setItem("userInfo",JSON.stringify(res.data))    
          setTimeout(()=>{navigate('/dashboard/app')}, 1000);   
        }
         else if (res.status === 400) {
             setSnackbarTitle("Invalid Credentials")
             setSnackBarOpen(true);
             setLoading(false)
         }
         else if (res.status === 401 || res.status === 409) {
             setSnackbarTitle(res.data.detail)
             setSnackBarOpen(true)
             setLoading(false)
         }
     }
     else{
         //response error 
     }
 }
  )
}
return (
  <>
<Stack spacing={3}>
    <TextField
fullWidth
id="loginEmail"
autoComplete="username"
type="email"
label= "Email address"
{...formik.getFieldProps('email')}
error={Boolean(touched.email && errors.email)}
helperText={touched.email && errors.email}
/>

        <TextField
          fullWidth
          autoComplete="current-password"
          type={showPassword ? 'text' : 'password'}
          label="Password"
          {...formik.getFieldProps('password')}
          error={Boolean(touched.password && errors.password)}
          helperText={touched.password && errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>



<Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
<Link component={RouterLink} variant="subtitle2" to="/forgotpassword" >
Forgot password?
</Link>
</Stack>

      <LoadingButton onClick={() => handlelogin(loginValue, passwordValue)} 
      loading={loading} loadingPosition="middle" fullWidth size="large" type="submit" variant="contained">
        Login
      </LoadingButton>
      <SnackBar
        open={snackBarOpen}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />
    </>
  );
}