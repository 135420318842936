import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack, IconButton, Divider } from '@mui/material';
import useResponsive from '../../hooks/useResponsive';
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { getNavConfig } from './NavConfig';
import Iconify from '../../components/Iconify';
import FeedbackPopup from "./FeedbackPopup";
import { userDetails, getProfilePicture } from "../../Services/general";

const DRAWER_WIDTH = 280;

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const [dialogState, setDialogState] = useState(false);
  const [organizationType, setOrganizationType] = useState('default');
  const isDesktop = useResponsive('up', 'lg');

  const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
      flexShrink: 0,
      width: isOpenSidebar ? DRAWER_WIDTH : 0,
    },
  }));

  var [userProfilePic, setUserProfilePic] = useState("");
  var [userName, setUserName] = useState("");
  var [setUserRole] = useState("");

  const handleUserDetails = () => {
    userDetails().then(res => {
      setUserName(res.data.firstName + " " + res.data.lastName);
      setUserRole(res.role);
    });

    getProfilePicture().then(res => {
      if (res.status === 200) {
        setUserProfilePic(res.data);
      } else {
        //Response Error
      }
    });
  };

  useEffect(() => {
    handleUserDetails();
    const userInfo = JSON.parse(localStorage.getItem('userInfo')) || {};
    setOrganizationType(userInfo.customerType || 'default');
  }, []);

  const handlesession = () => {
    setDialogState(true);
  };

  const { navConfig, userManagement, session } = getNavConfig(organizationType);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ width: "100%", px: 2.5, py: 3, display: 'inline-flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Logo />
        {isOpenSidebar ?
          <IconButton onClick={onCloseSidebar} sx={{ mr: 1, color: 'text.primary', flexDirection: 'row' }}>
            <Iconify
              icon={'akar-icons:three-line-horizontal'}
              sx={{ width: 27, height: 27, marginRight: -2.7, marginBottom: -1 }}
            />
          </IconButton> : <></>}
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="/dashboard/account">
          <AccountStyle>
            <Avatar src={userProfilePic} alt={userName} />
            <Box sx={{ ml: 2 }} >
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {userName}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection session={session} navConfig={navConfig} />
      <Box sx={{ flexGrow: 1 }} />
      <Divider sx={{ borderBottomWidth: '3px' }} />
      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          <Box
            component="img"
            src="/static/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50, justifyContent: 'center' }}
          />
          <Button variant="contained" onClick={handlesession}>
            FeedBack
          </Button>
          {dialogState && (
            <FeedbackPopup />
          )}
          <Button href="https://attentionkart.com/about-us/" target="_blank" variant="contained" size="small">
            About Us
          </Button>
        </Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          variant="persistent"
          anchor="left"
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: DRAWER_WIDTH,
              },
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
