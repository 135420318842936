import React from 'react';
import { Typography, Container, Tab, Box } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import CustomizedBreadcrumbs from '../components/Breadcrumbs';
import Page from '../components/Page';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import AddchartIcon from '@mui/icons-material/Addchart';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import General from '../sections/Accounts/General';
import Notification from '../sections/Accounts/Notifications';
import Password from '../sections/Accounts/Password';
import Billing from '../sections/Accounts/Billing';
import AnalyticsTemp from '../sections/Accounts/AnalyticsTemp';
import Users from '../sections/Accounts/Users';
import styled from "@emotion/styled";

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  minWidth: '100%',
});
  

export default function Account() {
    const [value, setValue] = React.useState('General');
    const [customPath, setCustomPath] = React.useState("General");

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setCustomPath(newValue);
    };

    return (
        
            <RootStyle>
        <Page title="Account" >
            <Container maxWidth="xl" className='tabPanel'>
                <Box>
                    <Typography variant="h4" sx={{ mb: 5 }}>
                        Account
                    </Typography>
                    <CustomizedBreadcrumbs value={customPath}/>
                </Box>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                            onChange={handleChange}
                            aria-label="icon position tabs example"
                        >
                            <Tab icon={<AccountCircleIcon />} iconPosition="start" label="General" value="General" />
                            <Tab icon={<AccountBalanceWalletIcon />} iconPosition="start" label="Billing" value="Billing" />
                        </TabList>
                    </Box>
                    <TabPanel value="General">
                        <General/>
                    </TabPanel>
                    <TabPanel value="Billing">
                        <Billing/>
                    </TabPanel>
                    <TabPanel value="Notifications">
                        <Notification/>
                    </TabPanel>
                    <TabPanel value="Analytics">
                        <AnalyticsTemp/>
                        </TabPanel>
                    <TabPanel value="changePassword">
                        <Password/>
                        </TabPanel>
                        <TabPanel value="Users">
                        <Users/>
                        </TabPanel>
                </TabContext>
            </Container>
        </Page>
        </RootStyle>
    );
}
