import React from "react";
import PropTypes from "prop-types";


const propTypes = {
    highlighted: PropTypes.bool,
    onClick: PropTypes.func,
    title: PropTypes.string,
    head: PropTypes.string,
    priceText: PropTypes.string,
    priceDetails: PropTypes.string,
    buttonClass: PropTypes.string,
    buttonText: PropTypes.string,
    children: PropTypes.node,
    highlightColor: PropTypes.string,
    highlightedColor: PropTypes.string,
    shouldDisplayButton: PropTypes.bool
};

const defaultProps = {
    highlighted: false,
    highlightColor: "#38BC41",
    highlightedColor: "#E2FBD8",
    shouldDisplayButton: true
};

class PricingSlot extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            highlighted,
            highlightColor,
          
        } = this.props;
        return (
            <div className="Grid-cell">
                <ul className="price basic-border">
                
                    <li
                        style={
                            highlighted
                                ? { backgroundColor: highlightColor }
                                : {}
                        }
                        className={
                            (highlighted ? "highlighted" : "basic") + "-header"
                        }
                    >   
                        {this.props.title}
                    </li> 
                    <br/>

                    <li className="tagText">{this.props.priceText}</li> 
                    <li className="small">{this.props.priceDetails}</li>
                    <li className="tag">{this.props.extra}</li>
                    <li className="tag">{this.props.storage}</li>
                    {this.props.children}
               </ul>

            </div>
        );
    }
}

PricingSlot.propTypes = propTypes;
PricingSlot.defaultProps = defaultProps;

export default PricingSlot;
