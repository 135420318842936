import * as Yup from 'yup';
import { Avatar, Box, Button, Card, CardContent, Container, FormControl, Grid, IconButton,Paper, Stack,TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import './account.css';
import SnackBar from '../../components/Snackbar/Snackbar';
import { MDBContainer } from "mdb-react-ui-kit";
import styled from "@emotion/styled";
import { values } from 'lodash';
import { useFormik, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import { updateUser } from '../../Services/general';
import { userDetails } from '../../Services/general';
import { useEffect } from "react";

import { setProfilePicturedetail ,getPictureUploadParams, getProfilePicture} from '../../Services/general';
import S3 from 'react-aws-s3'

export const StyleWrapper = styled.div`
img{
    border-radius: 70px;
}`


const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));


const SectionStyle = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function General() {

  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = React.useState("");

  const [data, setData] = React.useState(null);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  };

  const generalSchema = Yup.object().shape({
    firstName: Yup.string().min(5, 'Too Short!').max(50, 'Too Long!').required('First name is required'),
    lastName: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('Last name is required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    address: Yup.string().min(5, 'Too Short!').max(200, 'Too Long!').required('Address is required'),
    city: Yup.string().min(5, 'Too Short!').max(200, 'Too Long!').required('City is required'),
    state: Yup.string().min(5, 'Too Short!').max(200, 'Too Long!').required('State is required'),
    country: Yup.string().min(5, 'Too Short!').max(200, 'Too Long!').required('country is required'),
    zipcode: Yup.string().min(5, 'Check Number').max(10, 'Check Number').required('Zip Code is required'),
    organizationName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Company name is required'),
    companySize: Yup.string().min(20, 'Check Number').max(100, 'Check Number').required('Company size is required'),
    websiteUrl: Yup.string().required('Website url is required'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      organizationName: '',
      companySize: '',
      websiteUrl: '',
      address: '',
      city: '',
      state: '',
      country: '',
      zipcode: '',


    },
    validationSchema: generalSchema,
    onSubmit: () => {
      console.log(values);
    },
  });
  const { errors, touched } = formik;
  var [emailValue, setEmailValue] = useState('');
  var [firstName, setFirstName] = useState('');
  var [lastName, setLastName] = useState('');
  var [address, setAddress] = useState('');
  var [city, setCity] = useState('');
  var [state, setState] = useState('');
  var [country, setCountry] = useState('');
  var [zipcode, setZipcode] = useState('');
  var [organizationName] = useState('');
  var [websiteUrl] = useState('');
  var [companySize] = useState('');

  const [loading, setLoading] = React.useState(false);
  const [profilePicture, setProfilePicture] = React.useState("");
  const [pictureAvailable, setPictureAvailable] = React.useState(false);
  const [defaultPicture, setDefaultPicture] = React.useState(true);

  const handleupdate = async (address, city, state, country, zipcode, emailValue, firstName, organizationName, websiteUrl, companySize, lastName) => {
    setLoading(true);
    updateUser(formik).then(res => {
      if (res) {
        if (res.status === 200) {
          setSnackbarTitle("Changes updated sucessfully")
          setSnackBarOpen(true)
        }
        else if (res.status === 404) {
          setSnackbarTitle("User not found")
          setSnackBarOpen(true)
        }

        else if (res.status === 422) {
          setSnackbarTitle(res.data.detail)
          setSnackBarOpen(true)
        }
      }
      else {
        // response error
      }

    })
  }

  const handleSessionUserSummary = () => {
    userDetails().then(res => {
      if (res.status === 200) {
        if (res.data === "No data") {
          console.log("blank data")
        }
        else {
          setData(res.data);
          setInitialValues(res.data)
        }
      }
      else {
        //response error 
      }
    })
  }

  const setInitialValues = (_data) => {
    formik.setValues(values = {
      firstName: _data.firstName, lastName: _data.lastName, email: _data.email,
      organizationName: _data.organizationName, companySize: _data.companySize, websiteUrl: _data.websiteUrl,
      city: _data.Address.city, state: _data.Address.state, country: _data.Address.country, zipcode: _data.Address.zipcode,
      address: _data.Address.address })
  }


  const handleUploadImage = async (e) => {

      const config =  await getPictureUploadParams();
      console.log("config",config);
      window.Buffer = window.Buffer || require("buffer").Buffer;
      console.log("file name",e.target.files[0]["name"])
      const file_name=e.target.files[0]["name"]
      const ReactS3Client = new S3(config);
           // the name of the file uploaded is used to upload it to S3
           ReactS3Client
           .uploadFile(e.target.files[0], file_name)
           .then(data => setProfilePicturedetail(file_name))
           .catch(err => console.error(err))
   
  }
  
  const handleUserProfilePicture = () => {
    getProfilePicture().then(res => {
      if (res.status==200){
        setProfilePicture(res.data)
        setPictureAvailable(res.data==''?false:true) 
      }
    })
    
  }

  function getInitials(fullName) {
    const names = fullName.split(' ');
    const initials = names[0].substring(0, 1);
    return initials.toUpperCase();
    }
  
  useEffect(() => {
    handleSessionUserSummary()
    handleUserProfilePicture()
  }, [])

  return (
    <RootStyle>
      <StyleWrapper>
        <Box sx={{ flexGrow: 1 }}>
          <FormControl className="formControll">
            <Container maxWidth="xl">
              <Grid container spacing={2} >
              <Grid item xs={4}>
  <SectionStyle className="item">
    <Card>
      <CardContent elevation={3}>
        <Stack direction="row" className="stack">
          <input 
            accept="/image*" 
            id="icon-button-file" 
            type="file" 
            onChange={(e) => handleUploadImage(e)} 
            className="imageInput" 
            style={{ display: 'none' }} 
          />
          <label htmlFor="icon-button-file">
            <IconButton color="primary" aria-label="upload picture" component="span">
              <MDBContainer className="my-5 d-flex justify-content-center" style={{ width: 200, height: 200 }}>
                {pictureAvailable ? 
                  <Avatar 
                    alt={firstName} 
                    src={profilePicture} 
                    sx={{ width: '100%', height: '100%', objectFit: 'cover' }} 
                    variant="square" 
                  /> :
                  <Avatar sx={{ width: '100%', height: '100%', objectFit: 'cover' }}>
                    {getInitials(firstName)}
                  </Avatar>
                }
              </MDBContainer>
            </IconButton>
          </label>
        </Stack>
        <Typography className="typography labelSize" sx={{ mb: 5 }}>
          Allowed *.jpeg, *.jpg, *.png, *.gif
          <br /> max size of 3.1 MB
        </Typography>
      </CardContent>
    </Card>
  </SectionStyle>
</Grid>

                <Grid item xs={8}>
                  <FormikProvider value={formik} >
                    <Stack spacing={3} alignItems="center" justifyContent="center" direction={"row"}>
                      <TextField sx={{ width: 400 }}
                        disabled
                        label="First name"
                        {...formik.getFieldProps('firstName')}
                        error={Boolean(touched.firstName && errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                      />
                      <TextField
                        disabled
                        sx={{ width: 400 }}
                        autoComplete='lastName'
                        type='text'
                        label="Last name"
                        {...formik.getFieldProps('lastName')}
                        error={Boolean(touched.lastName && errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                      />
                    </Stack>
                    <br />
                    <Stack spacing={3} alignItems="center" justifyContent="center" direction={"row"}>
                      <TextField sx={{ width: 400 }}
                        disabled
                        autoComplete="username"
                        type="email"
                        label="Email "
                        {...formik.getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />

                      <TextField sx={{ width: 400 }}
                        label="Address"
                        {...formik.getFieldProps('address')}
                        error={Boolean(touched.address && errors.address)}
                        helperText={touched.address && errors.address}
                      />
                    </Stack>
                    <br />
                    <Stack spacing={3} alignItems="center" justifyContent="center" direction={"row"}>
                      <TextField sx={{ width: 400 }}
                        label="City"
                        {...formik.getFieldProps('city')}
                        error={Boolean(touched.city && errors.city)}
                        helperText={touched.city && errors.city}
                      />
                      <TextField sx={{ width: 400 }}
                        label="State"
                        {...formik.getFieldProps('state')}
                        error={Boolean(touched.state && errors.state)}
                        helperText={touched.state && errors.state}
                      />
                    </Stack>
                    <br />
                    <Stack spacing={3} alignItems="center" justifyContent="center" direction={"row"}>
                      <TextField sx={{ width: 400 }}
                        label="Country"
                        {...formik.getFieldProps('country')}
                        error={Boolean(touched.country && errors.country)}
                        helperText={touched.country && errors.country}
                      />
                      <TextField sx={{ width: 400 }}
                        autoComplete="Zip Code"
                        type="number"
                        label=" Zip Code "
                        {...formik.getFieldProps('zipcode')}
                        error={Boolean(touched.zipcode && errors.zipcode)}
                        helperText={touched.zipcode && errors.zipcode}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 12)
                        }}
                      />
                    </Stack>
                    <br />
                    <Stack spacing={3} alignItems="center" justifyContent="center" direction={"row"}>
                      <TextField
                        disabled
                        sx={{ width: 400 }}
                        autoComplete="websiteUrl"
                        type="text"
                        label="Website Url"
                        {...formik.getFieldProps('websiteUrl')}
                        error={Boolean(touched.websiteUrl && errors.websiteUrl)}
                        helperText={touched.websiteUrl && errors.websiteUrl}
                      />
                      <TextField
                        disabled
                        sx={{ width: 400 }}
                        autoComplete="organizationName"
                        type="text"
                        label="Company Name"
                        {...formik.getFieldProps('organizationName')}
                        error={Boolean(touched.organizationName && errors.organizationName)}
                        helperText={touched.organizationName && errors.organizationName}
                      />
                    </Stack>
                    <br />
                    <TextField
                      disabled
                      sx={{ width: 265 }}
                      autoComplete="organizationSize"
                      type="text"
                      label="Company Size"
                      {...formik.getFieldProps('companySize')}
                      error={Boolean(touched.companySize && errors.companySize)}
                      helperText={touched.companySize && errors.companySize}
                    />
                    <Button variant="contained" color="success" className="buttonSubmit"
                      loading={loading} loadingPosition="middle"
                      onClick={() => handleupdate(address, city, state, country, zipcode, emailValue, firstName, organizationName, websiteUrl, companySize, lastName)}>
                      Save Changes
                    </Button>
                  </FormikProvider>
                  <SnackBar
                    open={snackBarOpen}
                    close={handleClose}
                    snackbarTitle={snackbarTitle}
                  />
                </Grid>
              </Grid>
            </Container>
          </FormControl>
        </Box>

      </StyleWrapper>
    </RootStyle>


  );
}