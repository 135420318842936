import * as React from 'react';
import { emphasize, styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import { Link } from '@mui/material';


function capitalizeFirstLetter(str) {
  return (str.charAt(0).toUpperCase() + str.slice(1));
}

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

function handleClick(event) {
  console.info('You clicked a breadcrumb.');
}

const DASHBOARD_PATH = "/dashboard/app";

export default function CustomizedBreadcrumbs(props) {
  let path = window.location.pathname.split('/');
  path = path.filter(item => item !== "");
  let breadCrumbList = [];
  let temp = "";
  breadCrumbList = path.map(item => {
    temp = temp + `/${item}`;
    return {
      label: item,
      href: temp === "/dashboard" ? DASHBOARD_PATH : temp
    }
  })
  if (props.value) {
    breadCrumbList.push(
      {
        label: props.value,
        href: `/${props.value}`
      }
    );
  }

  return (
    <>
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        {breadCrumbList.map((item) => (
          <Link
            underline="none"
            color="inherit"
            href={item.href}
          >
            <StyledBreadcrumb label={capitalizeFirstLetter(item.label)} />
          </Link>
        ))}
      </Breadcrumbs>
    </div></>
  );
}
