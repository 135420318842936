import React, { useState } from 'react';
// material
import { Grid, Paper,Stack, TablePagination, Box } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { filter } from 'lodash';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";
import Card from '@mui/material/Card';


export const StyleWrapper = styled.div`
.css-1fmcalr-MuiTableCell-root
{
    text-align: center;
}`

const column = [
  { field: "Timeframe", headername: "Timeframe" },
  { field: "Facevisible", headername: "Facevisible" },
  { field: "Speakers", headername: "Speakers" },
  { field: "Topic", headername: "Topic" },
 ]

const rows = [
  {
    Timeframe: "0-1 mins",
    Facevisible: "Andrea",
    Speakers: "",
    Topic: "",
  },
  {
    Timeframe: "1-2 mins",
    Facevisible: "Andrea, Jharna",
    Speakers: "Jharna",
    Topic: "",
  },
  {
    Timeframe: "2-3 mins",
    Facevisible: "Andrea, Jharna",
    Speakers: "Andrea, Jharna",
    Topic: "food,potato,",
  },
  {
    Timeframe: "3-4 mins",
    Facevisible: "Andrea, Jharna",
    Speakers: "Andrea, Jharna",
    Topic: "sentence,paragraph",
  },
  {
    Timeframe: "4-5 mins",
    Facevisible: "Andrea, Jharna",
    Speakers: "Andrea, Jharna",
    Topic: "sentence,paragraph",
  },
  {
    Timeframe: "5-6 mins",
    Facevisible: "Andrea, Jharna",
    Speakers: "Andrea",
    Topic: "sentence,paragraph",
  },
  {
    Timeframe: "6-7 mins",
    Facevisible: "Andrea, Jharna",
    Speakers: "Andrea",
    Topic: "one, was",
  },
  {
    Timeframe: "7-8 mins",
    Facevisible: "Andrea, Jharna",
    Speakers: "Andrea, Jharna",
    Topic: "",
  },
  {
    Timeframe: "8-9 mins",
    Facevisible: "Andrea, Jharna",
    Speakers: "Andrea",
    Topic: "",
  },
  {
    Timeframe: "9-10 mins",
    Facevisible: "Andrea, Jharna",
    Speakers: "Andrea, Jharna",
    Topic: "",
  },
  {
    Timeframe: "10-11 mins",
    Facevisible: "Andrea, Jharna",
    Speakers: "Andrea",
    Topic: "",
  },
  {
    Timeframe: "11-12 mins",
    Facevisible: "Andrea, Jharna",
    Speakers: "Andrea",
    Topic: "",
  },
  {
    Timeframe: "12-13 mins",
    Facevisible: "Andrea, Jharna",
    Speakers: "Andrea",
    Topic: "",
  },
  {
    Timeframe: "13-14 mins",
    Facevisible: "Andrea, Jharna",
    Speakers: "Andrea",
    Topic: "",
  },
  {
    Timeframe: "14-15 mins",
    Facevisible: "Andrea, Jharna",
    Speakers: "Andrea",
    Topic: "",
  },
  {
    Timeframe: "15-16 mins",
    Facevisible: "Andrea, Jharna",
    Speakers: "Andrea",
    Topic: "",
  },
  {
    Timeframe: "16-17 mins",
    Facevisible: "Andrea, Jharna",
    Speakers: "Andrea, Jharna",
    Topic: "",
  },
  {
    Timeframe: "17-18 mins",
    Facevisible: "Andrea, Jharna",
    Speakers: "Andrea",
    Topic: "",
  },
  {
    Timeframe: "18-19 mins",
    Facevisible: "Andrea, Jharna",
    Speakers: "Andrea",
    Topic: "",
  },
  {
    Timeframe: "19-20 mins",
    Facevisible: "Andrea, Jharna",
    Speakers: "Andrea, Jharna",
    Topic: "",
  },
]

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.Name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (

    <TableHead>
      <TableRow>
        {column.map((row) => (
          <TableCell>
            <TableSortLabel
              active={orderBy === row.field}
              direction={orderBy === row.field ? order : 'asc'}
              onClick={createSortHandler(row.field)}
            >
              {row.field}
              {orderBy === row.Name ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


export default function Detalgorithmltable() {
  
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('Topic');
  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

 
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  


  return (
    <>
    <StyleWrapper>
      <Card
        sx={{
          p: 2.5,
          flexGrow: 1,
          justifyContent: "center",
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? '#1A2027' : '#ffffff',
        }}>
        <StyleWrapper>
          <Grid container spacing={-0.5}>
           <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}  aria-label="a dense table">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />

            <TableBody>
              {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                const { field, Timeframe, Speakers, Topic, Facevisible } = row;
                const isItemSelected = selected.indexOf(Timeframe) !== -1;

                return (
                  <TableRow
                    hover
                    key={field}
                    tabIndex={-1}
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                  >

                    <TableCell align="center">{Timeframe} </TableCell>
                    <TableCell align="center">{Facevisible}</TableCell>
                    <TableCell align="center">{Speakers}</TableCell>
                    <TableCell align="center">{Topic}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>

          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          </TableContainer>
          </Grid>
        </StyleWrapper>
      </Card>
      </StyleWrapper>
    </>
  );
  
} 

