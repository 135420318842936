import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Stack from '@mui/material/Stack';
import {Divider} from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {Tooltip} from "@mui/material";
import Teams from "./Teams";
import Zoom from "./Zoom";

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2)
  }
}));



export default function ScheduleMeeting(props) {
  const [open, setOpen] = React.useState(true);
  
  const handleClose = () => {
    setOpen(false);
    // window. location. reload(false);
    window.location.replace('/dashboard/online');
  };

const [dialogStatetwo,setDialogstatetwo]=React.useState(false);
const [dialogStatethree,setDialogstatethree]=React.useState(false);

const handlesessiontwo = () => {
  setDialogstatetwo(true)
}
const handlesessionthree = () => {
  setDialogstatethree(true)
}
  
  
return (
    <RootStyle>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        justifyContent="center"
        alignItems="center"
       >
        <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
        <Stack style={{alignItems:"center"}}>
        <h3> Schedule Meeting</h3>
        </Stack>
        <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
        <Divider variant="middle"/>
        <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
        
        <DialogContent style={{alignItems:"center",width:"300px"}}>
        <div>
           <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">Select Meeting App</FormLabel>
      <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
      <RadioGroup aria-labelledby="demo-radio-buttons-group-label"defaultValue="female"name="radio-buttons-group" style={{ paddingLeft: '20px' }}>
        <FormControlLabel value="Teams" control={<Radio />} onClick={handlesessiontwo} label={
          <Tooltip id="button-report" placement="right-start" title="Teams">
          <img src="/static/icons/Teams48.png"alt="Teams" style={{ paddingLeft: '20px' }}/>
          </Tooltip>
        } />
         { dialogStatetwo &&  (
           <Teams date={props.date}/>
           )}
        <br/>
        <FormControlLabel value="Zoom" control={<Radio />} onClick={handlesessionthree}label={
          <Tooltip id="button-report" placement="right-start" title="Zoom">
            
          <img src="/static/icons/Zoom48.png"alt="Zoom" style={{ paddingLeft: '20px' }}/>
          </Tooltip>
        } />
         {dialogStatethree &&  (
           <Zoom date={props.date}/>
           )}
       
      </RadioGroup>
    </FormControl>
           </div>
    
     
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} >
            Ok
          </Button>
          <Button  onClick={handleClose}autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
      </RootStyle>
  );
}

