import React,{ useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import {  Stack,  TextField } from '@mui/material';
// material
import { LoadingButton } from '@mui/lab';
import { emailUser } from "../../../Services/emailVerification";
import SnackBar from "../../../components/Snackbar/Snackbar";


// ----------------------------------------------------------------------

export default function RegisterEmailVerificationForm() {

const navigate = useNavigate();

var [loginValue, setLoginValue] = useState("");
const [loading, setLoading] = React.useState(false);
const [snackBarOpen, setSnackBarOpen] = React.useState(false);
const [snackbarTitle, setSnackbarTitle] = React.useState("");

useEffect(() => {
  if (loading) {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }
}, [loading]);

    const handleClose = (event, reason) => {
     if (reason === "clickaway") {
      return;
     }
     setSnackBarOpen(false);
    }  


    const EmailVerificationSchema = Yup.object().shape({
    });
    
    const formik = useFormik({
      initialValues: {
        email: '',
      },
      
      validationSchema: EmailVerificationSchema,
      onSubmit: () => {
      console.log(values);  
      },
    });

    const { errors, touched, values} = formik;

    const handleRequest = async (loginValue, pagename) => {
      setLoading(true);
      emailUser(loginValue, "RegisterEmailVerification").then(res=>
        {
          if(res){
            if (res.status === 200) {
            navigate(`/emailVerification/${loginValue}/${pagename}`)
            }
            else if (res.status === 422) {
                setSnackbarTitle(res.data.detail)
                setSnackBarOpen(true)
            }

            else if (res.status === 404) {
                setSnackbarTitle(res.data.detail)
                setSnackBarOpen(true)  
            }
        }
        })

    }


  return (
    <>
      <Stack spacing={3}>
    <TextField
fullWidth
id="loginEmail"
autoComplete="username"
type="email"
label= "Email address"
{...formik.getFieldProps('email')}
error={Boolean(touched.email && errors.email)}
helperText={touched.email && errors.email}
value={loginValue}
onChange={e => setLoginValue(e.target.value)}
/>
</Stack>
<br/>

         <LoadingButton loading={loading} loadingPosition="middle" onClick={() => handleRequest(loginValue, "RegisterEmialVerification")} fullWidth size="large" type="submit" variant="contained" >
            Send Otp
         </LoadingButton>
         <SnackBar
                open={snackBarOpen}
                close={handleClose}
                snackbarTitle={snackbarTitle} 
            />
            </>
  );
}


