import React from "react";
import { useRef, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {helpers} from '../helper/index';
const settings = require('../settings');
const apiEndpoint = settings.MicrosoftintegrationApiEndpoint;


export {createmeeting,getazuretimezone,checkvalidmeetinglink,startmeetinganalytics}


function createmeeting(meetingdetails){
    const OrganizationId = JSON.parse(localStorage.getItem("userInfo")).organizationId;
    const userId = JSON.parse(localStorage.getItem("userInfo")).userId;

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`${apiEndpoint}/createTeamsmeeting?organizationId=${OrganizationId}&userId=${userId}`, 
    JSON.stringify(meetingdetails),requestOptions)
        .then(res => res)
        .catch(error => error.response)
                 
}


function getazuretimezone(){
   
    return axios.get(`${apiEndpoint}/gettimezonelist`)
        .then(res => res)
        .catch(error => error.response)
                 
}


function checkvalidmeetinglink(meetinglink) {

    return axios.get(`${apiEndpoint}/checkvalidmeetinglink?meetlinglink=${meetinglink}`)
        .then(res => res)
        .catch(error => error.response)

}

function startmeetinganalytics(meetinglink) {

    return axios.get(`${apiEndpoint}/startmeetinganalytics?meetlinglink=${meetinglink}`)
        .then(res => res)
        .catch(error => error.response)

}


