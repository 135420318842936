import { Link as RouterLink } from 'react-router-dom';
import { Card, Link, Chip, Typography, Button, Stack, Box,IconButton } from '@mui/material';
import Divider from '@mui/material/Divider';
import useResponsive from '../hooks/useResponsive';
import Page from '../components/Page';
import Logo from '../components/Logo';
import AuthSocial from '../sections/auth/AuthSocial';
import styled from "@emotion/styled";
import { Icon } from '@iconify/react';
import ReactTooltip from "react-tooltip";

export const StyleWrapper = styled.div`
.css-7xbxup-MuiChip-root{
    font-size: x-large;
    font-weight: 500;
    padding-left: 30px;
    padding-right: 30px;
}
.css-78jmu5-MuiChip-root{
  color: #38BC41;
  background-color: #E2FBD8;
}
.css-alic6i-MuiPaper-root-MuiCard-root{
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
  }
`

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const CardStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 1000,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 4),
}));

export default function Register() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  

  return (
    <Page title="Register">
      <StyleWrapper>
      <RootStyle>
        <HeaderStyle>
          <Logo />
          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
             
            </Typography>
          )}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hey!! We are here to manage your meetings and its analytics more effectively.
            </Typography>
            <img alt="register" src="/static/illustrations/illustration_register.png" />
          </SectionStyle>
        )}
        <CardStyle>
          <Card
          sx={{
            p: 5,
            margin: '9',
            maxWidth: 1000,
            flexGrow: 1,
            justifyContent: "center",
            backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? '#1A2027' : '#ffffff',
           }}>
        <Stack spacing={3} alignItems="center" justifyContent="center" >
          <Typography><strong>
          If you already have an account.
          </strong>
          </Typography>
          <Button size="medium"sx={{width: 100}} backgroundColor="#38BC41" variant="contained" to="/login" component={RouterLink}>
                  Log In
          </Button>
        </Stack>
        <br/>
        <Divider>
        <Chip label="OR" />
      </Divider>
      <br/>
      <Stack spacing={3} alignItems="center" justifyContent="center" >
      <Typography><strong>
        <h3>
        Sign Up
        </h3>
      </strong>  
      </Typography>
      </Stack>
        <br/>
        
      <Stack spacing={3} direction="row" alignItems="center" justifyContent="center">
            <Stack spacing={3} alignItems="center" justifyContent="center" >
              <Button size="medium"sx={{width: 100}} backgroundColor="#38BC41" variant="contained" to="/IndividualRegister" component={RouterLink}>
              Individual
              </Button>
            </Stack>            
          
            <Stack spacing={3} alignItems="center" justifyContent="center" >
              <Button size="medium"sx={{width: 100}} backgroundColor="#38BC41" variant="contained" to="/CompanyRegister" component={RouterLink}>
                Company
              </Button>
            </Stack> 
        </Stack>  
        <Stack spacing={1} alignItems="center" justifyContent="center" >
            <Link component={RouterLink} variant="subtitle2" to="/dashboard/app" >
             <IconButton  data-place="right" data-tip data-for="registerTip" style={{marginLeft: "auto",padding:"15px"}}>
               <Icon icon="mingcute:information-fill" color="#38BC41" width="30" height="30" edge="false"  />
             </IconButton>
            </Link>
            <ReactTooltip backgroundColor="#E2FBD8" textColor="#38BC41" id="registerTip" place="top" effect="solid">
              <strong> How to select for sign up </strong> 
             </ReactTooltip>
         </Stack>
        <Box
            component="img"
            src="/static/mock-images/images/image1.png"
            sx={{ width: 800, height: 300, margin: "auto", display: 'block' }}
          />
      </Card>    
   </CardStyle>             
            <AuthSocial />


            {!smUp && (
              <Typography variant="body2" sx={{ mt: 3, textAlign: 'center' }}></Typography>)}
           </RootStyle>
      </StyleWrapper>
    </Page>
  );
}

