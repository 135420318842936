import React,{ useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ResetPasswordUser } from "../../../Services/resetPassword";
import SnackBar from "../../../components/Snackbar/Snackbar";
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function ResetPasswordForm() {

const navigate = useNavigate();

var [loginValue] = useState("");
var [passwordValue] = useState("");
const [snackBarOpen, setSnackBarOpen] = React.useState(false);
const [snackbarTitle, setSnackbarTitle] = React.useState("");
const [showPassword, setShowPassword] = useState(false);
const [loading, setLoading] = React.useState(false);
  
useEffect(() => {
  if (loading) {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }
}, [loading]);

const handleShowPassword = () => {
setShowPassword((show) => !show);
};

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  password: Yup.string().required('Password is required'),
});

const formik = useFormik({
  initialValues: {
    email: '',
    password: '',
    },
  validationSchema: ForgotPasswordSchema,
  onSubmit: () => {
  console.log(values);  
  },
});

const { errors, touched, values } = formik;


    const handleClose = (event, reason) => {
     if (reason === "clickaway") {
      return;
     }
     setSnackBarOpen(false);
    }  

    const handleRequest = async () => {
      setLoading(true);
      ResetPasswordUser(formik).then(res => {
         
         if(res){
             if (res.status === 201) {
                setTimeout(() => {
              setSnackbarTitle(" Valid Credentials")
              setSnackBarOpen(true)
              navigate('/login')
            }, 2000);
             }
             else if (res.status === 400) {
                 setSnackbarTitle("Invalid Credentials")
                 setSnackBarOpen(true)
             }
             else if (res.status === 401) {
                 setSnackbarTitle("Invalid Credentials")
                 setSnackBarOpen(true)  
             }
         }
         else{
             //response error 
         }
     }
    )
    }


  return (
    <>
      <Stack spacing={3}>
    <TextField
fullWidth
id="loginEmail"
autoComplete="username"
type="email"
label= "Email address"
{...formik.getFieldProps('email')}
error={Boolean(touched.email && errors.email)}
helperText={touched.email && errors.email}
/>

<TextField
fullWidth
autoComplete="current-password"
type={showPassword ? 'text' : 'password'}
label="Password"
{...formik.getFieldProps('password')}
error={Boolean(touched.password && errors.password)}
helperText={touched.password && errors.password}
                InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
/>

</Stack>
<br/>

         <LoadingButton  loading={loading} loadingPosition="middle" onClick={() => handleRequest( loginValue,passwordValue)} fullWidth size="large" type="submit" variant="contained" >
            Change Password
         </LoadingButton>
         <SnackBar
                open={snackBarOpen}
                close={handleClose}
                snackbarTitle={snackbarTitle} 
            />
            </>
  );
}

