import axios from 'axios';
const settings = require('../settings');
const apiEndpoint = settings.ApiEndPoint;


export {loginUser} ;

function buildReqBody(formik) {
    return {
        loginEmail: formik.values.email,
        password: formik.values.password,
         }
}

function loginUser(formik) {
    const requestOptions = {

        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`${apiEndpoint}/login`,
        JSON.stringify(buildReqBody(formik)), requestOptions)
        .then(res => res)
        .catch(error => error.response)
}