import Router from './routes';
import ThemeProvider from './theme';
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import React from "react";
// import { createBrowserHistory } from 'history';
import { UserProvider } from './context/userContext';



export default function App() {
  //  var { isAuthenticated } = useState();
  //  const history = createBrowserHistory();
  //  var DefaultRoute = Router.DefaultRoute;
  return (
    <ThemeProvider>
      <UserProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <Router />
    </UserProvider>
    </ThemeProvider>  
  );
}