import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Stack, MenuItem, IconButton, Link, Dialog, DialogActions,
  DialogContent,DialogTitle, CardMedia, TextField, 
  DialogContentText, Grid, Paper,
  TableContainer} from '@mui/material';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import Label from '../../../components/Label';
import SearchNotFound from '../../../components/SearchNotFound';
import {  UserListToolbar, UserMoreMenu } from '../../../sections/@dashboard/user';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CustomizedBreadcrumbs from '../../../components/Breadcrumbs';
import PropTypes from 'prop-types';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import styled from "@emotion/styled";
import { Link as RouterLink, useNavigate  } from 'react-router-dom';
import { Icon } from '@iconify/react';
import React, { useState } from 'react';
import ReactTooltip from "react-tooltip";
import { getuploadparams,getvideourl,checkvideoname,setuploaddetails,getofflinesession,editofflinesession,deleteofflinesession } from "../../../Services/offlinesession";
import { checkeligibilty,executeanalytics } from "../../../Services/run";
import SnackBar from "../../../components/Snackbar/Snackbar";
import S3 from 'react-aws-s3';


export const StyleWrapper = styled.div`
.css-1fmcalr-MuiTableCell-root
{
    text-align: center;
}
.css-wq0irl-MuiPaper-root-MuiSnackbarContent-root{
  background-color:#E2FBD8;
  color:#38BC41;
}`
// ----------------------------------------------------------------------

function getSteps() {
  return ["Choose Video File", "Select Session Template", "Upload video", "Complete the Process"];
}



  



function GetStepContent(step) {

  const [sessionType, setSessionType] = React.useState("monitoring");
  const [filename, setFileName] = React.useState("");
  const [fileinfo,setFileinfo] = React.useState({});
  const [filedata,setFileData]= React.useState({});
  const [processStatus,setProcessStatus]=React.useState("");
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = React.useState("");

  const handlesessiontype = (event) => {
    console.log("Event.target.value is", event.target.value);
    setSessionType(event.target.value)
  }

  const handlefileupload = () => {
    if(filename =="") {
      setSnackbarTitle("no file selected")
      setSnackBarOpen(true)

    }
    else {
    getuploadparams(filename).then(res=>
      {
        if(res){
          if (res.status === 200) {
           console.log(".......",res.data) 
           window.Buffer = window.Buffer || require("buffer").Buffer;
           console.log("............",fileinfo)
           const ReactS3Client = new S3(res.data);
            // the sessionName of the file uploaded is used to upload it to S3
           ReactS3Client
           .uploadFile(filedata, filename)
           .then(data => {
            
            console.log(data.location)
            setuploaddetails(fileinfo.size,fileinfo.duration,filename,sessionType).then(
              res=> {
                if(res){
                  if (res.status === 200) {
                   console.log(".......",res.data) 
                  }
                  else {
                    
                  }
                }
              }
            )
            
           })
                      
           .catch(err => console.error(err))
           }
          else {
            setSnackbarTitle("error in file upload")
            setSnackBarOpen(true)
          }
      }
      })
    }
    //upload

    //set upload details
  }

  const handleChangeStatus = ({ meta,file, remove }, status) => {
    console.log("file status",status,file)
    if (status === 'preparing') {

      //check video Name
      checkvideoname(file.name).then(res=>
        {
          if(res){
            if (res.status === 200) {
              setFileName(file.name)
            }
            else {
              setSnackbarTitle("video with Name already exists")
              setSnackBarOpen(true)
            }
        }
        })
     
    }

  else if (status==='done') {

    setFileinfo(meta) 
    setFileData(file)
  }

   else if (status === 'aborted') {
    remove()
  }
  }
  switch (step) {
    case 0:
      return (
        <>
      
        <Dropzone
          accept="video/*"
          inputContent={"Drag and Drop or upload video"}
          submitButtonContent={"Save"}
          onChangeStatus={handleChangeStatus}
   
          maxFiles={1}
          multiple={false}
          canCancel={true}
          styles={{
            dropzone: { overflow: 'auto', minHeight: 100, maxHeight: 20, width: 500 },
            inputLabel: { color: '#38BC41' },
            submitButton: { backgroundColor: '#38BC41', color: '#E2FBD8' }
          }}      
        />
        </>);

    case 1:
      return (
        <Stack direction="row" justifyContent="center" spacing={0}>
          <FormControl required={true} sx={{ m: 1, minWidth: 300, textAlign: "center" }}>
            <InputLabel id="Select Session template"> Select Session template</InputLabel>
            <Select defaultValue={sessionType} label="Select Session template" onChange={handlesessiontype}>
              <MenuItem value={"Team Meetings"}> Team Meetings</MenuItem>
              <MenuItem value={"Project Updates"}> Project Updates</MenuItem>
              <MenuItem value={"Training & Workshops"}> Training & Workshops</MenuItem>
              <MenuItem value={"Brainstorming Sessions"}> Brainstorming Sessions</MenuItem>
              <MenuItem value={"Performance Reviews"}> Performance Reviews</MenuItem>
              <MenuItem value={"Client Meetings"}> Client Meetings</MenuItem>
              
            </Select>
          </FormControl>
          <Stack spacing={1} alignItems="center" justifyContent="center" >
            <Link component={RouterLink} variant="subtitle2" to="/dashboard/app" >
              <IconButton data-place="right" data-tip data-for="registerTip" style={{ marginLeft: "auto", padding: "15px" }}>
                <Icon icon="mingcute:information-fill" color="#38BC41" width="30" height="30" edge="false" />
              </IconButton>
            </Link>
            <ReactTooltip backgroundColor="#E2FBD8" textColor="#38BC41" id="registerTip" place="top" effect="solid">
              <strong> How to select session template </strong>
            </ReactTooltip>
          </Stack>
        </Stack>
      );
    case 2:
      return (
        <Stack spacing={2}>
       
        <Paper>
        <Typography variant="h4">File</Typography>
          <Typography sx={{ wordBreak: "break-word" }}>{filename}</Typography>
          </Paper>

          <Paper>
          <Typography variant="h4">Session Type</Typography>
          <Typography sx={{ wordBreak: "break-word" }}>{sessionType}</Typography>
          </Paper>
          <Stack spacing={1} alignItems="center" justifyContent="center" >
          <Button
              onClick={handlefileupload}
                sx={{ mr: 1 }}
              >
                Confirm upload
              </Button>
            </Stack>
          </Stack>

      );
    case 3:
      return "Process completed";
  
  }
}

const column = [
  { field: "sessionName", headername: "sessionName" },
  { field: "Type", headername: "Type" },
  { field: "Duration", headername: "Duration" },
  { field: "Date", headername: "Date" },
  { field: "Time", headername: "Time" },
  { field: "Status", headername: "Status" },
  { field: "Preview", headername: "Preview" },
  { field: "Action", headername: "Action" },
]






function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.sessionName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (

    <TableHead>
      <TableRow>
        {column.map((row) => (
          <TableCell>
            <TableSortLabel
              active={orderBy === row.field}
              direction={orderBy === row.field ? order : 'asc'}
              onClick={createSortHandler(row.field)}
            >
              {row.field}
              {orderBy === row.sessionName ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function OfflineForm(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [selected] = useState([]);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('Date');
  const [showvideo,setshowvideostate]=React.useState(false);
  const [videourl,setvideourlstate]=React.useState("");
  const [open, setOpen] = React.useState(false);
  const [runopen,setRunopen]=React.useState(false);
  const [showrechargedialogue,setShowrechargedialogue]=React.useState(false);
  const [showrunstartdialogue,setShowrunstartdialogue]=React.useState(false);
  const [showerrordialogue,setShowerrordialogue]=React.useState(false);
  const [showrunstatus,setShowrunstatus]=React.useState(false);
  const [selectedsession, setSelectedsession]=React.useState("");
  const [showrundialogue,setShowdialogue]=React.useState(false);
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [participant,setparticipantstate]=React.useState("");
  const [snackbarTitle, setSnackbarTitle] = React.useState("");
  const [rows, setRows] = React.useState([]);

  const navigate = useNavigate();
  


  React.useEffect(() => {
    getofflinesession() .then(res=> {
      console.log(res.data["items"])
      setRows(res.data["items"])
    }
      )
  
  }, [])
 
  const handleRuneligility = (e) => {
    setRunopen(false)
    checkeligibilty(participant,e.currentTarget.value).then(res => {
    console.log(res.data)
    if (res.data==="elgible")
       {
        setShowrunstartdialogue(true)
        executeanalytics(selectedsession,participant)
        window.location.reload(false);
       }
    else if (res.data==="low balance") {  
        setShowrechargedialogue(true)
    }
    else
    {
        setShowerrordialogue(true)
    }
    })
  }

  const handleClose = (event, reason) => {
    if (reason === "onclick") {
        return;
    }
    setSnackBarOpen(false);
    }

  const handleDialogClose = () => {
    setOpen(false);
    setRunopen(false);
    setShowrechargedialogue(false);
    setShowrunstartdialogue(false);
    setShowerrordialogue(false);
    setShowrunstatus(false)
  };

  const handleRunButton = (e) => {
    console.log(e.currentTarget.id)
    console.log(e.currentTarget.value)
    setSelectedsession(e.currentTarget.id)
    if (e.currentTarget.value==="processing")
      {
        console.log("open status")
        setShowrunstatus(true)
      }
    else { 
    
        setShowdialogue(true)
        setRunopen(true)
         }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), filterName);




  const handleReset = () => {
    setActiveStep(0);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handlePlayButton = (e) => {
    console.log(e.currentTarget.value)
    getvideourl(e.currentTarget.value).then(res => {
      console.log("video data",res.data)
      setvideourlstate(res.data)
      setshowvideostate(true)
      setOpen(true);
    })
}

const handleHistoryButton = (e) => {
  console.log(e.currentTarget.value)
  if (e.currentTarget.value === "executed") {  
       navigate("/dashboard/Analytics", {state: {userInfo: "Id"}});

     }
  else {
      setSnackbarTitle("File should be in executed state to preview")
      setSnackBarOpen(true)
  } 
}

  return (
    <>
    <StyleWrapper>
      <CustomizedBreadcrumbs />
      <Box sx={{ width: "100%" }}>
        <br />
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <br />
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>{GetStepContent(activeStep)}</Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0 || activeStep === steps.length - 1}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleNext} disabled={activeStep === steps.length - 1}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Box>
      <br />
      <Card
        sx={{
          p: 2.5,
          flexGrow: 1,
          justifyContent: "center",
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? '#1A2027' : '#ffffff',
        }}>
        <StyleWrapper>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
          </Stack>
          <Grid container spacing={-0.5}>
           <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}  aria-label="a dense table">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />

            <TableBody>
              {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                const { field, sessionId , sessionName, duration, uploadedDatetime, status, sessionType } = row;
                const isItemSelected = selected.indexOf(sessionName) !== -1;

                return (
                  <TableRow
                    hover
                    key={field}
                    tabIndex={-1}
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                  >

                    <TableCell align="left">{sessionName} </TableCell>
                    <TableCell align="left">{sessionType}</TableCell>
                    <TableCell align="left">{duration}</TableCell>
                    <TableCell align="center">{uploadedDatetime}</TableCell>
                    <TableCell align="left">
                      <Label align="left" variant="ghost" color={(status === 'banned' && 'Error') || 'success'}>{sentenceCase(status)} </Label>
                    </TableCell>
               <TableCell align="left">{row.Preview}
                      <Button onClick = { handlePlayButton } value={sessionId} variant="contained" size="small" color="primary">Play</Button>
                      {showvideo && (
        <Dialog
        style={{opacity:0.8}}
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
       <CardMedia  component="video" image={videourl} title={sessionName} controls controlsList="nodownload"/>  
       </Dialog> 
       )}
       </TableCell>
                    <TableCell align='right'>{row.Action}
                      <Stack direction="row" spacing={1}>
                        <Button onClick = { handleRunButton } id={sessionName} value={status} variant="contained" size="small" color="primary">Run</Button>
      {showrundialogue && (
        <Dialog style={{opacity:0.8}} open={runopen} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Session Execution</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the number of partcipants in the session
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="participantnum"
            label="Partcipant's Number"
            type="number"
            fullWidth
            onChange={(e) => setparticipantstate(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button value={duration} onClick={handleRuneligility} color="primary">
            Check
          </Button>
        </DialogActions>
      </Dialog>
    )}
    { showrunstatus && (
    <Dialog style={{opacity:0.8}} open={showrunstatus} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">processsing  status</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Session is already executing
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    
    )}
    { showrechargedialogue && (
        <Dialog
        style={{opacity:0.8}}
        open={showrechargedialogue}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Insufficient Balance. Please recharge
          </DialogContentText>
          <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
        </DialogContent>
        </Dialog>
    
    )}
    { showrunstartdialogue && (
    
     <Dialog
     style={{opacity:0.8}}
        open={showrunstartdialogue}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Execution started . Computation will take time to complete. Please wait........
          </DialogContentText>
          <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
        </DialogContent>
        </Dialog>
    
    )}
    {showerrordialogue && (
        <Dialog
        style={{opacity:0.8}}
        open={showerrordialogue}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           OOPS. There is an error. Please wait till we figure it
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
        </Dialog>
     )}
    <Button  onClick ={handleHistoryButton}
       value={status} variant="contained" size="small" color="primary">Preview</Button>
        <SnackBar
                open={snackBarOpen}
                close={handleClose}
                snackbarTitle={snackbarTitle}
            />
                      </Stack>
                    </TableCell>
                    <TableCell align="left"><UserMoreMenu/></TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>

           
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          </TableContainer>
          </Grid>
        </StyleWrapper>
      </Card>
      </StyleWrapper>
    </>
  );
}
