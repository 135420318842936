import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import User from './pages/User';
import Login from './pages/Login'; 
import ContactSupport from './pages/contactSupport';
import IndividualRegister from './pages/IndividualRegister';
import NotFound from './pages/Page404';
import PaymentSucess from './pages/PaymentSucess';
import PaymentFailed from './pages/PaymentFailed';
import Error from './pages/Error';
import Register from './pages/Register';
import CompanyRegister from './pages/CompanyRegister';
import DashboardApp from './pages/DashboardApp';
import ForgotPasswordForm from './pages/forgotPassword';
import RegisterEmailVerificationForm from './pages/RegisterEmailVerification';
import ResetPasswordForm from './pages/resetPassword';
import Analytics from './pages/Analytics';
import Camerakart from './pages/CameraKart';
import AKEmoeats from './pages/AkEmoeats';
import Integration from './pages/Integration';
import EmailVerificationForm from './pages/emailVerification';
import UserProfileForm from './pages/userProfile';
import OfflineForm from './pages/Recording';
import OnlineForm from './pages/Calendar';
import Account from './pages/Account';
import Pricing from './sections/Pricing/Pricing';
import CheckOut from './sections/Pricing/CheckOut';
import Password from './sections/Accounts/Password';
import AnalyticsTemp from './sections/Accounts/AnalyticsTemp';
import ZoomScheduler from './sections/auth/Online/ZoomScheduler'
import Users from './sections/Accounts/Users';
import Camerainsights from './sections/Cctvinsights/Camerainsights';
import FootfalldetailsView from './sections/Cctvinsights/FootfalldetailsView';
import { ProtectedRoute } from './protectedRoute';


export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element:<ProtectedRoute><DashboardLayout /></ProtectedRoute>,
      children: [
        { path: 'app', element: <ProtectedRoute><DashboardApp /></ProtectedRoute> },
        { path: 'Error', element: <Error /> },
        // { path: 'user', element: <ProtectedRoute><User /> </ProtectedRoute> },
        { path: 'forgotPassword', element: <ForgotPasswordForm /> },
        { path: 'registerEmailVerification', element: <RegisterEmailVerificationForm/> },
        { path: 'resetPassword', element: <ResetPasswordForm /> },
        { path: 'PaymentSucess', element: <PaymentSucess /> },
        { path: 'PaymentFailed', element: <PaymentFailed /> },
        { path: 'Analytics', element:<ProtectedRoute> <Analytics /> </ProtectedRoute>},
        { path: 'CameraKart', element:<ProtectedRoute> <Camerakart /> </ProtectedRoute>},
       
        { path: 'AkEmoeats', element:<ProtectedRoute> <AKEmoeats /> </ProtectedRoute>},
        { path: 'Camerainsights', element:<ProtectedRoute> <Camerainsights /> </ProtectedRoute>},
        { path: 'FootfalldetailsView', element:<ProtectedRoute> <FootfalldetailsView /> </ProtectedRoute>},
        { path: 'Integration', element: <ProtectedRoute><Integration /> </ProtectedRoute>},
        { path: 'emailVerification/:username/:formName', element: <EmailVerificationForm /> },
        { path: 'userprofile', element: <ProtectedRoute><UserProfileForm /> </ProtectedRoute>},
        { path: 'Account', element:<ProtectedRoute> <Account /></ProtectedRoute> },
        { path: 'General', element:<ProtectedRoute> <Account /></ProtectedRoute> },
        { path: 'Online', element:<ProtectedRoute> <OnlineForm /> </ProtectedRoute>},
        { path: 'offline', element: <ProtectedRoute><OfflineForm /></ProtectedRoute> },
        { path: 'Pricing', element: <ProtectedRoute><Pricing /></ProtectedRoute>},
        { path: 'ContactSupport', element: <ProtectedRoute><ContactSupport /> </ProtectedRoute>},
        { path: 'CheckOut', element: <ProtectedRoute><CheckOut /></ProtectedRoute> },
        { path: 'Password', element:<ProtectedRoute> <Password /> </ProtectedRoute>},
        { path: 'AnalyticsTemp', element:<ProtectedRoute> <AnalyticsTemp /> </ProtectedRoute>},
        { path: 'Users', element:<ProtectedRoute> <Users /> </ProtectedRoute> },
        { path: 'ZoomScheduler', element:<ProtectedRoute> <ZoomScheduler /> </ProtectedRoute> },
        { path: 'Camerainsights', element:<ProtectedRoute> <Camerainsights /> </ProtectedRoute> },
       
      ],
    },
    
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Login /> },
        { path: 'login', element: <Login /> },
        { path: 'CompanyRegister', element: <CompanyRegister /> },
        { path: 'IndividualRegister', element: <IndividualRegister /> },
        { path: 'ContactSupport', element: <ContactSupport /> },        
        { path: 'Error', element: <Error /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
        { path: 'forgotPassword', element: <ForgotPasswordForm/> },
        { path: 'registerEmailVerification', element: <RegisterEmailVerificationForm/> },
        { path: 'resetPassword', element: <ResetPasswordForm/> },
        { path: 'emailVerification/:username/:formName', element: <EmailVerificationForm/> },
        { path: 'PaymentSucess', element: <PaymentSucess /> },
        { path: 'PaymentFailed', element: <PaymentFailed /> },
        { path: 'Online', element: <Navigate to="/dashboard/online " /> },
        { path: 'General', element: <Navigate to="/dashboard/account " /> },
        { path: 'userprofile', element: <Navigate to="/dashboard/userprofile" /> },
        { path: 'offline', element: <Navigate to="/dashboard/offline" /> },
        { path: 'Pricing', element: <Navigate to="/dashboard/pricing" /> }, 
        { path: 'CheckOut', element: <Navigate to="/dashboard/checkout"/> },
        { path: 'Password', element: <Navigate to="/dashboard/password" /> }, 
        { path: 'AnalyticsTemp', element: <Navigate to="/dashboard/analyticstemp" /> }, 
        { path: 'Users', element: <Navigate to="/dashboard/users" /> },
        { path: 'CameraKart', element: <Navigate to="/dashboard/CameraKart" /> },
        { path: 'FootfalldetailsView', element:<Navigate to="/dashboard/FootfalldetailsView" /> },
        {path:'Camerainsights' , element: <Navigate to="/dashboard/Camerainsights" /> }

       
        ],
    },    
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
