import * as React from 'react';
import { Box, Button,Grid, Typography, Slider } from "@mui/material";
import { styled } from '@mui/material/styles';
import MuiInput from '@mui/material/Input';
import Snackbar from '@mui/material/Snackbar';
import {getAnalyticsSummary} from '../../Services/analyticsTemp';
import {useEffect} from "react";
import { setAnalyticsSummary } from '../../Services/analyticsTemp';

export default function Analytics() {

  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const [snackbarTitle] = React.useState("");
    const [engagementScoreThreshold, setEngagementScoreThreshold] = React.useState("");
    const [sentimentScoreThreshold, setSentimentScoreThreshold] = React.useState("");
    const [participationScoreThreshold, setParticipationScoreThreshold] = React.useState("");

    const handleAnalyticsReport = () => {
      getAnalyticsSummary().then(res => {
                console.log("usagesummary",res.data)
                if (res.status === 200){
                  setEngagementScoreThreshold(res.data.engagementScoreThreshold)
                  setSentimentScoreThreshold(res.data.sentimentScoreThreshold)
                  setParticipationScoreThreshold(res.data.participantScoreThreshold)
                      }
                else {
                }
                })
      }
      useEffect(() => {      
        handleAnalyticsReport()     
        }, [])
     
        const handleSetAnalyticsReport = () => {
          const analyticsSummary= {engagementScoreThreshold:engagementScoreThreshold,sentimentScoreThreshold:sentimentScoreThreshold,
            participantScoreThreshold:participationScoreThreshold}
          setAnalyticsSummary(analyticsSummary).then(res => {
                    console.log("setusagesummary",res.data)
                    if (res.status === 200){
                          }
                    else {
                      setValue(res.data);
                    } 
                    })
          }
          useEffect(() => {      
            handleSetAnalyticsReport()     
            }, [])
        
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
        return;
        }
        setSnackBarOpen(false);
        };

  const [state] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    color:'#E2FBD8',
  });
  const { vertical, horizontal } = state;

  const Input = styled(MuiInput)`
         width: 42px; `;


         const [value, setValue] = React.useState()
        
          const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setValue(event.target.value === '' ? '' : Number(event.target.value));
          };
                      
          const handleBlur = () => {
            if (value < 0) {
              setValue(0);
            } else if (value > 100) {
              setValue(100);
            }
          };

         
          
    return (
      
        <Box>
        <Typography id="input-slider" gutterBottom>
        Engagement score Threshold for Alert
      </Typography>
      
      <Grid container spacing={2} alignItems="center">
        
        <Grid item xs>
          <Slider  aria-label="input-slider" valueLabelDisplay="auto"  onChange={(e,val) =>setEngagementScoreThreshold(val) }/>
        </Grid>
        <Grid item>
          <Input
            value={engagementScoreThreshold}
            size="small"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 10,
              min: 0,
              max: 100,
              type: 'number',
              'aria-label': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
      <Typography id="input-slider" gutterBottom>
        Sentiment score Threshold for Alert
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
         <Slider  aria-label="input-slider" valueLabelDisplay="auto" onChange={(e,val) =>setSentimentScoreThreshold(val) } />
         </Grid>
        <Grid item>
          <Input
            value={sentimentScoreThreshold}                               
            size="small"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 10,
              min: 0,
              max: 100,
              type: 'number',
              'aria-label': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
      <Typography id="input-slider" gutterBottom>
        Participation score Threshold for Alert
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
         <Slider  aria-label="input-slider" valueLabelDisplay="auto" onChange={(e,val) =>setParticipationScoreThreshold(val) }/>
         </Grid>
        <Grid item>
          <Input
            value={participationScoreThreshold}
            size="small"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 10,
              min: 0,
              max: 100,
              type: 'number',
              'aria-label': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
      <div class="buttonSubmitNotificationDiv">
        <Button variant="contained" color="success" className="buttonSubmitNotification" onClick={handleSetAnalyticsReport}>
          Save Changes
        </Button>
        <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={6000}
        open={snackBarOpen}
        close={handleClose}
        snackbarTitle={snackbarTitle}
        />
      </div>
    </Box>
  );
}