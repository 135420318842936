import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import {Divider} from "@mui/material";
import {createmeeting,getzoomtimezone,startmeetinganalytics } from "../../../Services/zoomintegration";


const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));



export default function Zoom(props) {
  const [open, setOpen] = React.useState(true);
  
  const handleClose = () => {
    setOpen(false);
    // window. location. reload(false);
    window.location.replace('/dashboard/online');
  };

  const [setTitle]=React.useState();
  
  return (
    <RootStyle>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        justifyContent="center"
        alignItems="center"
       >
        <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
        <Stack style={{alignItems:"center"}}>
        <img src="/static/icons/Zoom48.png"alt="Zoom"/>
        <h2>Add Zoom Meeting</h2>
        
        </Stack>
        
        <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
        <Divider variant="middle"/>
        <DialogContent style={{alignItems:"center" ,width:"400px"}} >
        <div>
           <TextField id="outlined-basic" label="Meeting Id" variant="outlined"  onChange={(newValue) => setTitle(newValue)} style={{width: "300px"}}/>
           </div>
           <Stack direction="row" spacing={20} alignItems="center" justifyContent="space-between" sx={{ my: 2 }}></Stack>
           <div>
           <TextField minRows={5} id="outlined-basic" label="Password" variant="outlined" style={{width: "300px"}}/>
           </div>
           <Stack direction="row" spacing={20} alignItems="center" justifyContent="space-between" sx={{ my: 2 }}></Stack>
           
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button  onClick={handleClose}autoFocus>
            Add
          </Button>
        </DialogActions>
      </BootstrapDialog>
      </RootStyle>
  );
}

