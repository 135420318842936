import { Button, Card, CardContent, Container, FormControl, Grid, Paper, styled, Switch, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Snackbar from '@mui/material/Snackbar';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function Notification() {

    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const [snackbarTitle] = React.useState("");


    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
        return;
        }
        setSnackBarOpen(false);
        };

  const [state] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    color:'#E2FBD8',
  });
  const { vertical, horizontal } = state;
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Container maxWidth="xl">
                <Card>
                    <CardContent >
                        <FormControl className="formControll">
                            <Typography sx={{ mb: 5 }}>
                                ACTIVITY
                            </Typography>
                            <Grid container spacing={2} >
                                <Grid item xs={12}>
                                    <Item className="item">
                                        <div className="notificationClass">
                                            <Switch className="typography"></Switch>
                                            <Typography sx={{ mb: 5 }}>
                                                Email me when Analytics is completed
                                                </Typography>
                                        </div>                                       
                                    </Item>
                                </Grid>
                            </Grid>
                            <Typography sx={{ mb: 5 }}>
                                APPLICATION
                            </Typography>
                            <Grid container spacing={2} >
                                <Grid item xs={12}>
                                    <Item className="item">
                                        <div className="notificationClass">
                                            <Switch className="typography"></Switch>
                                            <Typography sx={{ mb: 5 }}>
                                                News and announcements
                                            </Typography>
                                        </div>

                                        <div className="notificationClass">
                                            <Switch className="typography"></Switch>
                                            <Typography sx={{ mb: 5 }} >
                                                Weekly product updates
                                            </Typography>
                                        </div>

                                        <div className="notificationClass">
                                            <Switch className="typography"></Switch>
                                            <Typography sx={{ mb: 5 }}>
                                                Weekly blog digest
                                            </Typography>
                                        </div>

                                    </Item>
                                </Grid>
                                <div class="buttonSubmitNotificationDiv">
                                    <Button variant="contained" color="success" className="buttonSubmitNotification">
                                        Save Changes
                                    </Button>
                                    <Snackbar
                               anchorOrigin={{ vertical, horizontal }}
                               autoHideDuration={6000}
                               open={snackBarOpen}
                               close={handleClose}
                               snackbarTitle={snackbarTitle}
                              />
                                </div>
                            </Grid>
                        </FormControl>
                    </CardContent>
                </Card>
            </Container>
        </Box>
    );
}