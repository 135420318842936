import React from 'react';
import { Typography,Grid,Avatar} from '@mui/material';
import {AppAnalyticsSummary} from '../../sections/@dashboard/app';

export default function Usersummary(props) {
	let {userScoresData} = props;
	return (
		<div>
			 <Typography variant="h4" sx={{ mb: 5 }}>
			 Andrea Blended Session
        </Typography>

			<Grid container spacing={3}>
				{Object.keys(userScoresData).map(function(key) {
					if(key != "emotion_summary" && key != "head_turn_around_summary" && key != "eye_gaze_summary") {
						return (
							<Grid item xs={6} sm={6} md={4} lg={3}>
								<AppAnalyticsSummary title={userScoresData[key].title} total={userScoresData[key].value} color='error' icon={'ci:happy'} />
							</Grid>
						)
					}
				})}
			</Grid>
			<br></br>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<Typography variant="h4" sx={{ mt: 3 }} style={{ paddingBottom: "0.5em" }}>
						Emotion Summary
					</Typography>
				</Grid>
				{userScoresData['emotion_summary'].map(function(value) {
					return (
						<Grid item xs={6} sm={6} md={4} lg={3}>
							<AppAnalyticsSummary title={value.title} total={value.total} icon={value.icon} />
						</Grid>
					)
				})}
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<Typography variant="h4" sx={{ mt: 3 }} style={{ paddingBottom: "0.5em" }}>
						Head turn around Summary
					</Typography>
				</Grid>
				{userScoresData['head_turn_around_summary'].map(function(value) {
					return (
						<Grid item xs={6} sm={6} md={4} lg={3}>
							<AppAnalyticsSummary title={value.title} total={value.total} color={value.color} icon={value.icon} />
						</Grid>
					)
				})}
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<Typography variant="h4" sx={{ mt: 3 }} style={{ paddingBottom: "0.5em" }}>
						Eye Gaze Summary
					</Typography>
				</Grid>
				{userScoresData['eye_gaze_summary'].map(function(value) {
					return (
						<Grid item xs={6} sm={6} md={4} lg={3}>
							<AppAnalyticsSummary title={value.title} total={value.total} color={value.color} icon={value.icon} />
						</Grid>
					)
				})}
			</Grid>
          
		</div>

	);
} 