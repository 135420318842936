import React from "react";
import { useRef, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {helpers} from '../helper/index';
const settings = require('../settings');
const apiEndpoint = settings.onlinesessionApiEndPoint;


export {getonlinesession};


function getonlinesession(){
        const OrganizationId = JSON.parse(localStorage.getItem("userInfo")).organizationId;
        const UserId = JSON.parse(localStorage.getItem("userInfo")).userId;
        return axios.post(`${apiEndpoint}/geteventdetails?organizationId=${OrganizationId}&userId=${UserId}`)
            .then(res => res)
            .catch(error => error.response)
                     
    }







