import axios from 'axios';

export const helpers = {
    setAuth
};

function setAuth() {
    const token = localStorage.getItem("id_token")
    if (token) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
}