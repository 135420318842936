import * as Yup from 'yup';
import { useFormik, FormikProvider } from 'formik';
import TextField from "@mui/material/TextField";
import { LoadingButton } from '@mui/lab';
import { Stack} from '@mui/material';
import {useNavigate, useParams } from 'react-router-dom';
import { verifyCodeWithID } from "../../../Services/verifyCode"
import SnackBar from '../../../components/Snackbar/Snackbar';
import React,{ useState, useEffect } from 'react';

export default function OTPFields() {

const navigate = useNavigate();

const { username,formName }= useParams();

const [snackBarOpen, setSnackBarOpen] = React.useState(false);
const [snackbarTitle, setSnackbarTitle] = React.useState("");
const [loading, setLoading] = React.useState(false);
var [codeValue, setCodeValue ] = useState('');

useEffect(() => {
  if (loading) {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }
}, [loading]);

  const handleClose = (event, reason) => {
          if (reason === "clickaway") {
              return;
          }
          setSnackBarOpen(false);
          }  

const EmailVerificationSchema = Yup.object().shape({})

const formik = useFormik({
  initialValues: {
     code: '',
   },

validationSchema: EmailVerificationSchema,
    onSubmit: () => {
      console.log(values);
    },
  });

  const { errors, touched,values} = formik;

  
  const handlesendreqt = async (username, codeValue) => {
    setLoading(true);
      verifyCodeWithID(username, codeValue).then(res => {
          if(res){
              if (res.status === 200) {
                setTimeout(() => {
              if(formName === "ForgotPassword")
                navigate('/resetpassword')
              else if(formName ==="RegisterEmialVerification")
                navigate('/login')
            }, 200);
              }
              else if (res.status === 400) {
                setSnackbarTitle(res.data.detail)
                setSnackBarOpen(true)
              }
              else if (res.status === 404) {
                setSnackbarTitle(res.data.detail)
                setSnackBarOpen(true)  
              }
          }
          else{
              //response error 
          }
      }
    )
    }
    
   return (
     <>
      <FormikProvider value={formik}>
        <Stack >
          <TextField fullWidth
          autoComplete='text'
          type='number'
          label= "Enter 6 digit otp received on your email id"
          onInput = {(e) =>{e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,6) }}
          {...formik.getFieldProps('code')}
            error={Boolean(touched.code && errors.code)}
            helperText={touched.code && errors.code}
            value={codeValue}
            onChange={e => setCodeValue(e.target.value)}

         />
        </Stack>
        <br/>
        <LoadingButton fullWidth size="large" type="submit" variant="contained"
          loading={loading} loadingPosition="middle" onClick={() => handlesendreqt(username,codeValue)}>
          OK
        </LoadingButton>
          <SnackBar
                    open={snackBarOpen}
                    close={handleClose}
                    snackbarTitle={snackbarTitle}
                  />
    </FormikProvider>  

    
 </> 
  );
 }


