import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Stack from '@mui/material/Stack';
import {Divider} from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {Tooltip} from "@mui/material";
import TeamsAdd from "../Online/TeamsAdd";
import ZoomAdd from "../Online/ZoomAdd";

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ScheduleMeeting(props) {
  const [open, setOpen] = React.useState(true);
  
  const handleClose = () => {
    setOpen(false);
    window.location.replace('/dashboard/online');
  };
  
const [open1, setOpen1] = React.useState(false);
const [dialogStatetwo,setDialogstatetwo]=React.useState(false);
const [dialogStatethree,setDialogstatethree]=React.useState(false);

const handlesessiontwo = () => {
  setDialogstatetwo(true)
}
const handlesessionthree = () => {
  setDialogstatethree(true)
}


  const handleClick = () => {
    setOpen1(true);
  };

  const handleClose1 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen1(false);
  };

  const [state] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    color:'#E2FBD8',
  });
  const { vertical, horizontal } = state;
  
  
  return (
    <RootStyle>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        justifyContent="center"
        alignItems="center"
       >
        <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
        <Stack style={{alignItems:"center"}}>
        <h3> Add Meeting Link </h3>
        </Stack>
        <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
        <Divider variant="middle"/>
        <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
        
        <DialogContent style={{alignItems:"center"}}>
            <div>
           <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">Select Meeting App</FormLabel>
      <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
      <RadioGroup aria-labelledby="demo-radio-buttons-group-label"defaultValue="female"name="radio-buttons-group" style={{ paddingLeft: '20px' }}>
        <FormControlLabel value="Teams" control={<Radio />} onClick={handlesessiontwo} label={
          <Tooltip id="button-report" placement="right-start" title="Teams">
          <img src="/static/icons/Teams48.png"alt="Teams" style={{ paddingLeft: '20px' }}/>
          </Tooltip>
        } />
         { dialogStatetwo &&  (
           <TeamsAdd/>
           )}
        <br/>
        <FormControlLabel value="Zoom" control={<Radio />} onClick={handlesessionthree}label={
          <Tooltip id="button-report" placement="right-start" title="Zoom">
            
          <img src="/static/icons/Zoom48.png"alt="Zoom" style={{ paddingLeft: '20px' }}/>
          </Tooltip>
        } />
         {dialogStatethree &&  (
           <ZoomAdd/>
           )}
       
      </RadioGroup>
    </FormControl>
           </div>
    
    
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} onClick1={handleClick } onClose={handleClose1}>
            Ok
          </Button>
          <Snackbar open={open1} autoHideDuration={6000} onClose={handleClose1} anchorOrigin={{ vertical, horizontal }}sx={{ color: 'SUCCESS.lighter' }}>
          <Alert onClose={handleClose1} severity="success" sx={{ width: '100%' }}>
          Changes saved successfully..
          </Alert>
          </Snackbar>
          <Button  onClick={handleClose}autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
      </RootStyle>
  );
}

