import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Toolbar,
  Typography,
  Box,
  TextField
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { format } from 'date-fns';

const FootfallDetailsView = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);


 

  const fetchFootfallData = async (date, page, limit) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`https://abzf31ptt8.execute-api.ap-south-1.amazonaws.com/STAGE/footfalldetails`, {
        params: {
          cameraId :localStorage.getItem("cameraId"),
          date: date.format('YYYY-MM-DD HH:mm:ss'), // Ensure your API can handle this format
          skip: page * limit,
          limit: limit
        }
      });
      setRows(response.data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
      setRows([]);
    }
  };

  useEffect(() => {
    fetchFootfallData(selectedDate, page, rowsPerPage);
  }, [selectedDate, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page with new row limit
  };

  const isDateDisabled = (date) => {
    return date.isAfter(dayjs());
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ width: '100%' }}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Store Scene Analytics
          </Typography>
        </Toolbar>

        <Box sx={{ display: 'flex', justifyContent: 'right', p: 2 }}>
          <DatePicker
            label="Select Date"
            value={selectedDate}
            onChange={(newDate) => {
              setSelectedDate(newDate);
              setPage(0); // Reset to first page
            }}
            renderInput={(params) => <TextField {...params} />}
            shouldDisableDate={isDateDisabled}
          />
        </Box>

        {loading ? (
          <Typography>Loading...</Typography>
        ) : error ? (
          <Typography color="error">Error: {error}</Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Time</TableCell>
                
                  <TableCell>People Identified</TableCell>
                  <TableCell>Detailed Insights </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length > 0 ? (
                  rows.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.dateTime}</TableCell>
      
                      <TableCell>{row.footFall}</TableCell>
                      <TableCell>{row.insights}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align="center">No data available for the selected date</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {rows.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length} // This should come from the server if server-side pagination
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default FootfallDetailsView;
