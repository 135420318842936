import { Container, Button, Card, CardContent, Grid, Typography, SvgIcon, CardActions, Box, TextField } from "@mui/material";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import HistoryIcon from '@mui/icons-material/History';
import PaymentIcon from '@mui/icons-material/Payment';
import React, { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import RenderRazorpay from './RenderRazorpay';

const FontAwesomeSvgIcon = React.forwardRef((props, ref) => {
  const { icon } = props;
  const {
    icon: [width, height, , , svgPathData],
  } = icon;

  return (
    <SvgIcon ref={ref} viewBox={`0 0 ${width} ${height}`}>
      {typeof svgPathData === 'string' ? (
        <path d={svgPathData} />
      ) : (
        svgPathData.map((d, i) => (
          <path style={{ opacity: i === 0 ? 0.4 : 1 }} d={d} />
        ))
      )}
    </SvgIcon>
  );
});

FontAwesomeSvgIcon.propTypes = {
  icon: PropTypes.any.isRequired,
};

export default function Billing() {
  const [displayRazorpay, setDisplayRazorpay] = useState(false);
  const [orderDetails, setOrderDetails] = useState({
    orderId: null,
    currency: null,
    amount: null,
  });
  const [numClicks, setNumClicks] = useState('');
  const [price, setPrice] = useState(0);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const userId = userInfo.organizationId;
  const credits = userInfo.credits;
  const organizationType = userInfo.customerType;

  const handleCreateOrder = async (amount, currency) => {
    try {
      const response = await axios.post('https://akvisionpaymentservices-er4v7pzexq-el.a.run.app/order', {
        amount: amount * 100, // convert amount into the lowest unit, e.g., Dollar -> Cents
        currency,
        keyId: "rzp_live_EmdhsNtB5ZLJUa",
        keySecret: 'f4y9N1QOxXtFKnntMwxmFbqo',
        user_id: userId
      });

      if (response.data && response.data.order_id) {
        setOrderDetails({
          orderId: response.data.order_id,
          currency: response.data.currency,
          amount: response.data.amount,
        });
        setDisplayRazorpay(true);
      }
    } catch (error) {
      console.error('Error creating order:', error);
    }
  };

  const handleNumClicksChange = (event) => {
    const clicks = parseInt(event.target.value, 10);
    setNumClicks(clicks);

    let newPrice = 0;
    if (clicks >= 1000) {
      newPrice = clicks * 5 * 0.9; // 10% OFF
    } else if (clicks >= 500) {
      newPrice = clicks * 5 * 0.95; // 5% OFF
    } else {
      newPrice = clicks * 5;
    }
    setPrice(newPrice);
  };

  const [numCameras, setNumCameras] = useState('');
  const [numMonths, setNumMonths] = useState('');
  const [cameraPrice, setCameraPrice] = useState(0);

  const handleNumCamerasChange = (event) => {
    setNumCameras(event.target.value);
    updateCameraPrice(event.target.value, numMonths);
  };

  const handleNumMonthsChange = (event) => {
    setNumMonths(event.target.value);
    updateCameraPrice(numCameras, event.target.value);
  };

  const updateCameraPrice = (cameras, months) => {
    const totalPrice = cameras * months * 1510.59 ; // $18 per camera per month
    setCameraPrice(totalPrice);
  };

  return (
    <Container>
      <Grid container spacing={3} justifyContent="center" style={{ marginTop: '20px' }}>
        {organizationType === "cafe/Restaurant" ? (
          <>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Card style={{ height: '100%' }}>
                <CardContent>
                  <Box display="flex" alignItems="center">
                    <CreditCardIcon fontSize="large" />
                    <Typography variant="h5" component="div" style={{ marginLeft: '10px' }}>
                      Credits Available
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    You have {credits} clicks available.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        label="Number of Clicks"
                        type="number"
                        value={numClicks}
                        onChange={handleNumClicksChange}
                        InputProps={{ inputProps: { min: 1} }}
                        fullWidth
                        placeholder="Enter number of clicks"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        Price: ₹{price}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={() => handleCreateOrder(price, 'INR')}
                        disabled={numClicks < 1 || isNaN(numClicks)}
                      >
                        Recharge
                      </Button>
                    </Grid>
                    {displayRazorpay && (
                      <RenderRazorpay
                        amount={orderDetails.amount}
                        currency={orderDetails.currency}
                        orderId={orderDetails.orderId}
                        keyId={"rzp_live_EmdhsNtB5ZLJUa"}
                        keySecret={"'f4y9N1QOxXtFKnntMwxmFbqo'"}
                        userId={userId}
                      />
                    )}
                  </Grid>
                </CardActions>
              </Card>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Card style={{ height: '100%' }}>
                <CardContent>
                  <Box display="flex" alignItems="center">
                    <MonetizationOnIcon fontSize="large" /> {/* New icon */}
                    <Typography variant="h5" component="div" style={{ marginLeft: '10px' }}>
                      Charges
                    </Typography>
                  </Box>
                  <Box mt={2}>
                    <Typography variant="body2" style={{ color: '#45862d' }}>
                      Unit Price: ₹5 per click
                    </Typography>
                    <Typography variant="body2" style={{ color: '#40CEF9' }}>
                      100 clicks (Minimum Recharge)
                    </Typography>
                    <Typography variant="body2" style={{ color: '#9a7413' }}>
                      500 clicks (5% OFF)
                    </Typography>
                    <Typography variant="body2" style={{ color: '#FF826F' }}>
                      1000 clicks and Above (10% OFF)
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Card style={{ height: '100%' }}>
                <CardContent>
                  <Box display="flex" alignItems="center">
                    <HistoryIcon fontSize="large" />
                    <Typography variant="h5" component="div" style={{ marginLeft: '10px' }}>
                      Transaction History
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    View your past transactions.
                  </Typography>
                </CardContent>
                <CardActions>
                  
                </CardActions>
              </Card>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Card style={{ height: '100%' }}>
                <CardContent>
                  <Box display="flex" alignItems="center">
                    <PaymentIcon fontSize="large" />
                    <Typography variant="h5" component="div" style={{ marginLeft: '10px' }}>
                      Subscription
                    </Typography>
                  </Box>
                  <Grid container spacing={2} style={{ marginTop: '10px' }}>
                    <Grid item xs={12}>
                      <TextField
                        label="Number of Cameras"
                        type="number"
                        value={numCameras}
                        onChange={handleNumCamerasChange}
                        InputProps={{ inputProps: { min: 1 } }}
                        fullWidth
                        placeholder="Enter number of cameras"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Number of Months"
                        type="number"
                        value={numMonths}
                        onChange={handleNumMonthsChange}
                        InputProps={{ inputProps: { min: 1 } }}
                        fullWidth
                        placeholder="Enter number of months"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        Total Price:  ₹{cameraPrice}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={() => handleCreateOrder(cameraPrice, 'INR')}
                        disabled={numCameras < 1 || numMonths < 1 || isNaN(numCameras) || isNaN(numMonths)}
                      >
                        Recharge
                      </Button>
                    </Grid>
                    {displayRazorpay && (
                      <RenderRazorpay
                        amount={orderDetails.amount}
                        currency={orderDetails.currency}
                        orderId={orderDetails.orderId}
                        keyId={"rzp_live_EmdhsNtB5ZLJUa"}
                        keySecret={"'f4y9N1QOxXtFKnntMwxmFbqo'"}
                        userId={userId}
                      />
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Card style={{ height: '100%' }}>
                <CardContent>
                  <Box display="flex" alignItems="center">
                    <MonetizationOnIcon fontSize="large" /> {/* New icon */}
                    <Typography variant="h5" component="div" style={{ marginLeft: '10px' }}>
                      Charges
                    </Typography>
                  </Box>
                  <Box mt={2}>
                    <Typography variant="body2" style={{ color: '#45862d' }}>
                      Unit Price: $18 per camera per month
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Card style={{ height: '100%' }}>
                <CardContent>
                  <Box display="flex" alignItems="center">
                    <HistoryIcon fontSize="large" />
                    <Typography variant="h5" component="div" style={{ marginLeft: '10px' }}>
                      Transaction History
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    View your past transactions.
                  </Typography>
                </CardContent>
                <CardActions>
                  
                </CardActions>
              </Card>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
}
