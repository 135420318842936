import axios from 'axios';
const settings = require('../settings');
const apiEndpoint = settings.cameraservicesendpoint;

export {listcamera,getmomentinsights} ;


function listcamera() {
    const OrganizationId = JSON.parse(localStorage.getItem("userInfo")).organizationId;
    const requestOptions = {

        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`${apiEndpoint}/cameralist?organizationId=${OrganizationId}`,
        requestOptions)
        .then(res => res)
        .catch(error => error.response)
}

function getmomentinsights(rtspurl) {
    
    const requestOptions = {

        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`http://172.174.243.140:8080/insightatmoment?rtspUrl=${rtspurl}`,
        requestOptions)
        .then(res => res)
        .catch(error => error.response)
}
