const ApiEndPoint = 'https://usermanagmentservices-er4v7pzexq-el.a.run.app/';
const offlinesessionApiEndPoint ='https://dywyxw9eeh.execute-api.ap-south-1.amazonaws.com/STAGE/'
const zoomintegrationApiEndpoint='https://mgf7hxdajl.execute-api.ap-south-1.amazonaws.com/STAGE/'
const MicrosoftintegrationApiEndpoint='https://u7m4oy1hn4.execute-api.ap-south-1.amazonaws.com/STAGE/'
const PaymentgatewayApiEndpoint='https://iyav4qa3f5.execute-api.ap-south-1.amazonaws.com/STAGE/'
const onlinesessionApiEndPoint='https://7mw11ho0gh.execute-api.ap-south-1.amazonaws.com/STAGE'
const cameraservicesendpoint='https://abzf31ptt8.execute-api.ap-south-1.amazonaws.com/STAGE/'
 
module.exports = {
    ApiEndPoint,offlinesessionApiEndPoint,zoomintegrationApiEndpoint,MicrosoftintegrationApiEndpoint,PaymentgatewayApiEndpoint,onlinesessionApiEndPoint,cameraservicesendpoint
};



