import React, { useState } from 'react';
import { Box} from '@mui/material';
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import styled from "@emotion/styled";

const RootStyle = styled('div')(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
	  display: 'flex',
	},
  }));


export default function Useranalyticschart(props) {

	var [headPoseAnalysis] = useState("");
	var [emotionAnalysis] = useState("");
	var [eyeGazeAnalysis] = useState("");

		const emotionanalysis = {
		title: {
			text: 'Emotion Analysis'
		},
		series: [{
			type: 'area',
			name: 'Happy',
			data: [0.9, 0.1, 0.8, 0.7, 0.5]
		},
		{
			type: 'area',
			name: 'Sad',
			data: [0.3, 0.1, 0.0, 0.2, 0.1]
		},
		{
			type: 'area',
			name: 'Angry',
			data: [0.3, 0.1, 0.0, 0.2, 0.1]
		},
		{
			type: 'area',
			name: 'Sad',
			data: [0.0, 0.2, 0.1, 0.0, 0.0]
		},
		{
			type: 'area',
			name: 'Surprised',
			data: [0.0, 0.0, 0.0, 0.3, 0.1]
		},
		{
			type: 'area',
			name: 'Disgust',
			data: [0.0, 0.2, 0.2, 0.0, 0.0]
		},
		{
			type: 'area',
			name: 'Neutral',
			data: [0.1, 0.0, 0.9, 0.0, 0.4]
		},
		],
		credits: {
			enabled: false
		},
		scrollbar: {
			enabled: true
		},

	};

	const headposeanalysis = {
		title: {
			text: 'head pose Analysis'
		},

		series: [{
			type: 'area',
			name: 'Straight',
			data: [0.4, 0.1, 0.8, 0.7, 0.5]
		},
		{
			type: 'area',
			name: 'Right',
			data: [0.7, 0.1, 0.0, 0.2, 0.1]
		},
		{
			type: 'area',
			name: 'Left',
			data: [0.9, 0.1, 0.0, 0.2, 0.1]
		},
		{
			type: 'area',
			name: 'Up',
			data: [0.0, 0.2, 0.1, 0.0, 0.0]
		},
		{
			type: 'area',
			name: 'Down',
			data: [0.6, 0.3, 0.0, 0.3, 0.1]
		}
		],
		credits: {
			enabled: false
		},
		scrollbar: {
			enabled: true
		},

	};

	const eyegazeanalysis = {
		title: {
			text: 'Eye gaze Analysis'
		},
		chart:
		{
			type: "area"
		},

		series: [{

			name: "Looking Screen",
			data: [0, 0, 0, 1, 1, 1, 1]
		},
		{
			name: "Not Looking Screen",
			data: [1, 1, 1, 0, 0, 0, 0]
		},

		],
		credits: {
			enabled: false
		},
		scrollbar: {
			enabled: true
		},

	};
	
  //re-render object based on the value change in the state
  React.useEffect(() => {
	console.log("======== min and max values", props.StartPoint, props.EndPoint)
	});
  
	return (
		<RootStyle>
			<Box>
			
			<div style={{ height: "400px", marginTop: '15px', width:"1400px"}}>
				<HighchartsReact containerProps={{ style: { height: "100%"} }}
				 highcharts={Highcharts}
				 options={emotionanalysis} 
				 value= {emotionAnalysis}
				 />
				 <br/>
				<HighchartsReact containerProps={{ style: { height: "100%" } }}
				highcharts={Highcharts} 
				options={headposeanalysis}  
				value={headPoseAnalysis}
				/>
				<br/>
				<HighchartsReact containerProps={{ style: { height: "100%" } }}
				highcharts={Highcharts} 
				options={eyegazeanalysis} 
				value={eyeGazeAnalysis}
				/>
		    </div>
	      </Box>
		</RootStyle>
	);
} 

