import Iconify from '../../components/Iconify';

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfigCafe = [
  {
    title: 'App',
    path: '/dashboard/app',
    icon: getIcon('fluent:app-folder-24-filled'),
  },
  {
    title: 'Ak Emoeats',
    path: '/dashboard/AkEmoeats',
    icon: getIcon('ep:food'),
  },
];

const navConfigOther = [
  {
    title: 'App',
    path: '/dashboard/app',
    icon: getIcon('fluent:app-folder-24-filled'),
  },
  {
    title: 'CameraKart',
    path: '/dashboard/CameraKart',
    icon: getIcon('heroicons-outline:camera'),
  },
];

const userManagement = [
  {
    title: 'Account',
    path: '/dashboard/account',
    icon: getIcon('clarity:analytics-line'),
  },
];

const session = [
  {
    title: 'Recordings',
    path: '/dashboard/offline',
    icon: getIcon('heroicons-outline:status-offline'),
  },
];

export const getNavConfig = (organizationType) => {
  if (organizationType === 'cafe/Restaurant') {
    return { navConfig: navConfigCafe, userManagement, session };
  }
  return { navConfig: navConfigOther, userManagement, session };
};
