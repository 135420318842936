import React from 'react';
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
// material
import { Box } from '@mui/material';

export default function Detailanalytics(props) {

	const emotionoptions = {
		title: {
			text: 'Emotion Analysis'
		},
		tooltip: {
			shared: true,
			formatter: function () {
				var result = '', points = this.points, pointsLength = points.length;
				var dataindex;
				var count, participants;

				for (dataindex = 0; dataindex < pointsLength; dataindex += 1) {
					count = (points[dataindex].y);
					participants = (points[dataindex].series.data[points[dataindex].key].options.speakers);
					console.log(points[dataindex].key)
					result += ' <b>count:' + count + ' </b><br/>' + ' <b>participants:' + participants + ' </b><br/>';
				}
				return result
			}
		},
		series: [{
			name: 'Happy',
			data: [{
				y: 2,
				x:1,
				speakers: "Andrea,jharna"
			},
			{
				y: 3,
				x:2,
				speakers: "Andrea,jharna"
			},
			{
				y: 2,
				x:3,
				speakers: "jharna"
			},
			{
				y: 4,
				x:4,
				speakers: "Andrea,jharna"
			}
			]
		},
		{
			name: 'Sad',
			data: [{
				y: 1,
				speakers: "Jharna"
			},
			{
				y: 2,
				speakers: "Jharna"
			},
			{
				y: 3,
				speakers: "Andrea,Jharna"
			},
			{
				y: 4,
				speakers: "Andrea,Jharna"
			}
			]
		},
		{
			name: 'Angry',
			data: [{
				y: 2,
				speakers: ""
			},
			{
				y: 4,
				speakers: ""
			},
			{
				y: 6,
				speakers: ""
			},
			{
				y: 8,
				speakers: ""
			}
			]
		},
		{
			name: 'Neutral',
			data: [{
				y: 1,
				speakers: "Andrea"
			},
			{
				y: 3,
				speakers: "Andrea,Jharna"
			},
			{
				y: 7,
				speakers: "Jharna"
			},
			{
				y: 5,
				speakers: "Andrea,Jharna"
			}
			]
		}],
		credits: {
			enabled: false
		},
		scrollbar: {
			enabled: false
		},

	};

	const headposeoptions = {
		title: {
			text: 'head pose Analysis'
		},
		tooltip: {
			shared: true,
			formatter: function () {
				var result = '', points = this.points, pointsLength = points.length;
				var dataindex;
				var count, participants;

				for (dataindex = 0; dataindex < pointsLength; dataindex += 1) {
					count = (points[dataindex].y);
					participants = (points[dataindex].series.data[points[dataindex].key].options.speakers);
					console.log(points[dataindex].key)
					result += ' <b>count:' + count + ' </b><br/>' + ' <b>participants:' + participants + ' </b><br/>';
				}
				return result
			}
		},
		series: [{
			name: 'Straight',
			data: [{
				y: 2,
				speakers: "Andrea,Jharna"
			},
			{
				y: 3,
				speakers: "Andrea,Jharna"
			},
			{
				y: 2,
				speakers: "Jharna"
			},
			{
				y: 4,
				speakers: "Andrea,Jharna"
			}
			]
		},
		{
			name: 'Left',
			data: [{
				y: 1,
				speakers: "Andrea"
			},
			{
				y: 2,
				speakers: "Andrea"
			},
			{
				y: 3,
				speakers: "Andrea"
			},
			{
				y: 4,
				speakers: "Andrea"
			}
			]
		},
		{
			name: 'Right',
			data: [{
				y: 2,
				speakers: "Andrea"
			},
			{
				y: 4,
				speakers: "Andrea"
			},
			{
				y: 6,
				speakers: "Andrea"
			},
			{
				y: 8,
				speakers: "Andrea"
			}
			]
		},
		{
			name: 'Up',
			data: [{
				y: 1,
				speakers: ""
			},
			{
				y: 3,
				speakers: "Jharna"
			},
			{
				y: 7,
				speakers: "Jharna"
			},
			{
				y: 5,
				speakers: "Jharna"
			}
			]
		},
		{
			name: 'Down',
			data: [{
				y: 3,
				speakers: "Andrea"
			},
			{
				y: 5,
				speakers: "Andrea"
			},
			{
				y: 9,
				speakers: "Andrea"
			},
			{
				y: 10,
				speakers: "Andrea"
			}
			]
		}],
		credits: {
			enabled: false
		},
		scrollbar: {
			enabled: false
		},

	};

	const eyegazeoptions = {
		title: {
			text: 'Eye gaze Analysis'
		},
		tooltip: {
			shared: true,
			formatter: function () {
				var result = '', points = this.points, pointsLength = points.length;
				var dataindex;
				var count, participants;

				for (dataindex = 0; dataindex < pointsLength; dataindex += 1) {
					count = (points[dataindex].y);
					participants = (points[dataindex].series.data[points[dataindex].key].options.speakers);
					console.log(points[dataindex].key)
					result += ' <b>count:' + count + ' </b><br/>' + ' <b>participants:' + participants + ' </b><br/>';
				}
				return result
			}
		},
		series: [{
			name: 'Looking Screen',
			data: [{
				y: 2,
				speakers: "Andrea,Jharna"
			},
			{
				y: 3,
				speakers: "Andrea,Jharna"
			},
			{
				y: 2,
				speakers: "Andrea,Jharna"
			},
			{
				y: 4,
				speakers: "Andrea,Jharna"
			}
			]
		},
		{
			name: 'Not Looking Screen',
			data: [{
				y: 1,
				speakers: "Andrea"
			},
			{
				y: 2,
				speakers: "Andrea"
			},
			{
				y: 3,
				speakers: "Andrea"
			},
			{
				y: 4,
				speakers: "Andrea"
			}
			]
		},
		],
		credits: {
			enabled: false
		},
		scrollbar: {
			enabled: false
		},

	};
  //re-render object based on the value change in the state
  React.useEffect(() => {
	console.log("======== min and max values", props.StartPoint, props.EndPoint)
	});
  
	return (
		<Box>
			<div style={{ height: "400px", marginTop: '15px'}}>
					<HighchartsReact 
					containerProps={{ style: { height: "100%" } }} 
					highcharts={Highcharts} 
					options={emotionoptions} />
				<br/>
					<HighchartsReact 
					containerProps={{ style: { height: "100%" } }} 
					highcharts={Highcharts} 
					options={headposeoptions} />
				<br/>
					<HighchartsReact 
					containerProps={{ style: { height: "100%" } }} 
					highcharts={Highcharts} 
					options={eyegazeoptions} />
	    		</div>
		</Box>
	);
} 
