import React, { useState, useEffect,useRef } from 'react';
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from '@mui/material/TextField';
import DialogContentText from "@mui/material/DialogContentText";
import Stack from '@mui/material/Stack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';
import {Divider} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useFormik, FormikProvider,useField } from 'formik';
import * as Yup from 'yup';
import { values } from 'lodash';

import { createmeeting,getazuretimezone} from "../../../Services/microsoftintegration";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));



export default function Teams(props) {

 
  
  const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  }));
  
  const [open, setOpen] = React.useState(true);

  const theme = useTheme();
  const [timezones, setTimezones] = React.useState([]);
  const [startdate, setStartdate] =React.useState(props.date);
  const [enddate, setEnddate] =React.useState(props.date);
  const [selectedtimezone,setSelectedtimezone]=React.useState("");
  const [duration,setDuration]=React.useState("");
  const [emails, setEmails] = useState([]);
  const [showcreateresponse,setShowcreateresponse]=React.useState(false);
  const [responsemessage,setResponsemessage]=React.useState("");
 




  const MeetingSchema = Yup.object().shape({
    title: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!').required('Meeting title is required'),
    description: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!'),
    duration: Yup.number().positive('Duration should be a positive number').required('Duration is required'),
    timeZone: Yup.string().required('Company size is required')
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      duration: '',
      timeZone: '',
    },
    
    validationSchema: MeetingSchema,
  });

  const { errors, touched, getFieldProps } = formik;
  var [title] = useState('');
  var [description] = useState('');
  var [timeZone, setTimeZone] = useState('');


  const timezoneref = useRef("");
  const titleref = useRef("");
  const descriptionref = useRef("");
  const durationref = useRef("");
  const emaillistRef= useRef("");


  useEffect(() => {
    handletimezone()
  }, []);

  const handleClose = () => {
    setOpen(false);
    window.location.replace('/dashboard/online');
  };  

  const handletimezone = () =>
  {
    getazuretimezone().then(res=>
      {
        if(res){
          console.log("timezone",res.data.timezones)
          console.log("timezone====",res.data.timezone)
          if (res.status === 200) {
            setTimezones(res.data) 
           }
           else if (res.status==401){
            var message=res.data.detail+","+"please go to integration page and click on teams"
            var message=message.split(",")
            setTimezones(message) 
           }
}
      })
    }

    const handlesetTitle =(event) => {
      //setTitle(event.target.value)
      console.log(title)

    }
    const handlesetDescription =(event) => {
      //setDescription(event.target.value)
      console.log(description)

    }
 
    const handletimezoneselect = (event) => {
      const {
        target: { value },
      } = event;
      setSelectedtimezone(
        typeof value === 'string' ? value.split(',') : value,
      );
    };

   

    const handleEmailChange = (event, values) => {
      setEmails(values);
    };
  

    function checkDateDifference(startDate, endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
    
      if (end > start) {
        console.log("End date is greater than start date.");
      } else if (end.getTime() === start.getTime()) {
        console.log("End date is equal to start date.");
      } else {
        console.log("End date is not greater than start date.");
      }
    }
    
    const handleDialogClose = () => {
      setShowcreateresponse(false)
    };
  
    const handlecreate =() => {
      const meetingdetail={
       "topic": formik.values.title,
       "start_time": startdate,
       "duration":formik.values.duration,
       "timezone": selectedtimezone,
       "agenda": formik.values.description,
       "attendees": emails.toString()
     }
   console.log("meeting details",meetingdetail)
   
   createmeeting(meetingdetail).then(res=>
     {
       if(res){
           console.log("meeting response",res)
         if (res.status === 200) {
           setShowcreateresponse(true)
           setResponsemessage(res.data.detail)
             }
         else if (res.status === 404) {
           setShowcreateresponse(true)
           setResponsemessage(res.data.detail)  
         }
         else {
           setShowcreateresponse(true)
           setResponsemessage(res.data.detail)  
         }
         
          }
     })
 }
 
  
    const handlestartdate= (value) =>
    {
       
     
      let datavalue=value["$d"].toString()
      datavalue=datavalue.split(" ");
      datavalue=datavalue.slice(1,5)
      datavalue=datavalue.join(" ")
      console.log("start date",datavalue)
      setStartdate(datavalue)
    }
  
    const handleenddate= (value) =>
    {
       
    
      let datavalue=value["$d"].toString()
      datavalue=datavalue.split(" ");
      datavalue=datavalue.slice(1,5)
      datavalue=datavalue.join(" ")
      console.log("end date",datavalue)
      setEnddate(datavalue)
    }

  
 
  return (
    <>
    <FormikProvider value={formik}>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        justifyContent="center"
        alignItems="center"
       >
   
        <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
        <Stack style={{alignItems:"center"}}>

        <img src="/static/icons/Teams48.png"alt="Teams"/>
        <h2>Teams Meeting Scheduler</h2>
        </Stack>
        
        <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
        <Divider variant="middle"/>
        <DialogContent style={{alignItems:"center" ,width:"350px"}} >
        <div>
          <TextField fullWidth 
          autoComplete='title'
          label="Title"
          {...formik.getFieldProps('title')}
          error={Boolean(touched.title && errors.title)}
          helperText={touched.title && errors.title}
           />
        </div>
        <Stack direction="row" spacing={20} alignItems="center" justifyContent="space-between" sx={{ my: 2 }}></Stack>
        <div>
          <TextField fullWidth
           autoComplete='title'
           label="Description" 
           {...formik.getFieldProps('description')}
           error={Boolean(touched.description && errors.description)}
           helperText={touched.description && errors.description} />
        </div>
        <Stack direction="row" spacing={20} alignItems="center" justifyContent="space-between" sx={{ my: 2 }}></Stack>
        
        <LocalizationProvider
             dateAdapter={AdapterDayjs}
            >
              
     <Stack direction="row" spacing={3} >
        <MobileDateTimePicker
          label="StartDate"
          value={startdate}
          onChange={(startnewValue) => handlestartdate(startnewValue)}
          renderInput={(params) => <TextField {...params} />}
        
        />
      </Stack>
    </LocalizationProvider> 
    <Stack direction="row" spacing={20} alignItems="center" justifyContent="space-between" sx={{ my: 2 }}></Stack>
    <LocalizationProvider
             dateAdapter={AdapterDayjs}
            >
              
     <Stack direction="row" spacing={3} >
        <MobileDateTimePicker
          label="EndDate"
          value={enddate}
          onChange={(enddatevalue) => handleenddate(enddatevalue)}
          renderInput={(params) => <TextField {...params} />}
        
        />
      </Stack>
    </LocalizationProvider> 
    <Stack direction="row" spacing={20} alignItems="center" justifyContent="space-between" sx={{ my: 2 }}></Stack>
      <FormControl sx={{ width: '260px' }}>
        <InputLabel id="demo-multiple-name-label">TimeZone</InputLabel>
        <Select
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        // value={selectedtimezone}
        label = "Time Zone"
        {...formik.getFieldProps('selectedtimezone')}
        onClick={()=>handletimezone()}
        {...formik.getFieldProps('timeZone')}
         onChange={handletimezoneselect}
        input={<OutlinedInput label="timezone list" inputRef={timezoneref} />}
      >
        {timezones.map((timezone) => (
          <MenuItem key={timezone} value={timezone}>
            {timezone}
          </MenuItem>
        ))}
      </Select>
      </FormControl>
      <Stack direction="row" spacing={20} alignItems="center" justifyContent="space-between" sx={{ my: 2 }}></Stack>
    <FormikProvider value={formik}> 

  <Stack direction="row" spacing={20} alignItems="center" justifyContent="space-between" sx={{ my: 2 }}></Stack>
           </FormikProvider>    
     <Autocomplete
        style={{width: "260px"}}
        multiple
        freeSolo
        id="tags-outlined"
        options={[]}
        defaultValue={[]}
        value={emails}
        onChange={handleEmailChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Email list"
            placeholder="Email"
          />
        )}
      />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button  onClick={handlecreate}autoFocus>
            Add
          </Button>
        </DialogActions>
      </BootstrapDialog>
      </FormikProvider>
 
  { showcreateresponse && (
      <Dialog
      style={{opacity:0.8}}
      open={showcreateresponse}
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {responsemessage}
        </DialogContentText>
        <DialogActions>
        <Button onClick={handleDialogClose} color="primary">
          Close
        </Button>
      </DialogActions>
      </DialogContent>
      </Dialog>
  
  )}</>
  );
}