import axios from 'axios';
import {helpers} from '../helper/index';

const settings = require('../settings');
const apiEndpoint = settings.ApiEndPoint;


export {getUserList}; 


function getUserList(){
    helpers.setAuth();
    const organizationId=JSON.parse(localStorage.getItem("userInfo")).organizationId;
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`${apiEndpoint}/getuserlistbyorgId?organizationId=${organizationId}`, requestOptions)
        .then(res => res)
        .catch(error => error.response)       
}


