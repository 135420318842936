import * as Yup from 'yup';
import React, { useState, useEffect} from 'react';
import { useFormik, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Stack, TextField, MenuItem,IconButton, InputAdornment} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';

import Select from "@mui/material/Select";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import useResponsive from '../../../hooks/useResponsive';
import AuthSocial from '../../../sections/auth/AuthSocial';
import styled from "@emotion/styled";
import { CompanyUser } from '../../../Services/companyRegister';
import SnackBar from "../../../components/Snackbar/Snackbar";
import { useFormContext } from 'react-hook-form';
import { values } from 'lodash';


export default function RegisterForm() {

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().min(5, 'Too Short!').max(50, 'Too Long!').required('First name is required'),
    lastName: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('Last name is required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    address: Yup.string().min(5, 'Too Short!').max(200, 'Too Long!').required('Address is required'),
    city: Yup.string().min(5, 'Too Short!').max(200, 'Too Long!').required('City is required'),
    state: Yup.string().min(5, 'Too Short!').max(200, 'Too Long!').required('State is required'),
    country: Yup.string().min(5, 'Too Short!').max(200, 'Too Long!').required('country is required'),
    zipcode: Yup.string().min(5, 'Check Number').max(10, 'Check Number').required('Zip Code is required'),
    phone_number: Yup.string().min(10, 'Check Number').max(12, 'Check Number').required('Phone number is required'),
    office_phone_number: Yup.string().min(10, 'Check Number').max(12, 'Check Number').required('Office Phone number is required'),
    password: Yup.string().min(5, 'Too Short!').max(11, 'Too Long!').required('Password is required'),
    purpose: Yup.string().min(10, 'Too Short!').max(200, 'Too Long!').required('Purpose is required'),
    organizationName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Company name is required'),
    organizationSize: Yup.string().required('Company size is required'),
    websiteUrl: Yup.string().required('Website url is required'),
    role: Yup.string().required('Role is required'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      phone_number: '',
      office_phone_number: '',
      purpose: '',
      address: '',
      city: '',
      state: '',
      country: '',
      zipcode: '',
      organizationName:'',
      organizationSize:'',
      websiteUrl:'',
      role:'',
    },
    
    validationSchema: RegisterSchema,
    onSubmit: () => {
      console.log(values);
     },
  });

  const { errors, touched,getFieldProps } = formik;
  var [purpose] = useState('');
  var [passwordValue] = useState('');
  var [userContact] = useState(
    {
      phone_number: "",
      office_phone_number: "",
    }
  );
  var [organizationName, setCompanyName] = useState('');
  var [organizationSize, setCompanySize] = useState('');
  var [websiteUrl, setWebsiteUrl] = useState('');
  var [role, setRole] = useState('');
  var [emailValue, setEmailValue] = useState('');
  var [lastName, setLastName] = useState('');
  var [firstName, setFirstName] = useState('');
  var [userAddress, setUserAddress] = useState(
    {
      address: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",
    });

  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = React.useState("");

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    }
  }, [loading]);
  
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  }

  const handleLog = {}
  const handleregister = async (purpose,role, userAddress, userContact, passwordValue, emailValue, lastName, firstName, organizationName,organizationSize,websiteUrl) => {
    setLoading(true);
    CompanyUser(formik).then(res => {
      if (res) {
        if (res.status === 201) {
          setSnackbarTitle(" Valid Credentials")
          setSnackBarOpen(true)
          navigate('/dashboard/app')
         }
         else if (res.status === 400) {
          setSnackbarTitle("Invalid Credentials")
          setSnackBarOpen(true)
        }
         else if (res.status === 401 || res.status == 409 || res.status === 422) {
           setSnackbarTitle(res.data.detail)
           setSnackBarOpen(true)  
         }
      }
      else {
        // response error
      }
    }
    )
  }

  return (
    <>
        <FormikProvider value={formik}>
   
                <Stack spacing={3} >
                  <TextField 
                  fullWidth
                  autoComplete='firstName'
                  type='text'
                    label="First name"
                    {...formik.getFieldProps('firstName')}
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                  />

                  <TextField 
                  fullWidth
                  autoComplete='lastName'
                  type='text'
                    label="Last name"
                    {...formik.getFieldProps('lastName')}
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                  />

                  <TextField 
                  fullWidth
                    autoComplete="username"
                    type="email"
                    label="Email "
                    {...formik.getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                    <TextField 
                    fullWidth
                    autoComplete="organizationName"
                    type="text"
                    label="Company Name"
                    {...formik.getFieldProps('organizationName')}
                    error={Boolean(touched.organizationName && errors.organizationName)}
                    helperText={touched.organizationName && errors.organizationName}
                  />
        
                 <FormControl sx={{ m: 1, minWidth: 200}}>
                  <InputLabel htmlFor="Company Size">Company Size</InputLabel>
                   <Select defaultValue="None"  label="Company Size" {...formik.getFieldProps('organizationSize')}> 
                   <MenuItem value={"1-9"}>1-9</MenuItem>
                   <MenuItem value={"10-49"}>10-49</MenuItem>        
                   <MenuItem value={"50-249"}>50-249</MenuItem>
                   <MenuItem value={"250 or more"}>250 or more</MenuItem>        
                 </Select>
                </FormControl>
                 
                 <TextField 
                    fullWidth
                    autoComplete="websiteUrl"
                    type="text"
                    label="Website Url"
                    {...formik.getFieldProps('websiteUrl')}
                    error={Boolean(touched.websiteUrl && errors.websiteUrl)}
                    helperText={touched.websiteUrl && errors.websiteUrl}
                  />
                <FormControl sx={{ m: 1, minWidth: 200}}>
                 <InputLabel htmlFor="Choose Role">Choose Role</InputLabel>
                  <Select defaultValue="None"  label="Choose Role" {...formik.getFieldProps('role')}> 
                   <MenuItem value={"SUPERADMIN"}>SUPERADMIN</MenuItem>
                   <MenuItem value={"ADMIN"}>ADMIN</MenuItem>        
                   <MenuItem value={"CTO/CIO"}>CTO/CIO</MenuItem>   
                  <MenuItem value={"PRODUCT_MANAGER"}>PRODUCT_MANAGER</MenuItem>
                  <MenuItem value={"SOFTWARE_PROFESSIONAL"}>SOFTWARE_PROFESSIONAL</MenuItem>        
                  <MenuItem value={"MARKETING_SALES"}>MARKETING_SALES</MenuItem>  
                   <MenuItem value={"OTHERS"}>OTHERS</MenuItem>  
            </Select>
           </FormControl>

                  <TextField
                  fullWidth 
                    label="Address"
                    {...formik.getFieldProps('address')}
                    error={Boolean(touched.address && errors.address)}
                    helperText={touched.address && errors.address}
                  />
                  <TextField
                  fullWidth 
                    label="City"
                    {...formik.getFieldProps('city')}
                    error={Boolean(touched.city && errors.city)}
                    helperText={touched.city && errors.city}
                  />
                  <TextField
                  fullWidth 
                    label="State"
                    {...formik.getFieldProps('state')}
                    error={Boolean(touched.state && errors.state)}
                    helperText={touched.state && errors.state}
                  />
                  <TextField
                  fullWidth 
                    label="Country"
                    {...formik.getFieldProps('country')}
                    error={Boolean(touched.country && errors.country)}
                    helperText={touched.country && errors.country}
                  />
                  <TextField
                  fullWidth 
                    autoComplete="Zip Code"
                    type="number"
                    label=" Zip Code "
                    {...formik.getFieldProps('zipcode')}
                    error={Boolean(touched.zipcode && errors.zipcode)}
                    helperText={touched.zipcode && errors.zipcode}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 12)
                    }}
                  />

                  <TextField
                  fullWidth 
                    autoComplete="Phone Number"
                    type="number"
                    label=" Phone Number "
                    {...formik.getFieldProps('phone_number')}
                    error={Boolean(touched.phone_number && errors.phone_number)}
                    helperText={touched.phone_number && errors.phone_number}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 12)
                    }}
                  />
                  <TextField
                  fullWidth 
                    autoComplete="Phone Number"
                    type="number"
                    label=" Office Phone Number "
                    {...formik.getFieldProps('office_phone_number')}
                    error={Boolean(touched.office_phone_number && errors.office_phone_number)}
                    helperText={touched.office_phone_number && errors.office_phone_number}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 12)
                    }}
                  />

                  <TextField
                  fullWidth 
                    autoComplete="Password"
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    {...formik.getFieldProps('password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                              onInput={(e) => {
                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 8)
                              }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                  <TextField
                  fullWidth 
                    label="Purpose"
                    {...getFieldProps('purpose')}
                    error={Boolean(touched.purpose && errors.purpose)}
                    helperText={touched.purpose && errors.purpose}
                  />
                  <LoadingButton  size="large" type="submit" variant="contained"
                     loading={loading} loadingPosition="middle"  onClick={() => handleregister(purpose, userAddress, userContact,
                      passwordValue, emailValue, lastName, firstName,organizationName,organizationSize,websiteUrl,role)}>
                    Register
                  </LoadingButton>

                  <SnackBar
                    open={snackBarOpen}
                    close={handleClose}
                    snackbarTitle={snackbarTitle}
                  />
                </Stack>
        </FormikProvider>
    </>
  );
}



