import React, { useState } from 'react';
import { faker } from '@faker-js/faker';
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, CardMedia, Card, CardContent, MenuItem, Select, FormControl, InputLabel, IconButton } from '@mui/material';
import Page from '../components/Page';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import TrendingUpIcon from '@mui/icons-material/TrendingUp'; // Import an icon

// Registering the required components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function DashboardApp() {
  const theme = useTheme();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const organizationType = userInfo.customerType;

  const cafeOffers = [
    // ... (same data as before)
  ];

  const otherOffers = [
    // ... (same data as before)
  ];

  const videoSrc = organizationType === "cafe/Restaurant"
    ? "https://storage.googleapis.com/emoeatsmenuimages/IMG_0317.MP4"
    : "https://storage.googleapis.com/emoeatsmenuimages/processed_video%20(1).mp4";

  const offerings = organizationType === "cafe/Restaurant" ? cafeOffers : otherOffers;

  const avgFootfall = 12;
  const avgTimeSpent = "45 minutes";

  const peakTimeData = {
    labels: ["10 AM", "12 PM", "2 PM", "4 PM", "6 PM", "8 PM"],
    datasets: [
      {
        label: 'Footfall',
        data: [1, 5, 6, 3, 6, 6],
        fill: false,
        backgroundColor: 'rgba(75,192,192,1)',
        borderColor: 'rgba(75,192,192,1)',
      },
    ],
  };

  const [selectedMonth, setSelectedMonth] = useState('August');

  const augustData = {
    avgFootfall: '50',
    avgTimeSpent: '02h 30m',
    peakTimeData: {
      labels: ["10 AM", "12 PM", "2 PM", "4 PM", "6 PM", "8 PM"],
      datasets: [
        {
          label: 'Footfall',
          data: [1, 5, 6, 3, 6, 6],
          fill: false,
          backgroundColor: 'rgba(75,192,192,1)',
          borderColor: 'rgba(75,192,192,1)',
        },
      ],
    }
  };

  const septemberData = {
    avgFootfall: '14',
    avgTimeSpent: '01h 15m',
    peakTimeData: {
      labels: ["10 AM", "12 PM", "2 PM", "4 PM", "6 PM", "8 PM"],
      datasets: [
        {
          label: 'Footfall',
          data: [2, 6, 7, 4, 8, 7],
          fill: false,
          backgroundColor: 'rgba(75,192,192,1)',
          borderColor: 'rgba(75,192,192,1)',
        },
      ],
    }
  };


  const noData = {
    avgFootfall: 'No data found',
    avgTimeSpent: 'No data found',
    peakTimeData: {
      labels: [],
      datasets: [
        {
          label: 'Footfall',
          data: [],
          fill: false,
          backgroundColor: 'rgba(75,192,192,1)',
          borderColor: 'rgba(75,192,192,1)',
        },
      ],
    }
  };

  const data = selectedMonth === 'August' ? augustData : selectedMonth === 'Sept' ? septemberData : noData;

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={10}>
            <Card sx={{ bgcolor: "#FFFFFF", marginRight: 5 }}>
              <CardMedia
                component="video"
                autoPlay
                controls
                height='400'
                src={videoSrc}
                alt='coming soon'
              />
            </Card>
          </Grid>

          {/* Directly Display Avg Footfall, Avg Time Spent, and Peak Time Graph */}
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Grid container alignItems="center" justifyContent="space-between">
                  <Typography variant="h6" gutterBottom>
                    Avg Footfall
                  </Typography>
                  <TrendingUpIcon color="primary" /> {/* Icon on the right */}
                </Grid>
                <Typography variant="h4">
                  {avgFootfall} Cars/day
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Grid container alignItems="center" justifyContent="space-between">
                  <Typography variant="h6" gutterBottom>
                    Avg Time Spent
                  </Typography>
                  <TrendingUpIcon color="primary" /> {/* Icon on the right */}
                </Grid>
                <Typography variant="h4">
                  {avgTimeSpent}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Grid container alignItems="center" justifyContent="space-between">
                  <Typography variant="h6" gutterBottom>
                    Avg Peak Time Graph
                  </Typography>
                  <TrendingUpIcon color="primary" /> {/* Icon on the right */}
                </Grid>
                <Line data={peakTimeData} />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            {/* Month Filter */}
            <FormControl>
             
              <Select
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
              >
                <MenuItem value="Jan">Jan</MenuItem>
                <MenuItem value="Feb">Feb</MenuItem>
                <MenuItem value="Mar">Mar</MenuItem>
                <MenuItem value="April">April</MenuItem>
                <MenuItem value="May">May</MenuItem>
                <MenuItem value="June">June</MenuItem>
                <MenuItem value="July">July</MenuItem>
                <MenuItem value="August">August</MenuItem>
                <MenuItem value="Sept">Sept</MenuItem>
                <MenuItem value="Oct">Oct</MenuItem>
                <MenuItem value="Nov">Nov</MenuItem>
                <MenuItem value="Dec">Dec</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid container spacing={3} sx={{ mt: 2 }}>
            {/* Monthly Avg Footfall */}
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Grid container alignItems="center" justifyContent="space-between">
                    <Typography variant="h6" gutterBottom>
                      Monthly  Footfall
                    </Typography>
                    <TrendingUpIcon color="primary" /> {/* Icon on the right */}
                  </Grid>
                  <Typography variant="h4">
                    {data.avgFootfall}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Monthly Avg Time Spent */}
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Grid container alignItems="center" justifyContent="space-between">
                    <Typography variant="h6" gutterBottom>
                      Monthly Avg Time Spent
                    </Typography>
                    <TrendingUpIcon color="primary" /> {/* Icon on the right */}
                  </Grid>
                  <Typography variant="h4">
                    {data.avgTimeSpent}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Monthly Peak Time Graph */}
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Grid container alignItems="center" justifyContent="space-between">
                    <Typography variant="h6" gutterBottom>
                      Monthly Peak Time Graph
                    </Typography>
                    <TrendingUpIcon color="primary" /> {/* Icon on the right */}
                  </Grid>
                  {data.peakTimeData.labels.length > 0 ? (
                    <Line data={data.peakTimeData} />
                  ) : (
                    <Typography variant="h6">No data found</Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Offerings Grid */}
          <Grid container spacing={3} sx={{ mt: 3 }}>
            {offerings.map((offer) => (
              <Grid item xs={12} sm={6} md={4} key={offer.id}>
                <Card>
                  <CardContent>
                    <Grid container alignItems="center" justifyContent="space-between">
                      <Typography variant="h6" gutterBottom>
                        {offer.title}
                      </Typography>
                      <TrendingUpIcon color="primary" /> {/* Icon on the right */}
                    </Grid>
                    {offer.image && (
                      <CardMedia
                        component="img"
                        height="200"
                        image={offer.image}
                        alt={offer.title}
                      />
                    )}
                    <Typography variant="body2" color="textSecondary">
                      {offer.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
