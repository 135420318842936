import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import Page from '../components/Page';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}));

const styles = {

    largeIcon: {
      width: 100,
      height: 100,
    },
  
  };

export default function PaymentSucess() {
  return (
    <Page title="Payment Sucessful">
      <Container>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
        
          <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 24 24"><path fill="#0e6d0e" d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h5v-2H4v-6h18V6c0-1.11-.89-2-2-2zm0 4H4V6h16v2zm-5.07 11.17l-2.83-2.83l-1.41 1.41L14.93 22L22 14.93l-1.41-1.41l-5.66 5.65z"/></svg>
          
          <Typography variant="h2" paragraph>
            Thank you!
          </Typography>
        
          <Typography sx={{ color: 'text.secondary' }} variant="h5" paragraph>
            Payment done sucessfully
          </Typography>

          <Typography sx={{ color: 'text.secondary' }} variant="h6" paragraph>
            Click here to redirect to home page
          </Typography>

          <Button to="/" size="large" variant="contained" component={RouterLink}>
            Go to Home
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
