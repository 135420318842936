import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Toolbar,
  Typography,
  Box,
  TextField,
  Chip,
  Snackbar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

// Sound file (Make sure you have the file in the correct path)
import notificationSound from './mixkit-access-allowed-tone-2869.wav';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Orderdetailview = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const audioRef = useRef(new Audio(notificationSound));

  function formatDateToDDMMYYYY(date) {
    const day = String(date.date()).padStart(2, '0');
    const month = String(date.month() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.year();

    return `${day}/${month}/${year}`;
  }

  const fetchFootfallData = async (date, page, limit) => {
    setLoading(true);
    setError(null);
    try {
      const formattedDate = formatDateToDDMMYYYY(date);
      const organizationId = JSON.parse(localStorage.getItem("userInfo")).organizationId;
      console.log('Fetching data for date:', formattedDate);
      console.log('Page:', page, 'Limit:', limit);
      const response = await axios.get(`https://akemoeatsservice-er4v7pzexq-el.a.run.app/gettransaction`, {
        params: {
          restauranId: organizationId,
          date: formattedDate,
          skip: page * limit,
          limit: limit
        }
      });
      console.log('API Response:', response.data);
      setRows(response.data);
      setLoading(false);
    } catch (err) {
      console.error('API Error:', err.message);
      setError(err.message);
      setLoading(false);
      setRows([]);
    }
  };

  useEffect(() => {
    fetchFootfallData(selectedDate, page, rowsPerPage);

    const ws = new WebSocket('https://akemoeatsservice-er4v7pzexq-el.a.run.app/ws');
    console.log("ws",ws)
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setMessage(`Order placed successfully with ID: ${data.suggestion_id}`);
      setOpen(true);
      audioRef.current.play();
    };

    
  }, [selectedDate, page, rowsPerPage]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleViewDetailsForDate = (date) => {
    setSelectedDate(date);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const isDateDisabled = (date) => {
    // Disable future dates
    return date.isAfter(dayjs());
  };

  const formatSuggestedMenu = (suggestedMenu) => {
    return suggestedMenu.map(item => `${item.menu} - ${item.price} - Qty: ${item.quantity}`).join(', ');
  };

  const getStatusChip = (status) => {
    let color;
    if (status === 'order placed') {
      color = 'success';
    } else {
      color = 'warning';
    }
    return <Chip label={status} color={color} />;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ width: '100%' }}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Emoeats Transaction Details
          </Typography>
        </Toolbar>

        <Box sx={{ display: 'flex', justifyContent: 'right', p: 2 }}>
          <DatePicker
            label="Select Date"
            value={selectedDate}
            onChange={(newDate) => {
              handleViewDetailsForDate(newDate);
            }}
            renderInput={(params) => <TextField {...params} />}
            shouldDisableDate={isDateDisabled}
          />
        </Box>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Table Number</TableCell>
                <TableCell>Order Id</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Selfie Captured</TableCell>
                <TableCell>Emotion/Mood</TableCell>
                <TableCell>order details</TableCell>
              
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.tableNo}</TableCell>
                    <TableCell>{row.suggestionId}</TableCell>
                    <TableCell>{row.datetime}</TableCell>
                    <TableCell><img src={row.selfie} alt="Footfall" style={{ width: '50px' }} /></TableCell>
                    <TableCell>{row.emotion}</TableCell>
                    <TableCell>{formatSuggestedMenu(row.suggestedMenu)}</TableCell>
                   
                    <TableCell>{getStatusChip(row.status)}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">No data available for the selected date</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {rows.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Box>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {message}
        </Alert>
      </Snackbar>
    </LocalizationProvider>
  );
};

export default Orderdetailview;
