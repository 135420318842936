import axios from 'axios';
import { helpers } from '../helper/index';

const settings = require('../settings');
const apiEndpoint = settings.ApiEndPoint;


export { updateUser, userDetails, getProfilePicture,  setProfilePicturedetail ,getPictureUploadParams };

function buildReqBody(formik) {
    return {
        firstName: formik.values.firstName,
        loginEmail: formik.values.email,
        about: formik.values.about,
        address: formik.values.address,
        city: formik.values.city,
        state: formik.values.state,
        country: formik.values.country,
        zipcode: formik.values.zipcode,
        MobileNumber: formik.values.phone_number,
    }
}

function updateUser(formik) {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.put(`${apiEndpoint}/updateuserdetailsbyemail`,
        JSON.stringify(buildReqBody(formik)), requestOptions)
        .then(res => res)
        .catch(error => error.response)
}



function userDetails() {
    
    const userId = JSON.parse(localStorage.getItem("userInfo")).userId;
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`${apiEndpoint}/getuserdetailsbyId?userId=${userId}`, requestOptions)
        .then(res => res)
        .catch(error => error.response)
}



function getPictureUploadParams(e) {
   
    return axios.get(`${apiEndpoint}/getpictureuploadparams`)
        .then(res => res.data)
        .catch(error => error.response)
}


function setProfilePicturedetail(filename) {
    const email = JSON.parse(localStorage.getItem("userInfo")).email;

    return axios.post(`${apiEndpoint}/setprofilepicture?useremail=${email}&filename=${filename}`)
        .then(res => res)
        .catch(error => error.response)
}



function getProfilePicture() {
    helpers.setAuth();
    const email = JSON.parse(localStorage.getItem("userInfo")).email;
    return axios.get(`${apiEndpoint}/getprofilepicture?useremail=${email}`)
        .then(res => res)
        .catch(error => error.response)
}
