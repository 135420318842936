import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Rating from '@mui/material/Rating';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Divider, Typography } from "@mui/material";
import * as Yup from 'yup';
import { useFormik } from 'formik';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

export default function NewSessionForm(props) {
  const [open, setOpen] = React.useState(true);
  const [setTitle]=React.useState();

  
  const handleClose = () => {
    setOpen(false);
    window. location. reload(false);
  };
  
  
const RegisterSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Name is required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    
});

const formik = useFormik({
    initialValues: {
      Name: '',
      email: '',
      textarea:'',
    },
    validationSchema: RegisterSchema,
  });
const { errors, touched, getFieldProps } = formik;
  return (
    <RootStyle>
    <BootstrapDialog
      onClose={handleClose}
      open={open}
      aria-labelledby="customized-dialog-title"
      justifyContent="center"
      alignItems="center"
     >
      <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
      <Stack style={{alignItems:"center"}}>
      <h3> <strong>Have FeedBack? </strong> </h3>
      </Stack>
      <Divider/>
      <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
      <Typography align="center">Need help? Have feedback? I'm a human so please be</Typography>
      <Typography  align="center">nice and I'll fix it!</Typography>
      <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
      
      <DialogContent style={{alignItems:"center"}}>
      <div>
         <TextField
              sx={{width: 400}}
              label="Name"
              {...getFieldProps('Name')}
              error={Boolean(touched.Name && errors.Name)}
              helperText={touched.Name && errors.Name}
            />
         </div>
         <Stack direction="row" spacing={20} alignItems="center" justifyContent="space-between" sx={{ my: 2 }}></Stack>
         <div>
         <TextField
         onChange={(newValue) => setTitle(newValue)}
            sx={{width: 400}}
            autoComplete="username"
            type="email"
            label="Email "
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
         </div>
          <Stack direction="row" spacing={20} alignItems="center" justifyContent="space-between" sx={{ my: 2 }}></Stack>
          <div>
          <TextareaAutosize aria-label="minimum height" minRows={8} placeholder="Enter your feedback here." style={{ width: "100%" }}/>
          </div>
          <Stack direction="row" spacing={20} alignItems="center" justifyContent="space-between" sx={{ my: 2 }}></Stack>
          <Stack direction="row" spacing={1}>
          <div> 
          <Rating name="size-large" defaultValue={5} size="large" />
          </div>
          </Stack>   
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} >
          Close
        </Button>
        <Button  onClick={handleClose} autoFocus>
          Submit
        </Button>
      </DialogActions>
    </BootstrapDialog>
    </RootStyle>
  );
}

