import axios from 'axios';
import {helpers} from '../helper/index';

const settings = require('../settings');
const apiEndpoint = settings.ApiEndPoint;


export {checkeligibilty,executeanalytics};


function checkeligibilty(participantnum,duration){
    helpers.setAuth();
    const clientid=localStorage.getItem("client_id");
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`${apiEndpoint}/runeligibilty?clientId=${clientid}&partcipantnum=${participantnum}&duration=${duration}`, 
     requestOptions)
        .then(res => res)
        .catch(error => error.response)
        
        
}


function executeanalytics(sessionName,participant){
    helpers.setAuth();
    const clientid=localStorage.getItem("client_id");
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`${apiEndpoint}/executeanalytics?sessioName=${sessionName}&clientId=${clientid}&participantCount=${participant}`, 
     requestOptions)
        .then(res => res)
        .catch(error => error.response)     
}
