import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
function SnackBar(props) {
return (
<Snackbar
anchorOrigin={{
vertical: 'bottom',
horizontal: 'center',
}}
open={props.open}
autoHideDuration={4000}
onClose={props.close}
message={props.snackbarTitle}
action={
<React.Fragment>
<IconButton size="small" aria-label="close" color="inherit" onClick={props.close}>
<CloseIcon fontSize="small" />
</IconButton>
</React.Fragment>
}
/>
);
}

export default SnackBar;