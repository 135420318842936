import axios from 'axios';

const settings = require('../settings');
const apiEndpoint = settings.ApiEndPoint;


export { newUser };

function buildReqBody(formik) {
    return {
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        loginEmail: formik.values.email,
        organizationName: formik.values.organizationName,
        role: formik.values.role,
        contact: {
            OfficePhone: formik.values.office_phone_number,
            MobileNumber: formik.values.phone_number,
        },
        password: formik.values.password,
    
    }
}

function newUser(formik) {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`${apiEndpoint}/createorgadmin`,
        JSON.stringify(buildReqBody(formik)), requestOptions)
        .then(res => res)
        .catch(error => error.response)
}


