import React, { useState } from 'react';
import ReactTooltip from "react-tooltip";
// material
import { IconButton, Tab, Box, Grid, Paper, Avatar, Button, TablePagination } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TimelineIcon from '@mui/icons-material/Timeline';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import BarChartIcon from '@mui/icons-material/BarChart';
import Usersummary from './Usersummary';
import ChartComponent from './ChartComponent';
import LockScreen from 'react-lock-screen'
import { Card, Typography } from '@mui/material';
import Iconify from '../../components/Iconify';
import styled from "@emotion/styled";
import { filter } from 'lodash';
import { reactLocalStorage } from 'reactjs-localstorage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CustomModal from './customModal';

export const StyleWrapper = styled.div`
.css-159d762-MuiPaper-root-MuiCard-root{
  background-color: #E2FBD8;
  color:#38BC41,
}
.css-1e7k1nu-MuiTypography-root{
  color: #212B36;
}
.css-1di7szp-MuiButtonBase-root-MuiButton-root:hover{
  background-color: #38BC41;
}
.css-1di7szp-MuiButtonBase-root-MuiButton-root{
  background-color: #38BC41;
}
.css-9uy14h{
  color: #38BC41;
}`

function createdetailtable(
  Profile: string,
  Name: string,
  Email: string,
) {
  return { Profile, Name, Email };
}

function createindividualdetailtable(
  Timeframe: string,
  Emotion: string,
  Headturnaround: string,
  eyegaze: string,
  Topics: string,
  Sentimentscore: string,
  Alert: string
) {
  return { Timeframe, Emotion, Headturnaround, eyegaze, Topics, Sentimentscore, Alert };
}

const detailtablerows = [
 
  
  createdetailtable("", "Andrea", "Andrea*******@gmail.com"),
  createdetailtable("", "Jharna", "Jharna*******@gmail.com"),
 
 
];

const userScores = {
  "Andrea*******@gmail.com": {
    "sentiment_score":{
      "title":"Individual sentiment score",
      "value": "98.1%",
      "state":"happy"
    },
    "participant_score":{
      "title":"Individual Participant score",
      "value": "97.45%",
      "state":"sad"
    },
    "engagement_score":{
      "title":"Individual Engagement score",
      "value": "97.75%",
      "state":"sad"
    },
    "emotion_summary":[
      {
        "title":"Happy",
        "total":"9.5%",
        "icon":'ci:happy'
      },
      {
        "title":"Sad",
        "total":"0%",
        "icon":'entypo:emoji-sad'
      },
      {
        "title":"Angry",
        "total":"0%",
        "icon":'fa-regular:angry'
      },
      {
        "title":"Neutral",
        "total":"75.5%",
        "icon":'entypo:emoji-neutral'
      },
      {
        "title":"Surprised",
        "total":"15%",
        "icon":'icon-park-outline:surprised-face-with-open-mouth'
      },
      {
        "title":"Disgust",
        "total":"0%",
        "icon":'icon-park-outline:confounded-face'
      },
      {
        "title":"Fear",
        "total":"0%",
        "icon":'emojione-monotone:fearful-face'
      },
      {
        "title":"Understanding",
        "total":"60%",
        "icon":'emojione-monotone:fearful-face'
      },
      {
        "title":"Confusion",
        "total":"2%",
        "icon":'emojione-monotone:fearful-face'
      },
      {
        "title":"Boredom",
        "total":"0%",
        "icon":'emojione-monotone:fearful-face'
      },
    ],
    "head_turn_around_summary":[
      {
        "title":"Straight",
        "total":"98%",
        "icon":'icon-park-outline:confounded-face',
        'color':'warning'
      },
      {
        "title":"Not Straight",
        "total":"2%",
        "icon":'emojione-monotone:fearful-face',
        'color':'warning'
      }
    ],
    "eye_gaze_summary":[
      {
        "title":"Looking screen",
        "total":"96.25%",
        "icon":'icon-park-outline:confounded-face',
        'color':'info'
      },
      {
        "title":"Not Looking screen",
        "total":"3.75%",
        "icon":'emojione-monotone:fearful-face',
        'color':'info'
      }
    ]
  },
  "Jharna*******@gmail.com": {
    "sentiment_score":{
      "title":"Individual sentiment score",
      "value": "96.1%",
      "state":"happy"
    },
    "participant_score":{
      "title":"Individual Participant score",
      "value": "97.3%",
      "state":"sad"
    },
    "engagement_score":{
      "title":"Individual Engagement score",
      "value": "96.7%",
      "state":"sad"
    },
    "emotion_summary":[
      {
        "title":"Happy",
        "total":"40.2%",
        "icon":'ci:happy'
      },
      {
        "title":"Sad",
        "total":"0%",
        "icon":'entypo:emoji-sad'
      },
      {
        "title":"Angry",
        "total":"0%",
        "icon":'fa-regular:angry'
      },
      {
        "title":"Neutral",
        "total":"47.5%",
        "icon":'entypo:emoji-neutral'
      },
      {
        "title":"Surprised",
        "total":"12.3%",
        "icon":'icon-park-outline:surprised-face-with-open-mouth'
      },
      {
        "title":"Disgust",
        "total":"0%",
        "icon":'icon-park-outline:confounded-face'
      },
      {
        "title":"Fear",
        "total":"0%",
        "icon":'emojione-monotone:fearful-face'
      },
      {
        "title":"Understanding",
        "total":"65%",
        "icon":'emojione-monotone:fearful-face'
      },
      {
        "title":"Confusion",
        "total":"2%",
        "icon":'emojione-monotone:fearful-face'
      },
      {
        "title":"Boredom",
        "total":"1%",
        "icon":'emojione-monotone:fearful-face'
      },
    ],
    "head_turn_around_summary":[
      {
        "title":"Straight",
        "total":"80.1%",
        "icon":'icon-park-outline:confounded-face',
        'color':'warning'
      },
      {
        "title":"Not Straight",
        "total":"19.9%",
        "icon":'emojione-monotone:fearful-face',
        'color':'warning'
      }
    ],
    "eye_gaze_summary":[
      {
        "title":"Looking screen",
        "total":"88%",
        "icon":'icon-park-outline:confounded-face',
        'color':'info'
      },
      {
        "title":"Not Looking screen",
        "total":"12%",
        "icon":'emojione-monotone:fearful-face',
        'color':'info'
      }
    ]
  },
  
 
 
 
}

const individualdetailtablerows = [
  createindividualdetailtable("0-1 min", "Happy", "Straight", "looking screen", "", "80%", "-"),
  createindividualdetailtable("1-2 min", "Neutral", "Not Straight", "Not looking screen", "", "70%", ""),
  createindividualdetailtable("2-3 min", "sad", "Not Straight", "Not looking screen", "", "50%", ""),
  createindividualdetailtable("3-4 min", "Neutral", "Not Straight", "Not looking screen", "", "60%", ""),
  createindividualdetailtable("4-5 min", "sad", "Not Straight", "Not looking screen", "", "60%", ""),
  createindividualdetailtable("5-6 min", "Happy", "Straight", "looking screen", "", "85%", ""),
  createindividualdetailtable("6-7 min", "Neutral", "Not Straight", "Not looking screen", "", "80%", ""),
  createindividualdetailtable("7-8 min", "Neutral", "Not Straight", "Not looking screen", "", "80%", ""),
  createindividualdetailtable("8-9 min", "Happy", "Not Straight", "Not looking screen", "", "85%", ""),
  createindividualdetailtable("9-10 min", "Happy", "Straight", "looking screen", "", "85%", ""),
  createindividualdetailtable("10-11 min", "Neutral", "Not Straight", "Not looking screen", "", "80%", ""),
  createindividualdetailtable("11-12 min", "sad", "Not Straight", "Not looking screen", "", "60%", ""),
  createindividualdetailtable("12-13 min", "Neutral", "Straight", "looking screen", "", "80%", ""),
  createindividualdetailtable("13-14 min", "Neutral", "Not Straight", "Not looking screen", "", "80%", ""),
  createindividualdetailtable("14-15 min", "Neutral", "Not Straight", "Not looking screen", "", "80%", ""),
  createindividualdetailtable("15-16 min", "Happy", "Straight", "looking screen", "", "85%", ""),
  createindividualdetailtable("16-17 min", "Happy", "Not Straight", "Not looking screen", "", "90%", ""),
  createindividualdetailtable("17-18 min", "Neutral", "Not Straight", "Not looking screen", "", "80%", ""),
  createindividualdetailtable("18-19 min", "Neutral", "Straight", "looking screen", "", "80%", ""),
  createindividualdetailtable("19-20 min", "Neutral", "Not Straight", "Not looking screen", "", "80%", ""),
];

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.Name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function Useranalytics(props) {

  let {rowsPerPageValue,pageValue,sortOrderValue,sortOrderByValue,setTabTitle} = props;
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageValue ?? 5);
  const [page, setPage] = useState(pageValue ?? 0);

  const [order] = React.useState(sortOrderValue ?? 'asc');
  const [orderBy] = React.useState(sortOrderByValue ?? 'Date');
  const [filterName] = useState('');

  const filteredUsersData = applySortFilter(detailtablerows, getComparator(order, orderBy), filterName);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [liststate, setListState] = React.useState(true);
  const [vizstate, setVizState] = React.useState(false);
  const [backButton, setBackButton] = React.useState(false);

  const [filteredUsers]= React.useState(filteredUsersData);
  const [userScoresData, setUserScoresData]= React.useState();


  reactLocalStorage.set('visbility',);
  const handlePreview = (currentUser) => {
    let {Email} = currentUser;
    setUserScoresData(userScores[Email]);
    setListState(false)
    setVizState(true)
    setBackButton(true);
  }

  const changePage = () => {
    setTabTitle('Session Partcipant List')
    setListState(true);
    setVizState(false);
    setBackButton(false);
  }



  return (
    <>
      {<div style={{ marginTop: '-61px', marginRight: '69px', position: 'absolute', right: '0' }}>
      <IconButton aria-label="delete" size="large" color="success" data-place="left" data-tip data-for="registerTip" style={{ marginLeft: "auto", padding: "15px" }}>
         {backButton && <ArrowBackIcon onClick={changePage} fontSize="inherit" />}
        </IconButton>
          <ReactTooltip backgroundColor="#E2FBD8" textColor="#38BC41" id="registerTip" place="top" effect="solid">
              <strong> Back </strong>
          </ReactTooltip>
       </div>
      }
      <Grid container spacing={3}>
        {liststate && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Profile</TableCell>
                  <TableCell align="right">Name</TableCell>
                  <TableCell align="right">Email</TableCell>
                  <TableCell align="right">Analytics</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >

                    <TableCell align="right">
                      <Avatar alt={row.Name} src={row.Profile} />

                    </TableCell>
                    <TableCell align="right">
                      <div style={{"marginRight": "-35px","display":"flex","float": "right"}}>
                        {row.Name}
                        <CustomModal value={row.Name}/>
                      </div>
                    </TableCell>
                    <TableCell align="right">{row.Email}</TableCell>
                    <TableCell align="right">
                      <IconButton color="primary" aria-label="upload picture" component="label" onClick={() => handlePreview(row)}>
                        <TimelineIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[rowsPerPageValue, rowsPerPageValue+5, rowsPerPageValue+10]}
              component="div"
              count={detailtablerows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
        <div>
          {vizstate && (
            <Analyticstab accountType={"premium"} setTabTitle={setTabTitle} liststate={liststate} userScoresData={userScoresData}/>
          )}
        </div>
      </Grid>
    </>
  );
}

function Analyticstab(props) {
  let {accountType, setTabTitle, liststate,userScoresData} = props;
  reactLocalStorage.set('accountType', accountType);
  if(!liststate)
  {
      setTabTitle('');
  }

  var TableDetails = () => {
    return (
      <>
        <Avatar src="Andrea Blended session"/>
        <TabContext value={usersetailtab}>
      
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleuserdetailChange}
              aria-label="icon position tabs example"
            >
              <Tab icon={<BackupTableIcon />} iconPosition="start" label="Table" value="Table" />
              <Tab icon={<BarChartIcon />} iconPosition="start" label="Chart" value="Chart" />

            </TabList>
          </Box>
          <TabPanel value="Table">
            <Grid container spacing={3}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">Timeframe</TableCell>
                      <TableCell align="right">Emotion</TableCell>
                      <TableCell align="right">Headturnaround</TableCell>
                      <TableCell align="right">EyeGaze</TableCell>
                      <TableCell align="right">Topics</TableCell>
                      <TableCell align="right">Sentimentscore</TableCell>
                      <TableCell align="right">Alert</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {individualdetailtablerows.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >

                        <TableCell align="right">{row.Timeframe}</TableCell>
                        <TableCell align="right">{row.Emotion}</TableCell>
                        <TableCell align="right">{row.Headturnaround}</TableCell>
                        <TableCell align="right">{row.eyegaze}</TableCell>
                        <TableCell align="right">{row.Topics}</TableCell>
                        <TableCell align="right">{row.Sentimentscore}</TableCell>
                        <TableCell align="right">{row.Alert}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

            </Grid>
          </TabPanel>
          <TabPanel value="Chart">
          <ChartComponent GraphType="UserAnalytics" />
          </TabPanel>
        </TabContext></>
    )
  }

  const getLockScreenUi = setLock => {
    return (
      <StyleWrapper>
        <div style={{ width: "100%", height: "100%" }} />
        <div style={{ position: "relative", top: "-40em", left: "30%", width: "30%" }}>
          <Card
            sx={{
              py: 5,
              boxShadow: 0,
              textAlign: 'center',
              color: (theme) => theme.palette['info'].darker,
              bgcolor: (theme) => theme.palette['info'].lighter,
            }}
          >
            <Iconify icon="il:lock" width={24} height={24} />

            <Typography variant="h3"></Typography>

            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              Upgrade to unlock this feature
            </Typography>
            <br />
            <Button variant="contained" color="primary" href="/dashboard/pricing" target="_blank" >
              Upgrade
            </Button>
          </Card>
        </div>
      </StyleWrapper>
    );

  };

  const [isUserAnalyticsSummaryPurchased] = React.useState(false);
  const [isUserAnalyticsDetailsPurchased] = React.useState(false);

  const [useranalyticsvalue, setUseranalyticsvalue] = React.useState('Summary');
  const [usersetailtab, setUsersetailtab] = React.useState('Table');

  const handleusernalyticsChange = (event, newValue) => {
    setUseranalyticsvalue(newValue);
  };

  const handleuserdetailChange = (event, newValue) => {
    setUsersetailtab(newValue);

  };

  return (

    <>
      <Avatar src=""/>
      <TabContext value={useranalyticsvalue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleusernalyticsChange}
            aria-label="icon position tabs example"
          >
            <Tab icon={<BackupTableIcon />} iconPosition="start" label="Summary" value="Summary" />
            <Tab icon={<BarChartIcon />} iconPosition="start" label="Detail" value="Detail" />

          </TabList>
        </Box>
        <TabPanel value="Summary">
          {isUserAnalyticsSummaryPurchased ? <Usersummary /> : (
            reactLocalStorage.get('accountType') === 'Free' ? <LockScreen
              timeout={1}
              ui={getLockScreenUi}>
              <Usersummary userScoresData={userScoresData} />
            </LockScreen> : <Usersummary userScoresData={userScoresData} />
          )}
        </TabPanel>
        <TabPanel value="Detail">
          {isUserAnalyticsDetailsPurchased ? <TableDetails /> : (
            reactLocalStorage.get('accountType') === 'Free' ? <LockScreen
              timeout={1}
              ui={getLockScreenUi}
            >
              <TableDetails />
            </LockScreen> : <TableDetails />
          )}
        </TabPanel>
      </TabContext>
    </>
  );
}








