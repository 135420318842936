import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import { Link as RouterLink } from 'react-router-dom';
import styled from "@emotion/styled";

import { pricingdetails } from "../../Services/payment";

export const StyleWrapper = styled.div`
.css-1hdalm0-MuiCardHeader-root {
  background-color:#E2FBD8;
}
.css-10zx7qr-MuiGrid-root>.MuiGrid-item {
  padding-left: 5px;
}
.css-10zx7qr-MuiGrid-root>.MuiGrid-item {
  padding-top: 10px;
}
.css-xdmu94-MuiContainer-root {
  margin-left: 200px;
}`


function PricingContent()  {

  const [tiers, settiers] = React.useState([]);

  useEffect(() => {
    pricingdetails().then(res=>
      {
        if(res){
          if (res.status === 200) {
            settiers(res.data)  
          }
        }
  });
   })
 
  
  return (
    <React.Fragment>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
      </AppBar>
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Pricing
        </Typography>
      </Container>
      <StyleWrapper>
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end" paddingRight={10} >
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={24}
              sm={tier.title === 'Basic' ? 12 : 6}
              md={3}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Premium' ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="text.primary">
                      {tier.price}
                    </Typography>
                    
                  </Box>
                  <ul>
                  <Typography component="li" variant="" align="center"color="#C4CDD5">
                      {tier.extra}
                    </Typography>
                  </ul>
                  <ul>
                  <Typography component="li" variant="" align="center">
                      {tier.description}
                    </Typography>
                  </ul>
                  <ul>
                  <Typography component="li" variant="" align="center">
                      {tier.descriptionOne}
                    </Typography>
                  </ul>
                  
                  <ul>
                  <Typography component="li" variant="" align="center">
                      {tier.descriptionTwo}
                    </Typography>
                  </ul>
                  
                  <ul>
                  <Typography component="li" variant="h8" align="center"color="text.secondary">
                      {tier.textOne}
                    </Typography>
                  </ul>
                  <ul>
                  <Typography component="li" variant="" align="center">
                      {tier.symbol}
                    </Typography>
                  </ul>
                  <ul>
                  <Typography component="li" variant="" align="center">
                      <strong>{tier.bold}</strong>
                    </Typography>
                  <Typography component="li" variant="" align="center"color="text.secondary">
                      {tier.type}
                    </Typography>
                  <Typography component="li" variant="" align="center"color="#C4CDD5">
                      {tier.extraOne}
                    </Typography>
                  </ul>
                  <ul>
                  <Typography component="li" variant="" align="center">
                      {tier.descriptionThree}
                    </Typography>
                  <Typography component="li" variant="" align="center">
                      {tier.descriptionFour}
                    </Typography>
                  <Typography component="li" variant="" align="center">
                      {tier.descriptionFive}
                    </Typography>
                  <Typography component="li" variant="" align="center">
                      {tier.descriptionSix}
                    </Typography>
                  </ul>
                  <ul>
                  <Typography component="li" variant="h8" align="center"color="text.secondary">
                      {tier.textTwo}
                    </Typography>
                  </ul>
                  <ul>
                  <Typography component="li" variant="" align="center">
                      {tier.symbolOne}
                    </Typography>
                  </ul>
                  <ul>
                  <Typography component="li" variant="" align="center">
                      <strong>{tier.boldOne}</strong>
                    </Typography>
                  <Typography component="li" variant="" align="center"color="text.secondary">
                      {tier.typeOne}
                    </Typography>
                  </ul>
                  <ul>
                  <Typography component="li" variant="" align="center"color="#C4CDD5">
                      {tier.extraTwo}
                    </Typography>
                  </ul>
                  <ul>
                  <Typography component="li" variant="" align="center">
                      {tier.descriptionSeven}
                    </Typography>
                  <Typography component="li" variant="" align="center">
                      {tier.descriptionEight}
                    </Typography>
                  <Typography component="li" variant="" align="center">
                      {tier.descriptionNine}
                    </Typography>
                  <Typography component="li" variant="" align="center">
                      {tier.descriptionTen}
                    </Typography>
                  </ul>
                </CardContent>
                <CardActions style={{paddingRight:"10px",textAlign:"center",justifyContent: 'center'}}>
                <Link component={RouterLink} to={tier.Link !== undefined ? tier.Link : ""} state={{ tiername:tier.title }}>
                  <Button variant={tier.buttonVariant}style={{paddingRight:"60px",textAlign:"left"}} size="medium">
                  {tier.buttonText} 
                  </Button>
                  </Link>
                
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      </StyleWrapper>
      {/* Footer */}
      <Container
        maxWidth="md"
        component="footer"
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          mt: 8,
          py: [3, 6],
        }}
      >
      </Container>
      {/* End footer */}
    </React.Fragment>
  );
}

export default function Pricing() {
  return <PricingContent />;
}