import React, {useState , useEffect} from "react";
import { Link } from 'react-router-dom';
import { Grid, Box, Card, CardContent, Typography, CardActions, Button, CardMedia} from '@mui/material';
import  Cameralist from "../sections/Cctvinsights/Cameralist.js";
import Page from '../components/Page';




export default function Camerakart() {
    const cameras = {
        "Table 2": 'https://rtsp.me/embed/a4tbEr7B/',
        "Table 4 wide": 'https://rtsp.me/embed/h3QK8Dae/',
        
    };

    return (
        <Page title="CCTV Insights">
            
            <Cameralist cameras={cameras} />  
        </Page>
    );
};

