import React, { useEffect } from 'react';
import axios from 'axios';

const RenderRazorpay = ({ amount, currency, orderId, keyId, keySecret, userId }) => {
  useEffect(() => {
    const loadRazorpay = async () => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onload = async () => {
        const options = {
          key: keyId,
          amount: amount,
          currency: currency,
          order_id: orderId,
          handler: async (response) => {
            console.log(response);
            try {
              await axios.post('https://akvisionpaymentservices-er4v7pzexq-el.a.run.app/update-credits', {
                user_id: userId,
                credits: amount / 100 / 5 // Calculate credits based on price per click
              });
              alert('Credits updated successfully');
            } catch (error) {
              console.error('Error updating credits:', error);
            }
          },
          prefill: {
            name: 'Your Name',
            email: 'email@example.com',
            contact: '9999999999'
          },
          theme: {
            color: '#3399cc'
          }
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      };
      document.body.appendChild(script);
    };
    loadRazorpay();
  }, [amount, currency, orderId, keyId, keySecret, userId]);

  return null;
};

export default RenderRazorpay;
