import axios from 'axios';
const settings = require('../settings');
const apiEndpoint = settings.ApiEndPoint;


export {emailUser} ;


function emailUser(emailStr) {
        const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`${apiEndpoint}/sendemailverificationcode?emailStr=${emailStr}`, 
     requestOptions)
        .then(res => res)
        .catch(error => error.response)
}