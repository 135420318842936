import React, { useState, useEffect,useRef } from 'react';
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from '@mui/material/TextField';
import DialogContentText from "@mui/material/DialogContentText";
import Stack from '@mui/material/Stack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';
import {Divider} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useFormik, FormikProvider,useField } from 'formik';
import * as Yup from 'yup';

import { createmeeting,getzoomtimezone,startmeetinganalytics} from "../../../Services/zoomintegration";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));



export default function Zoom(props) {
  const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  }));
  
  const [open, setOpen] = React.useState(true);

  const theme = useTheme();
  const [timezones, setTimezones] = React.useState([]);
  const [startdate, setStartdate] =React.useState(props.date);
  const [selectedtimezone,setSelectedtimezone]=React.useState("");
  const [duration,setDuration]=React.useState("");
  const [emails, setEmails] = useState([]);
  const [showcreateresponse,setShowcreateresponse]=React.useState(false);
  const [responsemessage,setResponsemessage]=React.useState("");
 


  const MeetingSchema = Yup.object().shape({
    title: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!').required('Meeting title is required'),
    description: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!'),
    duration: Yup.number().positive('Duration should be a positive number').required('Duration is required'),
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      duration: '',
    },
    
    validationSchema: MeetingSchema,
   
  });

  const { errors, touched,getFieldProps } = formik;
  var [title] = useState('');
  var [description] = useState('');
  
  const timezoneref = useRef("");
  const titleref = useRef("");
  const descriptionref = useRef("");
  const durationref = useRef("");
  const emaillistRef= useRef("");


  useEffect(() => {
    handletimezone()
  }, []);

  const handleClose = () => {
    setOpen(false);
    window.location.replace('/dashboard/online');
  };

  const handleDialogClose = () => {
    setShowcreateresponse(false)
  };


  
  const handlecreate =() => {
       const meetingdetail={
        "topic": formik.values.title,
        "start_time": startdate,
        "duration":formik.values.duration,
        "timezone": selectedtimezone,
        "agenda": formik.values.description,
        "attendees": emails.toString()
      }
    console.log("meeting details",meetingdetail)
    
    createmeeting(meetingdetail).then(res=>
      {
        if(res){
            console.log("meeting response",res)
          if (res.status === 200) {
            setShowcreateresponse(true)
            setResponsemessage(res.data.detail)
              }
          else if (res.status === 404) {
            setShowcreateresponse(true)
            setResponsemessage(res.data.detail)  
          }
          else {
            setShowcreateresponse(true)
            setResponsemessage(res.data.detail)  
          }
          
}
      })
  }
  

  const handletimezone = () =>
  {
    getzoomtimezone().then(res=>
      {
        if(res){
          if (res.status === 200) {
            
            setTimezones(res.data) 
  }
}
      })
    }

    
 
    const handletimezoneselect = (event) => {
      console.log("selected timezone",event.target.value)
      setSelectedtimezone(event.target.value);
      console.log("selected timezone set",selectedtimezone)

    };

    const handledurationchange = (event) => {
      const {
        target: { value },
      } = event;
      setDuration(value);
    };

   

    const handleEmailChange = (event, values) => {
   
      setEmails(values);
    };

  

    const handlestartdate= (value) =>
  {
    let datavalue=value["$d"].toString()
    datavalue=datavalue.split(" ");
    datavalue=datavalue.slice(1,5)
    datavalue=datavalue.join(" ")
    console.log("start date",datavalue)
    setStartdate(datavalue)
  } 
 
  return (
     <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        justifyContent="center"
        alignItems="center"
       >
   
        <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
        <Stack style={{alignItems:"center"}}>
        <img src="/static/icons/Zoom48.png"alt="Zoom"/>
        <h2>Zoom Meeting Scheduler</h2>
        
        </Stack>
        
        <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
        <Divider variant="middle"/>
        <DialogContent style={{alignItems:"center" ,width:"350px"}} >
    
        <LocalizationProvider
             dateAdapter={AdapterDayjs}
            >
              
     <Stack direction="row" spacing={3} >
        <MobileDateTimePicker
          label="StartDate"
          value={startdate}
          onChange={(startnewValue) => handlestartdate(startnewValue)}
          renderInput={(params) => <TextField {...params} />}
        
        />
      </Stack>
    </LocalizationProvider> 
    <Stack direction="row" spacing={20} alignItems="center" justifyContent="space-between" sx={{ my: 2 }}></Stack>
      <FormControl sx={{ width: '260px' }}>
        <InputLabel id="demo-multiple-name-label">TimeZone</InputLabel>
<        Select
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        //value={selectedtimezone}
        label = "Time Zone"
        {...formik.getFieldProps('selectedtimezone')}
        onClick={()=>handletimezone()}
        onChange={handletimezoneselect}
        input={<OutlinedInput label="timezone list" inputRef={timezoneref} />}
      >
        {timezones.map((timezone) => (
          <MenuItem key={timezone} value={timezone}>
            {timezone}
          </MenuItem>
        ))}
      </Select>
      </FormControl>
      <Stack direction="row" spacing={20} alignItems="center" justifyContent="space-between" sx={{ my: 2 }}></Stack>
    <FormikProvider value={formik}> 
    <TextField 
                  fullWidth
                  autoComplete='duration'
                  type='number'
                    label="Duration in minutes"
                    {...formik.getFieldProps('duration')}
                    error={Boolean(touched.duration && errors.duration)}
                    helperText={touched.duration && errors.duration}
                  />

<Stack direction="row" spacing={20} alignItems="center" justifyContent="space-between" sx={{ my: 2 }}></Stack>
      
        <div>
        <TextField 
                  fullWidth
                  autoComplete='title'
                  type='text'
                    label="Meeting Title"
                    {...formik.getFieldProps('title')}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                  />

           </div>
           <Stack direction="row" spacing={20} alignItems="center" justifyContent="space-between" sx={{ my: 2 }}></Stack>
           <div>
           
           <TextField 
                  fullWidth
                  autoComplete='description'
                  type='text'
                    label="Description"
                    {...formik.getFieldProps('description')}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />
           </div>
           <Stack direction="row" spacing={20} alignItems="center" justifyContent="space-between" sx={{ my: 2 }}></Stack>
           </FormikProvider>    
     <Autocomplete
        style={{width: "260px"}}
        multiple
        freeSolo
        id="tags-outlined"
        options={[]}
        defaultValue={[]}
        value={emails}
        onChange={handleEmailChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Email list"
            placeholder="Email"
          />
        )}
      />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button  onClick={handlecreate}autoFocus>
            Add
          </Button>
        </DialogActions>
      </BootstrapDialog>

      { showcreateresponse && (
        <Dialog
        style={{opacity:0.8}}
        open={showcreateresponse}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {responsemessage}
          </DialogContentText>
          <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
        </DialogContent>
        </Dialog>
    
    )}
      </>
  );
}