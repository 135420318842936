import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import Page from "../components/Page";
import { Card, Stack,Typography, Container,IconButton ,Tooltip} from "@mui/material";
import CustomizedBreadcrumbs from '../components/Breadcrumbs';
import React, {useState , useEffect} from "react";
import  AddMeeting from "../sections/auth/Online/AddMeeting";
import  ScheduleMeeting from "../sections/auth/Online/ScheduleMeeting";
import interactionPlugin from '@fullcalendar/interaction';
import styled from "@emotion/styled";
import { Icon } from '@iconify/react';

import {getonlinesession} from '../Services/onlinesession'

export const StyleWrapper = styled.div`
.fc .fc-daygrid-day.fc-day-today {
  background-color: #DFE3E8;
  background-color: var(--fc-today-bg-color, #DFE3E8);
}
.fc .fc-timegrid-col.fc-day-today {
  background-color:  #DFE3E8;
  background-color: var(--fc-today-bg-color,  #DFE3E8);
}
.fc .fc-toolbar-title {
  font-size: 1.2em;
  margin: 0;
}
.fc .fc-button:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(56, 188, 65);
}
.fc .fc-button-primary {
  color: #fff;
  color: var(--fc-button-text-color, #fff);
  background-color: #38bc41;
  background-color: var(--fc-button-bg-color, #38bc41);
  border-color: #38bc41;
  border-color: var(--fc-button-border-color, #38bc41);
}
.fc .fc-button-primary:hover {
  color: #fff;
  color: var(--fc-button-text-color, #fff);
  background-color: #0E6D0E;
  background-color: var(--fc-button-hover-bg-color, #0E6D0E);
  border-color: #0E6D0E;
  border-color: var(--fc-button-hover-border-color, #0E6D0E);
}
.fc .fc-button-primary:disabled { /* not DRY */
    color: #fff;
    color: var(--fc-button-text-color, #fff);
    background-color: #38bc41;
    background-color: var(--fc-button-bg-color, #38bc41);
    border-color: #38bc41;
    border-color: var(--fc-button-border-color, #38bc41); /* overrides :hover */
  }
  .fc .fc-button-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(56, 188, 65);
  }
  .fc .fc-button-primary:not(:disabled):active,
  .fc .fc-button-primary:not(:disabled).fc-button-active {
    color: #fff;
    color: var(--fc-button-text-color, #fff);
    background-color: #38bc41;
    background-color: var(--fc-button-active-bg-color, #38bc41);
    border-color: #38bc41;
    border-color: var(--fc-button-active-border-color, #38bc41);
  }
  .fc .fc-button-primary:not(:disabled):active:focus,
  .fc .fc-button-primary:not(:disabled).fc-button-active:focus {
    box-shadow: 0 0 0 0.2rem rgba(226, 251, 216);
  }

  .fc .fc-col-header{
    width:100% !important
  }

  .fc .fc-daygrid-body-balanced {
    width:100% !important
  }

  .fc .fc-scrollgrid-sync-table{
    width:100% !important
  }

  
`

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 10,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + -24,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  }
}));

export default function Calendar() {
 
  
  const [selectedDate,setSelectedDate]= useState(true);


  const [dialogState,setDialogstate]=React.useState(false);
  const [dialogStateone,setDialogstateone]=React.useState(false);
  const [calendarEvents, setCalendarEvents] = useState([]);
 
 
 
  
  function handleEventClick(eventInfo) {
    console.log('Event clicked: ', eventInfo.event);
  }


  const handleEvents = () => {
    getonlinesession().then(res=>
      {
        if(res){
          if (res.status === 200) {
           setCalendarEvents(res.data)
          }
        }
      })
  }
  
  useEffect(() => {
    handleEvents();
  }, []);

  const handlesession = () => {
    setDialogstate(true)
  }
  
  const handlesessionone = () => {
    const currentdate = new Date()
    setSelectedDate(currentdate)
    setDialogstateone(true)
  }

  const handleDateSelect = (selectInfo) => {

    setSelectedDate(selectInfo.start)
    setDialogstateone(true)
  };

  return (
    <Page title="Online">

      <Container style={{maxWidth:'none'}}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} >
          <Typography variant="h4" gutterBottom>
            Calendar
          </Typography>
          
        </Stack>
        <CustomizedBreadcrumbs />
      </Container>
      <Card style={{ margin: "27px", width: "auto" }}>
        <div className="card card-calendar" style={{ width: "auto" }}>
          <div className="card-body p-3" style={{ width: "auto" }}>
            <StyleWrapper>
              <RootStyle>
                <MainStyle style={{}}>
                  <FullCalendar 
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    headerToolbar={{
                      left: "dayGridMonth,timeGridWeek,timeGridDay",
                      center: "title",
                      right: "prev,next today",
                    }}
                    initialView="dayGridMonth"
                    weekends={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    eventColor="#38bc41"
                    events={calendarEvents}
                    select={handleDateSelect}
                    displayEventTime={true}
                    eventAdd={function () { }}
                    eventChange={function () { }}
                    eventRemove={function () { }}
                  />
                </MainStyle>
              </RootStyle>
            </StyleWrapper>
          </div>
        </div>
      </Card>
    </Page>
  );
}
