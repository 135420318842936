import * as Yup from 'yup';
import React,{ useState } from 'react';
import { useFormik} from 'formik';
import {useNavigate } from 'react-router-dom';
import { Box, Button, Card, CardContent, Container, FormControl,IconButton, InputAdornment,TextField } from "@mui/material";
import SnackBar from '../../components/Snackbar/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Iconify from '../../components/Iconify';
import { ChangePassword } from '../../Services/changePassword'
import './account.css';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

export default function Password() {

    var [loginValue] = useState("");
    var [currentPassword] = useState("");
    var [newPassword] = useState("");
    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const [snackbarTitle, setSnackbarTitle] = React.useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);

    const navigate = useNavigate();

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
        return;
        }
        setSnackBarOpen(false);
        };
  
    const [state] = React.useState({
      open: false,
      vertical: 'bottom',
      horizontal: 'center',
      color:'#E2FBD8',
    });

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
        };

        const handleShowNewPassword = () => {
            setShowNewPassword((show) => !show);
            };
        
        const ChangePasswordSchema = Yup.object().shape({
          email: Yup.string().email('Email must be a valid email address').required('Email is required'),
          currentPassword: Yup.string().required('Password is required'),
          newPassword: Yup.string().required('Password is required'),
        });
        
        const formik = useFormik({
          initialValues: {
            email: '',
            currentPassword: '',
            newPassword: '',
            },
          validationSchema: ChangePasswordSchema,
          onSubmit: () => {
          console.log(values);   
          },
        });
        
        const { errors, touched, values} = formik;
        
        const handlechangepassword = async (loginValue,currentPassword,newPassword) => {
            ChangePassword(formik).then(res => {
                   
              if(res){
                  if (res.status === 201) {
                     setTimeout(() => {
                   setSnackbarTitle(" Valid Credentials")
                   setSnackBarOpen(true)
                   navigate('/dashboard/app')
                 }, 2000);
                  }
                  else if (res.status === 401) {
                      setSnackbarTitle("check for the credentials")
                      setSnackBarOpen(true)
                  }
                  else if (res.status === 401 || res.status == 409) {
                      setSnackbarTitle(res.data.detail)
                      setSnackBarOpen(true)  
                  }
              }
              else{
                  //response error 
              }
          }
           )
         }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Container maxWidth="xl">
                <Card>
                    <CardContent >
                        <FormControl className="formControll">
                            <TextField 
                            id="loginEmail" 
                            className="PasswordForm" 
                            label="Email Address" 
                            type="email" 
                            variant="outlined" 
                            {...formik.getFieldProps('email')}
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                            />
                            <TextField 
                            id="currentPassword" 
                            className="PasswordForm" 
                            label="Old Password" 
                            type={showPassword ? 'text' : 'password'} 
                            variant="outlined"
                            {...formik.getFieldProps('currentPassword')}
                            error={Boolean(touched.currentPassword && errors.currentPassword)}
                            helperText={touched.currentPassword && errors.currentPassword}  
                            InputProps={{ 
                                endAdornment: (
                             <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}/>
                            <TextField 
                            id="newpassword" 
                            className="PasswordForm" 
                            label="New Password" 
                            type={showNewPassword ? 'text' : 'password'} 
                            variant="outlined"
                            {...formik.getFieldProps('newPassword')}
                            error={Boolean(touched.newPassword && errors.newPassword)}
                            helperText={touched.newPassword && errors.newPassword}  
                             InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={handleShowNewPassword} edge="end">
                                      <Iconify icon={showNewPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                               />
                            <div class="buttonSubmitNotificationDiv">
                                <Button onClick={() => handlechangepassword( loginValue,currentPassword,newPassword)} variant="contained" color="success" className="buttonSubmitNotification">
                                    Save Changes
                                </Button>
                                <SnackBar
                open={snackBarOpen}
                close={handleClose}
                snackbarTitle={snackbarTitle}
            />
                            </div>
                        </FormControl>
                    </CardContent>
                </Card>
            </Container>
        </Box>);
}