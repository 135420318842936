import React, {useState , useEffect} from "react";
import { Link } from 'react-router-dom';
import { Grid, Box, Card, CardContent, Typography, CardActions, Button, CardMedia} from '@mui/material';
import  Orderdetailview from "../sections/AkEmoeats/Orderdetailview.js";
import Page from '../components/Page';




export default function AkEmoeats() {
    

    return (
        <Page title="Ak emoeats">
            <Orderdetailview />  
        </Page>
    );
};

