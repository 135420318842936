import React, { useRef, useState } from 'react';
import ReactPlayer from "react-player";
import Detailanalytics from './Detailanalytics';
import Useranalyticschart from './useranalyticschart';
import {Slider, Box}  from '@mui/material';

const minDistance = 5;

export default function ChartComponent(props) {
        const[duration,setDuration]=useState(0);
        const playerRef=useRef(null);
        const [value, setValue] = React.useState([0, 5]);
        const [maxValue, setMaxValue] = React.useState(Math.ceil(duration / 60));
        const[isDetailAnalytics, setDetailAnalytics]=useState(false);
        const[isUserDetailAnalytics, setUserDetailAnalytics]=useState(false);

            //re-render object based on the value change in the state
        React.useEffect(() => {
            setMaxValue(Math.ceil(duration / 60));
            HandleGraph()
        }, [duration]);

        const HandleGraph = () => {
            if(props.GraphType == "DetailAnalytics")
            {
                setDetailAnalytics(true)
            }
            else
            {
                setUserDetailAnalytics(true)
            }
        }

        const handleChange = (event, newValue, activeThumb) => {
            if (!Array.isArray(newValue)) {
                return;
            }
            if (activeThumb === 0) {
                const clamped = Math.min(newValue[0], Math.ceil(duration / 60) - minDistance);
                setValue([clamped, clamped + minDistance]);
            } else {
                const clamped = Math.max(newValue[1], minDistance);
                setValue([clamped - minDistance, clamped]); 
            }
        };

        const handleReady=()=>{
            setDuration(playerRef.current.getDuration);
        }
        return (
            <div className='player-wrapper'>
                <ReactPlayer
                    ref={playerRef}
                    onReady={handleReady}
                    url='https://attentionkart089qryqxrkmdb.s3.ap-south-1.amazonaws.com/offline/Andrea+Blended+session/input/Andrea+Blended+session.mp4'
                    className='react-player'
                    controls
                    width='100%'
                    height='100%'
                />
                <Box sx={{ width: "100%" }}>
                    <Slider
                    getAriaLabel={() => 'Temperature range'}
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    max={Math.ceil(maxValue)}
                    />
                </Box>
                {isDetailAnalytics &&(
                 <Detailanalytics StartPoint= {value[0]} EndPoint= {value[1]} />) }
                {isUserDetailAnalytics && (
                 <Useranalyticschart StartPoint= {value[0]} EndPoint= {value[1]} />)}
            </div>
        );  
}