import axios from 'axios';

const settings = require('../settings');
const apiEndpoint = settings.ApiEndPoint;


export {verifyCodeWithID} ;


function verifyCodeWithID(emailStr, code) {
        const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`${apiEndpoint}/verifycode?emailStr=${emailStr}&code=${code}`, 
     requestOptions)
        .then(res => res)
        .catch(error => error.response)
}
