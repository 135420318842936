import axios from 'axios';
const settings = require('../settings');
const apiEndpoint = settings.ApiEndPoint;


export {ChangePassword} ;

function buildReqBody(formik) {
    return {
        loginEmail: formik.values.email,
        currentPassword: formik.values.currentPassword,
        newPassword: formik.values.newPassword,
         }
}

function ChangePassword(formik) {
    const requestOptions = {

        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`${apiEndpoint}/changepassword`,
        JSON.stringify(buildReqBody(formik)), requestOptions)
        .then(res => res)
        .catch(error => error.response)
}