
// material
import { Avatar, Box, Card, CardContent, Container, FormControl, Grid, Paper,  Stack, styled, Typography } from "@mui/material";
// component
import React from 'react';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


export default function UserProfileForm() {
 return (
        <>
        <h1>Profile </h1>   
         <Box sx={{ flexGrow: 1 }}>
            <FormControl className="formControll">
                <Container maxWidth="xl">
                    <Grid container spacing={2} >
                        <Grid item xs={4}>
                            <Item className="item">
                                <Card>
                                    <CardContent elevation={3}>
                                        <Stack direction="row" className="stack">
                                            
                                                    <Avatar Sharp
                                                        alt="Remy Sharp"
                                                        src="https://res.cloudinary.com/minimal-ui/image/upload/v1614655910/upload_minimal/avatar/minimal_avatar.jpg"
                                                        sx={{ width: 150, height: 150 }}
                                                    />
                                        </Stack>
                                        <Typography className="typography labelSize" sx={{ mb: 5 }}>
                                            Jaydon
                                        </Typography>
                                       
                                    </CardContent>
                                </Card>
                            </Item>
                        </Grid>
                        <Grid item xs={8}>
                            <Item   >
                               <Typography className="typography labelSize" sx={{ mb: 5 }}>
                                            Organization : Attentionkart Private Ltd
                                        </Typography>
							   <Typography className="typography labelSize" sx={{ mb: 5 }}>
                                            Website URL : Attentionkart.com
                                        </Typography>
							    <Typography className="typography labelSize" sx={{ mb: 5 }}>
                                           Role : Marketing Head
                                        </Typography>
								 <Typography className="typography labelSize" sx={{ mb: 5 }}>
                                           Address : Canada , UK
                                        </Typography>		
                            </Item>
                        </Grid>
                    </Grid>
                </Container>
            </FormControl>
        </Box>


     
<Stack direction="row" spacing={2} justifyContent = "left" sx={{ my: 2 }}></Stack>
<Card
      sx={{
        p: 3,
        margin: 'auto',
        maxWidth: 600,
        flexGrow: 1,
        justifyContent: "center",
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#1A2027' : '#ffffff',
      }}
    > 
      
      <h5>Team Members </h5>
      <Stack direction="row" spacing={3} sx={{ my: 2 }}>
       <Avatar     
        src="/static/mock-images/avatars/avatar_2.jpg"
        sx={{ width: 50, height: 50 }}
      />
      
      <Avatar
        src="/static/mock-images/avatars/avatar_3.jpg"
        sx={{ width: 50, height: 50 }}
      />
      <Avatar
        src="/static/mock-images/avatars/avatar_4.jpg"
        sx={{ width: 50, height: 50 }}      
      />
      <Avatar
        src="/static/mock-images/avatars/avatar_5.jpg"
        sx={{ width: 50, height: 50 }}      
      />
      <Avatar
        src="/static/mock-images/avatars/avatar_6.jpg"
        sx={{ width: 50, height: 50 }}      
      />
      <Avatar
        src="/static/mock-images/avatars/avatar_7.jpg"
        sx={{ width: 50, height: 50 }}      
      />
      <Avatar
        src="/static/mock-images/avatars/avatar_8.jpg"
        sx={{ width: 50, height: 50 }}      
      />   
      <Avatar
        src="/static/mock-images/avatars/avatar_9.jpg"
        sx={{ width: 50, height: 50 }}      
      />   
       </Stack>
       </Card>
   </>
 );
}