
import { AppList } from '../sections/Integration/AppList';
import { Box, Typography} from '@mui/material';
import Page from '../components/Page';
import CustomizedBreadcrumbs from '../components/Breadcrumbs';

export default function IntegrationForm() {
   
    return (
        <Page title="Account" >
            <Typography sx={{pl: 2}} variant="h4" gutterBottom>
            Integrations
          </Typography>
          <Box sx={{pb: 2, pt: 1}}>
            <CustomizedBreadcrumbs></CustomizedBreadcrumbs>
          </Box>
            <AppList/>
        
        </Page>
    );
}
