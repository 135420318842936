import React, { useState, useEffect ,useParams} from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardMedia, Typography, Button, Grid } from '@mui/material';
import { Route, NavLink as Link, Switch, useLocation } from "react-router-dom";
import {getmomentinsights} from '../../Services/cameraservices';



const InfoCard = ({ title, value }) => (
  <Card sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: 140 }}>
    <CardContent>
      <Typography variant="h5">{value}</Typography>
      <Typography>{title}</Typography>
    </CardContent>
  </Card>
);

function CameraInsights() {
  // Placeholder for camera image/video. Replace 'camera_preview.jpg' with your actual image/video preview path.
  const location = useLocation();
  const cameraName= localStorage.getItem("cameraName");
  const rtspUrl= localStorage.getItem("rtspUrl");
  const rtspmeUrl= localStorage.getItem("rtspmeUrl");
  const [insights, setInsights] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

 
  

  useEffect(() => {
    setLoading(true);
    
    getmomentinsights(rtspUrl)
        .then(response => {
            console.log(response,"insights")
            setInsights(response.data.result);
            setLoading(false);
        })
        .catch(err => {
            setError(err);
            setLoading(false);
        });
}, []);

  const navigate = useNavigate();


  const handleViewDetailsClick = () => {
    navigate('/FootfalldetailsView'); // This will navigate to the page with your table view
  };

  const cardStyle = {
    width: '400px', // Fixed width
    bgcolor: "#FFFFFF"
};

const mediaWrapperStyle = {
    position: 'relative',
    height: '300px', // Fixed height for the media
};

const mediaStyle = {
    height: '100%',
    objectFit: 'cover' // Adjust this as needed
};

const loadingStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
};


  return (
    <Grid container spacing={2} sx={{ p: 3 }}>
      <Grid item xs={12} md={8}>
        <Card>
        <CardMedia
                               component="iframe" // Use iframe for video streaming
                               src={rtspmeUrl}
                              
                               sx={mediaStyle}
                           />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
            {cameraName}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Time Duration: 24/7
            </Typography>
            <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleViewDetailsClick}>
              CLICK HERE TO VIEW DETAILS
            </Button>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Grid container spacing={2}>
          
          <Grid item xs={12}>
           
         
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CameraInsights;
