import { Box, Card, CardContent, Container, Chip, Icon, Paper, Button, Stack,Typography,Link } from "@mui/material";
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { MDBContainer } from "mdb-react-ui-kit";
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';
import {Divider} from "@mui/material";

import { useTheme } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  }));

export default function ZoomScheduler(props) {
   

    const names = [
        '(GMT+00:00) Default',
        '(GMT-12:00) International Date Line West',
        '(GMT-11:00) Coordinated Universal Time-11',
        '(GMT-11:00) Samoa',
        '(GMT-10:00) Hawaii',
        '(GMT-09:30) Marquesas Islands',
        '(GMT-09:00) Alaska',
        '(GMT-08:00) Baja California',
        '(GMT-08:00) Pacific Time (US & Canada)',
        '(GMT-07:00) Chihuahua,Mazatlan',
      ];
      function getStyles(name, personName, theme) {
        return {
          fontWeight:
            personName.indexOf(name) === -1
              ? theme.typography.fontWeightRegular
              : theme.typography.fontWeightMedium,
        };
      }
      
      
      const RootStyle = styled('div')(({ theme }) => ({
        [theme.breakpoints.up('md')]: {
          display: 'flex',
        },
      }));
      
      const [open, setOpen] = React.useState(true);
    
      const theme = useTheme();
      const [personName, setPersonName] = React.useState([]);
    
      const handleChange = (event) => {
        const {
          target: { value },
        } = event;
        setPersonName(
          typeof value === 'string' ? value.split(',') : value,
        );
      };
    
    
      const handleClose = () => {
        setOpen(false);
        window.location.replace('/dashboard/online');
      };
    
      const [title,setTitle]=React.useState("");
      const [startdate, setStartdate] =React.useState(props.date);
      const [enddate, setEnddate] =React.useState(props.date);
    
      function checkDateDifference(startDate, endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);
      
        if (end > start) {
          console.log("End date is greater than start date.");
        } else if (end.getTime() === start.getTime()) {
          console.log("End date is equal to start date.");
        } else {
          console.log("End date is not greater than start date.");
        }
      }
    
      const handlecreate =() => {
        checkDateDifference(startdate, enddate)
        
      }
      
    
      const handlestartdate= (value) =>
      {
         
       
        let datavalue=value["$d"].toString()
        datavalue=datavalue.split(" ");
        datavalue=datavalue.slice(1,5)
        datavalue=datavalue.join(" ")
        console.log("start date",datavalue)
        setStartdate(datavalue)
      }
    
      const handleenddate= (value) =>
      {
         
      
        let datavalue=value["$d"].toString()
        datavalue=datavalue.split(" ");
        datavalue=datavalue.slice(1,5)
        datavalue=datavalue.join(" ")
        console.log("end date",datavalue)
        setEnddate(datavalue)
      }
     
  return (
    <>
    <Card 
      sx={{
        flexGrow: 1,
        margin:'auto',
        width:"50vh",
        height:"60vh",
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#1A2027' : '#ffffff',}}>
         <Box sx={{ flexGrow: 2 }}>
            
                <Container maxWidth="xl">
                            <Item className="item">                              
                                    <CardContent elevation={3}>
                                    <RootStyle>
    
        <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
        <Stack style={{alignItems:"center"}}>
        <img src="/static/icons/Zoom48.png"alt="Zoom"/>
        <h2>Zoom Meeting Scheduler</h2>
        
        </Stack>
        
        <Stack direction="row" spacing={10} alignItems="center" justifyContent="space-between" sx={{ my: 1 }}></Stack>
        <Divider variant="middle"/>
       
        <div>
           <TextField id="outlined-basic" label="Title" variant="outlined"  style={{width: "260px"}}/>
           </div>
           <Stack direction="row" spacing={20} alignItems="center" justifyContent="space-between" sx={{ my: 2 }}></Stack>
           <div>
           <TextField minRows={5} id="outlined-basic" label="Description" variant="outlined" style={{width: "260px"}}/>
           </div>
           <Stack direction="row" spacing={20} alignItems="center" justifyContent="space-between" sx={{ my: 2 }}></Stack>
            <LocalizationProvider
             dateAdapter={AdapterDayjs}
            >
              
     <Stack direction="row" spacing={3} >
        <MobileDateTimePicker
          label="StartDate"
          value={startdate}
          onChange={(startnewValue) => handlestartdate(startnewValue)}
          renderInput={(params) => <TextField {...params} />}
        
        />
      </Stack>
    </LocalizationProvider> 
     
    <Stack direction="row" spacing={20} alignItems="center" justifyContent="space-between" sx={{ my: 2 }}></Stack>
    <TextField  type="number" label="Duration in minutes" variant="outlined"   InputProps={{
    inputProps: { min: 0 }
  }}
  onKeyPress={(event) => {
    if (event?.key === '-' || event?.key === '+') {
      event.preventDefault();
    }
  }} style={{width: "260px"}}/>
    <Stack direction="row" spacing={20} alignItems="center" justifyContent="space-between" sx={{ my: 2 }}></Stack>
      <FormControl sx={{ width: '260px' }}>
        <InputLabel id="demo-multiple-name-label">TimeZone</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput label="Name" />}
        >
          {names.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, personName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Stack direction="row" spacing={20} alignItems="center" justifyContent="space-between" sx={{ my: 2 }}></Stack>
    
     <Autocomplete
        style={{width: "260px"}}
        multiple
        freeSolo
        id="tags-outlined"
        options={["supriya.p@attentionkart.com", "archana.v@attentionkart.com"]}
        defaultValue={["supriya.p@attentionkart.com", "archana.v@attentionkart.com"]}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Email list"
            placeholder="Email"
          />
        )}
      />
      
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button  onClick={handlecreate}autoFocus>
            Add
          </Button>
       
    
      </RootStyle>
                                                                      
                                    </CardContent>
                            </Item>
        </Container>
        </Box>
        </Card>
        </>
    );
}