import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box } from '@mui/material';



Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  // const logo = <Box component="img" src="https://www.attentionkart.com/wp-content/uploads/2022/04/logo.png" sx={{ width: 200, height: 25, ...sx }} />

  const logo = <Box component="img" src="https://storage.googleapis.com/emoeatsmenuimages/attentionkart-logo-black.png" sx={{ width: 200, height: 25, ...sx }} />


  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
