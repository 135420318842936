import React from 'react';
// material
import { Typography,Table, Grid, Paper, Stack, Avatar } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { AppWidgetSummary } from '../../sections/@dashboard/app';
import styled from "@emotion/styled";

export const StyleWrapper = styled.div`
.css-vve7qk-MuiPaper-root{
  margin: 20px;
}
`

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  minWidth: '100%',
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function createtalktimeinsight(Profile, name, percentage) {
  return { Profile, name, percentage };
}

const talktimerows = [
  createtalktimeinsight('','Andrea', "60%"),
  createtalktimeinsight('','Jharna', "80%"),
];

function createemotioninsight(Profile,name,label,percentage) {
  return { Profile ,name, label, percentage };
}

const emotioninsightrows = [
  createemotioninsight('','Andrea', "sad", "60%"),
  createemotioninsight('','Jharna', "sad", "30%"),
];


const createeyegazeinsightrows = [
  createemotioninsight('','Andrea', "Not looking screen", "45%"),

];


export default function Overview() {

  const [page] = React.useState("");
  const [rowsPerPage] = React.useState("");

  return (
    <>
    <RootStyle>
    <StyleWrapper>
      <Grid container spacing={2}>
        <Typography variant="h4" sx={{ mb: 5 }}>
        Andrea Blended session
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={4}>
            <AppWidgetSummary title="Overall Sentiment Score" total={"75%"} color='info' icon={'ic:sharp-sentiment-very-satisfied'} />
          </Grid>

          <Grid item xs={12} sm={4} md={4}>
            <AppWidgetSummary title="Overall Participation Score" total={"85%"} color='warning' icon={'emojione:speaking-head'} />
          </Grid>

          <Grid item xs={12} sm={4} md={4}>
            <AppWidgetSummary title="Overall Engagement Score" total={"80%"} color='primary' icon={'codicon:vm-active'} />
          </Grid>
        </Grid>
        <Grid container spacing={4} style={{ marginTop: '25px', marginLeft: '-1px' }}>
          <Typography variant="h4" sx={{ mb: 5 }}>
            Insights
          </Typography>
<br/>
<br/>
      <Stack direction= "row" paddingTop={8}>
          <Grid container sx={{ flexGrow: 1 }} spacing={0}>
              <Item>Talktime
       <Table>
        <TableBody>
          {(rowsPerPage > 0
            ? talktimerows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : talktimerows
          ).map((row) => (
            <TableRow key={row.name}>
               <TableCell align="right"><Avatar alt={row.name} src={row.Profile} /></TableCell>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell style={{ width: 100 }} align="center">
                {row.percentage}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        </Table>
              </Item>
              <Item>Negative Emotion Alert
              <TableBody>
          {(rowsPerPage > 0
            ? emotioninsightrows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : emotioninsightrows
          ).map((row) => (
            <TableRow key={row.name}>
                 <TableCell align="right"><Avatar alt={row.name} src={row.Profile} /></TableCell>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell style={{ width: 100 }} align="center">
                {row.label}
              </TableCell>
              <TableCell style={{ width: 100 }} align="center">
                {row.percentage}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
              </Item>
              <Item>Eyegaze
              <TableBody>
          {(rowsPerPage > 0
            ? createeyegazeinsightrows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : createeyegazeinsightrows
          ).map((row) => (
            <TableRow key={row.name}>
                 <TableCell align="righr"><Avatar alt={row.name} src={row.Profile} /></TableCell>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell style={{ width: 100 }} align="center">
                {row.label}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
     
              </Item>
           </Grid>
           </Stack>
        </Grid>
      </Grid>
  </StyleWrapper>
    </RootStyle>
      </>
  );
} 
